import React, {useMemo} from 'react'
import moment from 'moment'
import * as htmlToImage from 'html-to-image'
import ReactPaginate from 'react-paginate'
import {Button, Box, Text, Flex, Checkbox} from '@chakra-ui/react'
import {useParams, useLocation} from 'react-router-dom'
import {MdArrowBackIos} from 'react-icons/md'

import {MdUpdate} from 'react-icons/md'

import * as leaguesAPI from '../../../api_v2.1/leagues'
import PlayersList2 from './components/PlayersList2'
import PlayersList3 from './components/PlayersList3'
import PlayersList4 from './components/PlayersList4'
import config from '../../../constants/config'
import {getBase64} from '../../../constants/helpers'
import Loading from '../../Loading'

const ExportMatchDetail = () => {
  let {leagueId, seasonId, teamId} = useParams()

  // const {isOpen, onOpen, onClose} = useDisclosure()
  const [playerType, setPlayerType] = React.useState(null)
  const [state, setState] = React.useState({
    downloading: false,
    loading: true,
    loadingTeam: true,
    loadingSeason: true,
    players: [],
    dataSponsor: [],
    dataTeam: {},
    dataSeason: {},
    page: 0,
    total: 0,
    index: 0,
    content: '',
    league: {},
    checkboxStaff: true,
    checkboxPlayer: true,
    showBirthDate: true,
    checkboxLeagueName: true,
    checkboxSeasonName: true,
    numberPlayer: 21 // so luong hien thi trong 1 page giao dien dau tien
  })
  const [show, setShow] = React.useState(false)
  const [selected, setSelected] = React.useState(0)
  const [ready, setReady] = React.useState(false)
  const nodeplayer1 = React.useRef(new Date().toISOString() + 'player')
  const nodeplayer2 = React.useRef(new Date().toISOString() + 'player')
  const nodeplayer3 = React.useRef(new Date().toISOString() + 'player')
  const nodeplayer4 = React.useRef(new Date().toISOString() + 'player')

  const select = value => () => {
    setSelected(value)
    setShow(true)
  }
  const fetchData = React.useCallback(() => {
    leaguesAPI
      .getTeamsBySeasonIdBasketBall(leagueId, seasonId, teamId)
      .then(r => {
        setState(s => ({...s, dataTeam: r, players: r.members, total: r.members?.length, loadingTeam: false}))
        if (r.members?.filter(i => i.types.includes('basketball_staff_type')).length > 1) {
          setState(s => ({...s, numberPlayer: 21}))
        }
      })
      .catch(e => {
        setState(s => ({...s, loadingTeam: false}))
      })
    leaguesAPI
      .getLeagueById(leagueId)
      .then(r => {
        setState(s => ({...s, league: r}))
      })
      .catch(e => {
        console.log('e', e)
      })
    leaguesAPI
      .getSeasonByIdV2(leagueId, seasonId)
      .then(r => {
        setState(s => ({...s, dataSeason: r, loadingSeason: false}))
      })
      .catch(e => {
        setState(s => ({...s, loadingSeason: false}))
      })
  }, [leagueId, seasonId, teamId])

  const setShows = () => {
    setShow(false)
    setReady(false)
  }

  React.useEffect(() => {
    fetchData()
  }, [fetchData])
  React.useEffect(() => {
    switch (selected) {
      case 1:
        setPlayerType(nodeplayer1.current)
        break
      case 2:
        setPlayerType(nodeplayer2.current)
        break
      case 3:
        setPlayerType(nodeplayer3.current)
        break
      case 4:
        setPlayerType(nodeplayer4.current)
        break
      default:
        break
    }
  }, [selected])

  // const basketballPlayerType = state.checkboxPlayer ? state?.players.filter(i => i.types.includes('basketball_player_type')) : []
  // const basketballStaffTypes = state.checkboxStaff ? state?.players.filter(i => i.types.includes('basketball_staff_type')) : []
  const basketballPlayerStaffType = useMemo(() => state?.dataTeam?.members, [state.dataTeam])
  const total = useMemo(() => basketballPlayerStaffType?.length || 0, [basketballPlayerStaffType])
  const players1 = useMemo(() => basketballPlayerStaffType?.slice(state.page * state.numberPlayer, state.page * state.numberPlayer + state.numberPlayer), [basketballPlayerStaffType, state.page])
  const handlePageClick = ({selected}) => {
    setState(s => ({...s, page: selected}))
  }
  // const onChangeText = e => {
  //   e.persist()
  //   setState(s => ({...s, content: e.target.value}))
  // }
  const handleChangeCheckbox = ({target}) => {
    setState(s => ({...s, [target.name]: target.checked}))
  }
  const css = {
    cursor: 'pointer',
    border: '2px solid #fff',
    borderRadius: '2px'
  }
  /////////////
  ///////////
  /////////
  ///////
  const exportImage = async () => {
    if (state.downloading) return
    setState(s => ({...s, downloading: true}))
    htmlToImage
      .toJpeg(document.getElementById(playerType), {pixelRatio: 1, quality: 0.95})
      .then(dataUrl => {
        var link = document.createElement('a')
        link.download = `${state.dataTeam.name}- ${state.index === 0 ? 'danh sach' : 'anhtoancaulacbo'}.jpeg`
        link.href = dataUrl
        link.click()
        setState(s => ({...s, downloading: false}))
      })
      .catch(function (error) {
        setState(s => ({...s, downloading: false}))
        console.error('oops, something went wrong!', error)
      })
  }

  const setAgain = () => {
    setState(s => ({...s, checkboxPlayer: true, checkboxStaff: true}))
  }
  if (state.loadingSeason || state.loadingTeam) {
    return (
      <Box height={'50vh'} display="flex" flexDirection={'column'} justifyContent="space-between">
        <Loading />
      </Box>
    )
  }
  if (show) {
    return (
      <Box maxWidth={1480} margin="0 auto" display="flex" flexDirection={'column'} justifyContent="center">
        <Flex display={'flex'} marginBottom="10px" flexDirection="row" justifyContent={'space-between'}>
          <Box display={'flex'} flexDirection={'row'} mb={3} onClick={() => setShows()} cursor="pointer">
            <MdArrowBackIos size={'28px'} />
            <Text>Trở lại</Text>
          </Box>
          <Flex display="flex" flexDirection={'row'} justifyContent="flex-end">
            <Checkbox mr={2} name={'checkboxStaff'} colorScheme="red" isChecked={state.checkboxStaff} onChange={handleChangeCheckbox}>
              Nhân sự
            </Checkbox>
            <Checkbox mr={2} name={'checkboxPlayer'} colorScheme="green" isChecked={state.checkboxPlayer} onChange={handleChangeCheckbox}>
              Vận động viên
            </Checkbox>
            <Checkbox mr={2} name={'showBirthDate'} colorScheme="green" isChecked={state.showBirthDate} onChange={handleChangeCheckbox}>
              Ngày Sinh
            </Checkbox>
            {selected === 1 && (
              <Checkbox mr={2} name={'checkboxLeagueName'} colorScheme="green" isChecked={state.checkboxLeagueName} onChange={handleChangeCheckbox}>
                Tên giải
              </Checkbox>
            )}
            {selected === 1 && (
              <Checkbox mr={2} name={'checkboxSeasonName'} colorScheme="green" isChecked={state.checkboxSeasonName} onChange={handleChangeCheckbox}>
                Tên mùa giải
              </Checkbox>
            )}
            <Button ml={4} isLoading={state.downloading} isDisabled={!ready} colorScheme="blue" onClick={exportImage}>
              Tải xuống
            </Button>
          </Flex>
        </Flex>
        <Flex display={'flex'} flexDirection="row" justifyContent={'center'}>
          <Box>
            {selected === 1 && (
              <Box>
                <>
                  <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <PlayersVerticalType1
                      setIsReady={setReady}
                      id={nodeplayer1.current}
                      isShowBirthDay={state.showBirthDate}
                      players={players1}
                      size={2480}
                      setCheckBox={setAgain}
                      page={state.page}
                      checkboxPlayer={state.checkboxPlayer}
                      checkboxStaff={state.checkboxStaff}
                      seasonData={state.dataSeason}
                      teamData={state.dataTeam}
                      league={state.league}
                      checkboxLeagueName={state.checkboxLeagueName}
                      checkboxSeasonName={state.checkboxSeasonName}
                    />
                  </div>
                  <PlayersVerticalType1 setCheckBox={setAgain} size={480} page={state.page} isShowBirthDay={state.showBirthDate} players={players1} seasonData={state.dataSeason} teamData={state.dataTeam} checkboxPlayer={state.checkboxPlayer} checkboxStaff={state.checkboxStaff} league={state.league} checkboxLeagueName={state.checkboxLeagueName} checkboxSeasonName={state.checkboxSeasonName} />
                </>
                <ReactPaginate initialPage={state.page} previousLabel={'<'} nextLabel={'>'} breakLabel={'...'} breakClassName={'break-me'} pageCount={Math.ceil(total / state.numberPlayer)} marginPagesDisplayed={2} pageRangeDisplayed={1} onPageChange={handlePageClick} containerClassName={'pagination'} subContainerClassName={'pages pagination'} activeClassName={'active'} />
              </Box>
            )}
          </Box>
          {selected === 2 && (
            <>
              {/* <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <PlayersList2 id={nodeplayer1.current} players={players1} size={2480} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
                  </div> */}
              <Flex width="1100" id={nodeplayer2.current} height="100%" backgroundImage="url(/BG_DSPLAYER.png)" justifyContent="center" padding="20px">
                <PlayersList2 size={1080} setIsReady={setReady} isShowBirthDay={state.showBirthDate} checkboxPlayer={state.checkboxPlayer} checkboxStaff={state.checkboxStaff} players={state.dataTeam.members} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
              </Flex>
            </>
          )}
          {selected === 3 && (
            <>
              {/* <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <PlayersList2 id={nodeplayer1.current} players={players1} size={2480} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
                  </div> */}
              <Flex id={nodeplayer3.current} height="100%" backgroundImage="url(/BG_DSPLAYER.png)" justifyContent="center" padding="20px">
                <PlayersList3 size={1080} setIsReady={setReady} isShowBirthDay={state.showBirthDate} checkboxPlayer={state.checkboxPlayer} checkboxStaff={state.checkboxStaff} players={state.dataTeam.members} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
              </Flex>
            </>
          )}
          {selected === 4 && (
            <>
              {/* <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <PlayersList2 id={nodeplayer1.current} players={players1} size={2480} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
                  </div> */}
              <Flex id={nodeplayer4.current} width={1240} minHeight={778} backgroundImage="url(/cu18bgmau4.png)" justifyContent="center" padding="20px" backgroundSize={'cover'} backgroundPosition={'center'}>
                <PlayersList4 size={1240} setIsReady={setReady} isShowBirthDay={state.showBirthDate} checkboxPlayer={state.checkboxPlayer} checkboxStaff={state.checkboxStaff} players={state.dataTeam.members} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
              </Flex>
            </>
          )}
        </Flex>
      </Box>
    )
  } else {
    return (
      <Box height={'100%'} display="flex" flexDirection={'column'} justifyContent="space-between">
        <Flex display={'flex'} flexDirection={'row'} justifyContent={'space-between'} w={'100%'} height={'46%'}>
          <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} height={'100%'} onClick={select(1)}>
            <Image style={{height: '420px', margin: '0 auto'}} src="/chooseImgXuatAnh.jpg" />
          </Box>
          <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} height={'100%'} onClick={select(2)}>
            <Image style={{height: '420px', margin: '0 auto'}} src={'/template_2.jpeg'} />
          </Box>
          <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} height={'100%'} onClick={select(3)}>
            <Image style={{height: '420px', margin: '0 auto'}} src={'/template_3.png'} />
          </Box>
          <Box _hover={{borderColor: '#11368f !important'}} backgroundColor={'#eeeeee'} height="auto" display="flex" flexDirection={'column'} justifyContent="center" style={css} className="pointer" width={'24%'} onClick={select(4)}>
            <Image style={{margin: '0 auto'}} src={'/cu18choose.jpeg'} />
          </Box>
        </Flex>
      </Box>
    )
  }
}
const PlayersVerticalType1 = React.memo(
  ({id, players, league, size, page, setCheckBox, setIsReady, checkboxPlayer, checkboxStaff, isShowBirthDay, seasonData, teamData, checkboxLeagueName, checkboxSeasonName}) => {
    const width = size
    const height = width * 1.5
    const [listUser, setListUser] = React.useState([])
    const [playerState, setPlayerState] = React.useState(players || [])
    const px = width / 100
    const playersWrapperWidth = 90 * px
    const listPlayer = playerState.reduce(
      (p, c) => {
        if (!p[c.types]) p[c.types] = []
        if (c.types.length > 1) {
          p['basketball_player_type'].push(c)
          p['basketball_staff_type'].push(c)
          return p
        }
        p[c.types].push(c)
        return p
      },
      {basketball_player_type: [], basketball_staff_type: []}
    )
    const arrPriority = ['basketball_team_manager', 'basketball_coach', 'basketball_coach_assistant', 'basketball_medical_staff', 'basketball_marketing', 'basketball_other_staff']
    const maxSortIndex = arrPriority.length
    const _staffs = listPlayer?.basketball_staff_type
      ?.map(item => {
        const positions = item?.positions
          ?.map(i => {
            const sortIndex = arrPriority.findIndex(p => p == i)
            return {value: i, sortIndex: sortIndex == -1 ? maxSortIndex : sortIndex}
          })
          .sort((a, b) => a.sortIndex - b.sortIndex)
          ?.map(p => p.value)
        const sortIndex = arrPriority?.findIndex(p => p == positions[0])
        return {...item, positions, sortIndex: sortIndex == -1 ? maxSortIndex : sortIndex}
      })
      .sort((a, b) => a.sortIndex - b.sortIndex)

    React.useEffect(() => {
      setPlayerState(players)
    }, [players])

    React.useEffect(() => {
      setIsReady?.(false)
    }, [playerState])

    React.useEffect(() => {
      setListUser([])
      setCheckBox?.()
    }, [page])

    const onLoaded = value => {
      setListUser(s => [...s, value])
    }

    React.useEffect(() => {
      if (listUser.length === listPlayer?.basketball_staff_type.length + listPlayer.basketball_player_type.length) {
        setIsReady?.(true)
      }
    }, [listUser, listPlayer])
    const staffPosition = {basketball_coach: 'Huấn luyện viên', basketball_coach_assistant: 'Trợ lý huấn luyện viên', basketball_marketing: 'Truyền thông', basketball_medical_staff: 'Săn sóc viên', basketball_other_staff: 'Thành viên', basketball_team_manager: 'Trưởng đoàn'}
    return (
      <div id={id} style={{backgroundColor: '#eadadd', minHeight: height, width: width, padding: `${3 * px}px`, borderRadius: `${2 * px}px`}}>
        <div style={{width: '100%', height: '100%', backgroundColor: '#fff', padding: `${2 * px}px`, borderRadius: `${2 * px}px`, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', boxShadow: `${0.5 * px}px ${0.5 * px}px #921a2a`, border: `${0.2 * px}px solid #921a2a`}}>
          <div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <Image src={seasonData?.logo} style={{width: `${10 * px}px`, height: `${10 * px}px`, margin: `0px ${px}px`}} />
              <div style={{width: `${80 * px}px`, textAlign: 'center', color: '#921a2a'}}>
                {checkboxLeagueName && <h1 style={{fontSize: `${2.5 * px}px`, lineHeight: `${3 * px}px`, fontWeight: '600', textTransform: 'uppercase'}}>{league?.name}</h1>}
                {checkboxSeasonName && <h2 style={{fontSize: `${2.5 * px}px`, lineHeight: `${3 * px}px`, fontWeight: '600', textTransform: 'uppercase'}}>{seasonData?.name}</h2>}
                <div style={{width: '90%', height: '1px', backgroundColor: '#921a2a', margin: '0 auto', marginTop: `${1 * px}px`, marginBottom: `${1 * px}px`}} />
                <h1 style={{textAlign: 'center', fontSize: `${2.8 * px}px`, color: '#000', fontWeight: '600', lineHeight: `${3.5 * px}px`}}>DANH SÁCH THÀNH VIÊN CÂU LẠC BỘ</h1>
                <h1 style={{textAlign: 'center', fontSize: `${2.8 * px}px`, color: '#000', fontWeight: '600', lineHeight: `${3.5 * px}px`, textTransform: 'uppercase'}}>{teamData?.name}</h1>
              </div>
              <Image src={teamData?.logo} style={{width: `${10 * px}px`, height: `${10 * px}px`, margin: `0px ${px}px`}} />
            </div>
            {_staffs?.length > 0 && checkboxStaff && (
              <div style={{marginTop: `${2 * px}px`}}>
                <div style={{width: '100%'}}>
                  <div style={{backgroundColor: '#921a2a', padding: `${1 * px}px ${1 * px}px ${0.5 * px}px ${0.5 * px}px`, boxShadow: `${0.4 * px}px ${0.4 * px}px #c7c7c7`}}>
                    <h3 style={{fontSize: `${2.2 * px}px`, color: '#fff', fontWeight: '600'}}>NHÂN SỰ</h3>
                  </div>
                  <div style={{width: playersWrapperWidth, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {_staffs?.map((i, idx) => {
                      const itempx = (width - 10 * px) / 100
                      return (
                        <div
                          key={`${idx}${i?.id}`}
                          style={{
                            width: `${32 * itempx}px`,
                            marginLeft: [1, 4, 7, 10, 13, 16, 19].includes(idx) ? `${2 * itempx}px` : `0px`,
                            marginRight: [1, 4, 7, 10, 13, 16, 19].includes(idx) ? `${2 * itempx}px` : `0px`,
                            marginTop: `${2 * itempx}px`,
                            display: 'flex',
                            flexDirection: 'row',
                            border: `${0.5 * itempx}px solid #921a2a`,
                            backgroundColor: '#921a2a',
                            boxShadow: `${0.4 * itempx}px ${0.4 * itempx}px #c7c7c7`
                          }}>
                          <Image onload={onLoaded} src={i.avatar || config.logo} style={{width: `${((32 * itempx) / 100) * 40}px`, height: `${((32 * itempx) / 100) * 40}px`, objectFit: 'cover'}} />
                          <div style={{width: `${((32 * itempx) / 100) * 60}px`, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'center', padding: `${0.1 * itempx}px ${0.3 * itempx}px`}}>
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', fontWeight: '600', textTransform: 'uppercase'}}>{i.name}</h3>
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', borderTop: `${0.1 * itempx}px solid #fff`, borderBottom: `${0.1 * itempx}px solid #fff`}}>{moment(i.birthday).format('DD-MM-YYYY')}</h3>
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF'}}>{staffPosition[i.positions[0]] || i?.positions[0]}</h3>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
            {listPlayer?.basketball_player_type?.length > 0 && checkboxPlayer && (
              <div style={{marginTop: `${2 * px}px`}}>
                <div style={{width: '100%'}}>
                  <div style={{backgroundColor: '#921a2a', padding: `${1 * px}px ${1 * px}px ${0.5 * px}px ${0.5 * px}px`, boxShadow: `${0.4 * px}px ${0.4 * px}px #c7c7c7`}}>
                    <h3 style={{fontSize: `${2.2 * px}px`, color: '#fff', fontWeight: '600'}}>VẬN ĐỘNG VIÊN</h3>
                  </div>
                  <div style={{width: playersWrapperWidth, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {listPlayer?.basketball_player_type?.map((i, idx) => {
                      const itempx = (width - 10 * px) / 100
                      return (
                        <div
                          key={`${idx}${i?.id}`}
                          style={{
                            width: `${32 * itempx}px`,
                            marginLeft: [1, 4, 7, 10, 13, 16, 19].includes(idx) ? `${2 * itempx}px` : `0px`,
                            marginRight: [1, 4, 7, 10, 13, 16, 19].includes(idx) ? `${2 * itempx}px` : `0px`,
                            marginTop: `${2 * itempx}px`,
                            display: 'flex',
                            flexDirection: 'row',
                            border: `${0.5 * itempx}px solid #921a2a`,
                            backgroundColor: '#921a2a',
                            boxShadow: `${0.4 * itempx}px ${0.4 * itempx}px #c7c7c7`
                          }}>
                          <Image onload={onLoaded} src={i.avatar || config.logo} style={{width: `${((32 * itempx) / 100) * 40}px`, height: `${((32 * itempx) / 100) * 40}px`, objectFit: 'cover'}} />
                          <div style={{width: `${((32 * itempx) / 100) * 60}px`, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'center', padding: `${0.1 * itempx}px ${0.3 * itempx}px`}}>
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', fontWeight: '600', textTransform: 'uppercase'}}>{i.name}</h3>
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', borderTop: `${0.1 * itempx}px solid #fff`, borderBottom: `${0.1 * itempx}px solid #fff`}}>{moment(i.birthday).format('DD-MM-YYYY')}</h3>
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', fontWeight: '600'}}>Số áo: {i.shirtNumber ?? 0}</h3>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div style={{display: 'flex', flexDirection: 'column', padding: `${2 * px}px`}}>
            <Text fontWeight="700" fontSize={`${px}px`} color="#3E3E3E">
              WWW.VSPORTS.COM.VN
            </Text>
          </div>
        </div>
      </div>
    )
  },
  (p, n) =>
    JSON.stringify(p.page) === JSON.stringify(n.page) &&
    JSON.stringify(p.players) === JSON.stringify(n.players) &&
    JSON.stringify(p.checkboxLeagueName) === JSON.stringify(n.checkboxLeagueName) &&
    JSON.stringify(p.checkboxSeasonName) === JSON.stringify(n.checkboxSeasonName) &&
    JSON.stringify(p.checkboxPlayer) === JSON.stringify(n.checkboxPlayer) &&
    JSON.stringify(p.checkboxStaff) === JSON.stringify(n.checkboxStaff)
)

const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    if (props?.src?.includes('ui-avatars')) {
      getBase64(config.logo).then(r => {
        setData(r)
      })
    } else {
      getBase64(props.src).then(r => {
        setData(r)
      })
    }
  }, [props.src])
  return data ? <img {...props} src={data} onLoad={props.onload} alt="" /> : null
}

function areEqual(p, n) {
  return true
}

export default React.memo(ExportMatchDetail, areEqual)
