import React from 'react'
import bg from '../../../public/static/image-background/LOGO_02.png'
import config from '../../../constants/config'
import {useTranslation, formatDateTime} from '../../../constants/helpers'

const Mauranking1 = ({dataTable, dataSeason, size, id}) => {
  const {t} = useTranslation()
  const width = size
  const height = width * 1.5
  const px = width / 100
  const sizeItemLogo = px * 15
  const fontSize = px * 20 + 'pt'
  const fontSizeTable = px * 1 + 'pt'
  const fontSizeHeader = px * 2 + 'pt'
  const styleConfig = {
    fontStyleTextNormal: {fontFamily: 'SFUHelveticaCondensedBold', color: '#1d4380', fontSize: fontSizeTable},
    fontStyleNameOfTable: {fontFamily: 'SFUHelveticaCondensedBold', fontSize: fontSizeHeader, textAlign: 'center'},
    paddingTable: {padding: '8px 8px 8px 8x'},
    colorHeader: '#325ea8',
    colorText: '#1d4380',
    colorBodyTable: '#ffe5c2',
    colorTextName: '#f48220',
    colorTextHeader: '#fff'
  }

  return (
    <div id={id} className="bg-match-mau1" style={{width: width, minHeight: width / 1.3, padding: `${1.6 * px}px`, fontFamily: 'SFUHelveticaCondensedBold'}}>
      <div style={{width: `100%`, minHeight: `calc(${width / 1.3}px - ${3.2 * px}px)`, padding: `${px * 1.2}px ${px * 1.2}px ${px * 4.2}px ${px * 1.2}px`, backgroundColor: '#fff', position: 'relative'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{flex: 1}}>
            <Image src={dataSeason?.logo} style={{width: `${px * 8}px`, height: 'max-content'}} />
          </div>
          <div style={{flex: 6, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <h1 style={{textAlign: 'center', color: styleConfig.colorText, fontSize: `${2.5 * px}px`, textTransform: 'uppercase'}}>{dataSeason?.name}</h1>
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <Image src={bg} style={{width: `${px * 5}px`, height: 'max-content'}} />
          </div>
        </div>
        <h1 style={{textAlign: 'center', color: styleConfig.colorTextName, fontSize: `${4 * px}px`, textTransform: 'uppercase'}}>{t('rankings')}</h1>
        <div style={{display: 'grid', gridTemplateColumns: 'auto auto', gap: `${1 * px}px`}}>
          {dataTable.map((i, index) => {
            return (
              <div style={{width: '100%'}} key={`${index}${i?.id}`}>
                <h3 style={{...styleConfig.fontStyleNameOfTable, color: styleConfig.colorText, textTransform: 'uppercase', fontSize: `${1.8 * px}px`}}>{dataSeason?.settings?.type?.type == 'cup' && `${t('group')} ${i?.name}`}</h3>
                <table width="100%">
                  <thead>
                    <tr style={{background: styleConfig.colorHeader}}>
                      <td style={{...styleConfig.fontStyleTextNormal, color: styleConfig.colorTextHeader, paddingLeft: '5px', width: '5%'}}></td>
                      <td style={{...styleConfig.fontStyleTextNormal, color: styleConfig.colorTextHeader, width: '30%', textTransform: 'uppercase'}}>{t('team')}</td>
                      <td style={{...styleConfig.fontStyleTextNormal, color: styleConfig.colorTextHeader, textAlign: 'center', width: '5%'}}>{t('games_played_app')}</td>
                      <td style={{...styleConfig.fontStyleTextNormal, color: styleConfig.colorTextHeader, textAlign: 'center', width: '5%'}}>{t('wins_app')}</td>
                      <td style={{...styleConfig.fontStyleTextNormal, color: styleConfig.colorTextHeader, textAlign: 'center', width: '5%'}}>{t('draw_app')}</td>
                      <td style={{...styleConfig.fontStyleTextNormal, color: styleConfig.colorTextHeader, textAlign: 'center', width: '5%'}}>{t('lose_app')}</td>
                      <td style={{...styleConfig.fontStyleTextNormal, color: styleConfig.colorTextHeader, textAlign: 'center', width: '5%'}}>{t('goals_for_app')}</td>
                      <td style={{...styleConfig.fontStyleTextNormal, color: styleConfig.colorTextHeader, textAlign: 'center', width: '5%'}}>{t('goals_against_app')}</td>
                      <td style={{...styleConfig.fontStyleTextNormal, color: styleConfig.colorTextHeader, textAlign: 'center', width: '5%'}}>{t('goal_difference_app')}</td>
                      <td style={{...styleConfig.fontStyleTextNormal, color: styleConfig.colorTextName, textAlign: 'center', width: '5%'}}>{t('points_app')}</td>
                    </tr>
                  </thead>
                  <tbody style={{background: styleConfig.colorBodyTable}}>
                    {[...i?.teams].map((j, index) => {
                      return (
                        <tr key={`${j?.recordId}-${new Date().getTime()}`} style={{borderBottom: '1px solid rgba(0,0,0,0.1)'}}>
                          <td style={{...styleConfig.fontStyleTextNormal, paddingLeft: '14px', width: '5%'}}>{index + 1}</td>
                          <td className="" style={{...styleConfig.fontStyleTextNormal, width: '33%'}}>
                            <div className="" style={{textTransform: 'uppercase'}}>
                              {j.name}{' '}
                            </div>
                          </td>
                          <td style={{...styleConfig.fontStyleTextNormal, textAlign: 'center', width: '5%'}}>{j?.totalMatches || 0}</td>
                          <td style={{...styleConfig.fontStyleTextNormal, textAlign: 'center', width: '5%'}}>{j?.totalWinMatches || 0}</td>
                          <td style={{...styleConfig.fontStyleTextNormal, textAlign: 'center', width: '5%'}}>{j?.totalDrawMatches || 0}</td>
                          <td style={{...styleConfig.fontStyleTextNormal, textAlign: 'center', width: '5%'}}>{j?.totalLoseMatches || 0}</td>
                          <td style={{...styleConfig.fontStyleTextNormal, textAlign: 'center', width: '5%'}}>{j?.totalGoals || 0}</td>
                          <td style={{...styleConfig.fontStyleTextNormal, textAlign: 'center', width: '5%'}}>{j?.totalLoseGoals || 0}</td>
                          <td style={{...styleConfig.fontStyleTextNormal, textAlign: 'center', width: '5%'}}>{j?.totalDifferences || 0}</td>
                          <td style={{...styleConfig.fontStyleTextNormal, color: styleConfig.colorTextName, textAlign: 'center', width: '5%'}}>{j?.totalScores || 0}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function getBase64(url) {
  return new Promise(resolve => {
    fetch(url, {cache: 'reload'})
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        }
        reader.readAsDataURL(blob)
      })
  })
}

const areEqual = (p, n) => {
  return JSON.stringify(p.dataSeason) === JSON.stringify(n.dataSeason) && JSON.stringify(p.dataTable) === JSON.stringify(n.dataTable)
}

export default React.memo(Mauranking1, areEqual)
