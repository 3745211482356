import axios from 'axios'

export function getEventById(eventId, code) {
  // get danh sach phan thuong nam trong su kien
  return axios({
    url: `api/v1/events/lucky-draw-wheels/${eventId}?code=${code}`,
    method: 'get'
  }).then(res => res.data)
}
export function register(eventId, data) {
  return axios({
    url: `api/v1/events/lucky-draw-wheels/${eventId}/codes`,
    method: 'post',
    data
  }).then(res => res.data)
}
export function getDraw(eventId, data) {
  // get phan thuong duoc chi dinh
  return axios({
    url: `api/v1/events/lucky-draw-wheels/${eventId}/presents/draw`,
    method: 'post',
    data
  }).then(res => res.data)
}
export function checkUser(eventId, data) {
  return axios({
    url: `api/v1/events/lucky-draw-wheels/${eventId}/codes/has-create`,
    method: 'post',
    data
  }).then(res => res.data)
}
