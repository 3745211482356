import {Box} from '@chakra-ui/react'
import React from 'react'
import WheelComponent from './wheel'
import * as APIPrizes from '../../../api/userLuckyDraw'
import config from '../../../constants/config'

const Lucky = ({data, onChangeItemWin, onReloadData, code}) => {
  const isMobile = window.screen.width < 480 ? true : false
  const width = isMobile ? 340 : 510
  const widthbg = isMobile ? 400 : 620

  const segments = data?.presents?.map(i => i.name)
  const segColors = data?.presents?.map(i => i.color)
  const textColors = data?.presents?.map(i => i.textColor)
  const [present, setPresent] = React.useState('')
  const onFinished = winner => {
    onChangeItemWin && onChangeItemWin(winner)
  }
  const handleChangeDraw = () => {
    APIPrizes.getDraw(config.eventId, {code: code}).then(res => {
      localStorage.setItem('eventId', config.eventId) // luu de check use da quay so
      localStorage.setItem('code', code)
      setPresent(res?.present?.name)
      onReloadData(res)
    })
  }
  return (
    <Box width={widthbg} height={widthbg} borderRadius={'50%'} backgroundImage={'/img/vongtron.png'} backgroundSize="cover">
      <WheelComponent onSpin={handleChangeDraw} width={width} isMobile={isMobile} customSize={isMobile ? 170 : 255} segments={segments} segColors={segColors} textColors={textColors} winningSegment={present} onFinished={winner => onFinished(winner)} primaryColor="#FB8C00" contrastColor="white" buttonText="Quay" isOnlyOnce={false} upDuration={200} downDuration={1500} fontFamily="Arial" />
    </Box>
  )
}

function areEqual(p, n) {
  return JSON.stringify(p.data) === JSON.stringify(n.data) && JSON.stringify(p.code) === JSON.stringify(n.code)
}

export default React.memo(Lucky, areEqual)
