import React from 'react'
import moment from 'moment'
import * as htmlToImage from 'html-to-image'
import ReactPaginate from 'react-paginate'
import {Button, Box, Text, Flex, Checkbox} from '@chakra-ui/react'
import {useParams, useLocation} from 'react-router-dom'
import {MdArrowBackIos} from 'react-icons/md'

import {MdUpdate} from 'react-icons/md'

import * as leaguesAPI from '../../api/leagues'
import PlayersList2 from './components/PlayersList2'
import PlayersList3 from './components/PlayersList3'
import PlayersList4 from './components/PlayersList4'
import JSZip from 'jszip'
import FileSaver from 'file-saver'

const ExportMatchDetailAll = () => {
  let {leagueId, seasonId} = useParams()
  // const {isOpen, onOpen, onClose} = useDisclosure()
  const [playerType, setPlayerType] = React.useState(null)
  const [exportIndex, setExportIndex] = React.useState(0)
  const [listBlobImg, setListBlobImg] = React.useState([])
  const [state, setState] = React.useState({
    downloading: false,
    loading: true,
    loadingTeam: true,
    loadingSeason: true,
    players: [],
    dataSponsor: [],
    dataTeam: {},
    dataSeason: {},
    page: 1,
    perPage: 500,
    total: 0,
    index: 0,
    content: '',
    league: {},
    checkboxStaff: true,
    checkboxPlayer: true,
    showBirthDate: true,
    checkboxLeagueName: true,
    checkboxSeasonName: true,
    numberPlayer: 21 // so luong hien thi trong 1 page giao dien dau tien
  })
  const [exportIndexATeam, setExportIndexATeam] = React.useState(0)
  const [show, setShow] = React.useState(false)
  const [selected, setSelected] = React.useState(0)
  const [teams, setTeams] = React.useState([])

  const nodeplayer1 = React.useRef(new Date().toISOString() + 'player')
  const nodeplayer2 = React.useRef(new Date().toISOString() + 'player')
  const nodeplayer3 = React.useRef(new Date().toISOString() + 'player')
  const nodeplayer4 = React.useRef(new Date().toISOString() + 'player')
  const [firstLoad, setFirstLoad] = React.useState(false)
  const select = value => () => {
    setSelected(value)
    setShow(true)
  }
  const fetchData = () => {
    try {
      leaguesAPI.getTeamAcceptedBySeasonId(leagueId, seasonId, state.page, state.perPage).then(r => {
        setTeams(r?.data || [])
        // setTeamData(r)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const fetchLeague = React.useCallback(() => {
    try {
      leaguesAPI
        .getLeagueById(leagueId)
        .then(r => {
          setState(s => ({...s, league: r, dataSeason: r.seasons.filter(i => i.id === seasonId)[0]}))
        })
        .catch(e => {
          console.log('e', e)
        })
    } catch (error) {
      console.log('error')
    }
  }, [leagueId, seasonId])
  React.useEffect(() => {
    fetchData()
    fetchLeague()
  }, [])
  React.useEffect(() => {
    setExportIndex(0)
    setExportIndexATeam(0)
    setListBlobImg([])
    setFirstLoad(false)
    setState(s => ({...s, downloading: false}))
    switch (selected) {
      case 1:
        setPlayerType(nodeplayer1.current)
        break
      case 2:
        setPlayerType(nodeplayer2.current)
        break
      case 3:
        setPlayerType(nodeplayer3.current)
        break
      case 4:
        setPlayerType(nodeplayer4.current)
        break
      default:
        break
    }
  }, [selected])

  const footballPlayerType = React.useMemo(() => (state.checkboxPlayer && exportIndexATeam > -1 ? teams[exportIndexATeam]?.members.filter(i => i.types.includes('football_player_type')) : []), [exportIndexATeam, state.checkboxPlayer])
  const footballStaffType = React.useMemo(() => (state.checkboxStaff && exportIndexATeam > -1 ? teams[exportIndexATeam]?.members.filter(i => i.types.includes('football_staff_type')) : []), [exportIndexATeam, state.checkboxStaff])
  const footballPlayerStaffType = [...(footballStaffType || []), ...(footballPlayerType || [])]
  const total = footballPlayerStaffType.length
  const players1 = footballPlayerStaffType || []

  const handlePageClick = ({selected}) => {
    setState(s => ({...s, page: selected}))
  }

  const handleChangeCheckbox = ({target}) => {
    setState(s => ({...s, [target.name]: target.checked, page: 1}))
  }
  const css = {
    cursor: 'pointer',
    border: '2px solid #fff',
    borderRadius: '2px'
  }

  React.useEffect(() => {
    if (exportIndex > -1 && firstLoad) {
      if (exportIndexATeam === teams?.length) {
        exportZip()
      }
    }
  }, [exportIndexATeam, exportIndex, firstLoad])

  const exportZip = () => {
    try {
      const zip = JSZip()
      listBlobImg.forEach((blob, idx) => {
        zip.folder(`${state.dataSeason.name}`).file(`Danh-Sach-Doi-${teams[idx]?.name}.jpeg`, blob)
      })
      zip.generateAsync({type: 'blob'}).then(zipFile => {
        const fileName = `Danh-Sach-Cau-Thu-Tat-Ca-Cac-Doi.zip`
        setExportIndex(0)
        setExportIndexATeam(0)
        setListBlobImg([])
        setFirstLoad(false)
        setState(s => ({...s, downloading: false}))
        return FileSaver.saveAs(zipFile, fileName)
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const exportBlob = () => {
    if (firstLoad) {
      htmlToImage
        .toBlob(document.getElementById(playerType), {pixelRatio: 1, quality: 1})
        .then(blob => {
          setListBlobImg(s => [...s, blob])
          setExportIndexATeam(i => i + 1)
        })
        .catch(function (error) {
          setFirstLoad(false)
          setState(s => ({...s, downloading: false}))
          console.error('oops, something went wrong!', error)
        })
    }
  }

  React.useEffect(() => {
    if (firstLoad) exportBlob()
  }, [firstLoad])

  const exportImage = async () => {
    if (state.downloading) return
    setState(s => ({...s, downloading: true}))
    setFirstLoad(true)
    // setExportIndexATeam(0)
    // setExportIndex(0)
  }

  if (show) {
    return (
      <Box maxWidth={1480} margin="0 auto" display="flex" flexDirection={'column'} justifyContent="center">
        <Flex display={'flex'} marginBottom="10px" flexDirection="row" justifyContent={'space-between'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            mb={3}
            onClick={() => {
              setShow(false)
            }}
            cursor="pointer">
            <MdArrowBackIos size={'28px'} />
            <Text>Trở lại</Text>
          </Box>
          <Flex display="flex" flexDirection={'row'} justifyContent="flex-end">
            <Checkbox mr={2} name={'checkboxStaff'} colorScheme="red" isChecked={state.checkboxStaff} onChange={handleChangeCheckbox}>
              Nhân sự
            </Checkbox>
            <Checkbox mr={2} name={'checkboxPlayer'} colorScheme="green" isChecked={state.checkboxPlayer} onChange={handleChangeCheckbox}>
              Cầu thủ
            </Checkbox>
            <Checkbox mr={2} name={'showBirthDate'} colorScheme="green" isChecked={state.showBirthDate} onChange={handleChangeCheckbox}>
              Ngày Sinh
            </Checkbox>
            {selected === 1 && (
              <Checkbox mr={2} name={'checkboxLeagueName'} colorScheme="green" isChecked={state.checkboxLeagueName} onChange={handleChangeCheckbox}>
                Tên giải
              </Checkbox>
            )}
            {selected === 1 && (
              <Checkbox mr={2} name={'checkboxSeasonName'} colorScheme="green" isChecked={state.checkboxSeasonName} onChange={handleChangeCheckbox}>
                Tên mùa giải
              </Checkbox>
            )}
            <Button ml={4} isLoading={state.downloading} colorScheme="blue" isDisabled={!state.checkboxPlayer && !state.checkboxStaff} onClick={exportImage}>
              Tải xuống
            </Button>
          </Flex>
        </Flex>
        <Flex display={'flex'} flexDirection="row" justifyContent={'center'}>
          <Box>
            {selected === 1 && (
              <Box>
                <>
                  <div style={{overflow: 'scroll', width: 0, height: 0}}>{/* <PlayersVerticalType1 id={nodeplayer1.current} players={players1} size={2480} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} checkboxLeagueName={state.checkboxLeagueName} checkboxSeasonName={state.checkboxSeasonName} /> */}</div>
                  {/* <PlayersVerticalType1 size={480} players={players1} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} checkboxLeagueName={state.checkboxLeagueName} checkboxSeasonName={state.checkboxSeasonName} /> */}
                </>
                <ReactPaginate initialPage={state.page} previousLabel={'<'} nextLabel={'>'} breakLabel={'...'} breakClassName={'break-me'} pageCount={Math.ceil(total / state.numberPlayer)} marginPagesDisplayed={2} pageRangeDisplayed={1} onPageChange={handlePageClick} containerClassName={'pagination'} subContainerClassName={'pages pagination'} activeClassName={'active'} />
              </Box>
            )}
          </Box>
          {selected === 2 && teams[exportIndexATeam]?.members.length > 0 && exportIndexATeam <= teams.length - 1 && (
            <>
              <Flex width="1100" id={nodeplayer2.current} height="100%" backgroundImage="url(/BG_DSPLAYER.png)" justifyContent="center" padding="20px">
                <PlayersList2 size={1080} onLoadedParent={exportBlob} firstLoad={firstLoad} isShowBirthDay={state.showBirthDate} checkboxPlayer={state.checkboxPlayer} checkboxStaff={state.checkboxStaff} players={footballPlayerStaffType} seasonData={state.dataSeason} teamData={teams[exportIndexATeam]} league={state.league} />
              </Flex>
            </>
          )}
          {selected === 3 && teams[exportIndexATeam]?.members.length > 0 && exportIndexATeam <= teams.length - 1 && (
            <>
              <Flex id={nodeplayer3.current} height="100%" backgroundImage="url(/BG_DSPLAYER.png)" justifyContent="center" padding="20px">
                <PlayersList3 size={1080} onLoadedParent={exportBlob} isShowBirthDay={state.showBirthDate} checkboxPlayer={state.checkboxPlayer} checkboxStaff={state.checkboxStaff} players={teams[exportIndexATeam]?.members} seasonData={state.dataSeason} teamData={teams[exportIndexATeam]} league={state.league} />
              </Flex>
            </>
          )}
          {selected === 4 && teams[exportIndexATeam]?.members.length > 0 && exportIndexATeam <= teams.length - 1 && (
            <>
              {/* <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <PlayersList2 id={nodeplayer1.current} players={players1} size={2480} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
                  </div> */}
              <Flex id={nodeplayer4.current} width={1240} minHeight={778} backgroundImage="url(/cu18bgmau4.png)" justifyContent="center" padding="20px" backgroundSize={'cover'} backgroundPosition={'center'}>
                <PlayersList4 size={1240} onLoadedParent={exportBlob} isShowBirthDay={state.showBirthDate} checkboxPlayer={state.checkboxPlayer} checkboxStaff={state.checkboxStaff} players={teams[exportIndexATeam]?.members} seasonData={state.dataSeason} teamData={teams[exportIndexATeam]} league={state.league} />
              </Flex>
            </>
          )}
        </Flex>
      </Box>
    )
  } else {
    return (
      <Box height={'100%'} display="flex" flexDirection={'column'} justifyContent="space-between">
        <Flex display={'flex'} flexDirection={'row'} justifyContent={'space-between'} w={'100%'} height={'46%'}>
          <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} height={'100%'} onClick={select(1)}>
            <Image style={{height: '420px', margin: '0 auto'}} src="https://hcm03.vstorage.vngcloud.vn/v1/AUTH_359c137a6cf647cdb070a4808cbeaf8d/vstationfiles/5FqF7orBxx.jpeg" />
          </Box>
          <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} height={'100%'} onClick={select(2)}>
            <Image style={{height: '420px', margin: '0 auto'}} src={'/template_2.jpeg'} />
          </Box>
          <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} height={'100%'} onClick={select(3)}>
            <Image style={{height: '420px', margin: '0 auto'}} src={'/template_3.png'} />
          </Box>
          <Box _hover={{borderColor: '#11368f !important'}} backgroundColor={'#eeeeee'} height="auto" display="flex" flexDirection={'column'} justifyContent="center" style={css} className="pointer" width={'24%'} onClick={select(4)}>
            <Image style={{margin: '0 auto'}} src={'/cu18choose.jpeg'} />
          </Box>
        </Flex>
      </Box>
    )
  }
}
const PlayersVerticalType1 = React.memo(
  ({id, players, league, size, seasonData, teamData, checkboxLeagueName, checkboxSeasonName}) => {
    const width = size
    const height = width * 1.5
    const px = width / 100
    const playersWrapperWidth = 90 * px
    const listPlayer = players.reduce((p, c) => {
      if (!p[c.types]) p[c.types] = []
      p[c.types].push(c)
      return p
    }, {})
    const staffPosition = {football_coach: 'Huấn luyện viên', football_coach_assistant: 'Trợ lý huấn luyện viên', football_marketing: 'Truyền thông', football_medical_staff: 'Săn sóc viên', football_other_staff: 'Thành viên', football_team_manager: 'Trưởng đoàn'}
    return (
      <div id={id} style={{backgroundColor: '#eadadd', minHeight: height, width: width, padding: `${3 * px}px`, borderRadius: `${2 * px}px`}}>
        <div style={{width: '100%', height: '100%', backgroundColor: '#fff', padding: `${2 * px}px`, borderRadius: `${2 * px}px`, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', boxShadow: `${0.5 * px}px ${0.5 * px}px #921a2a`, border: `${0.2 * px}px solid #921a2a`}}>
          <div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <Image src={seasonData?.logo} style={{width: `${10 * px}px`, height: `${10 * px}px`, margin: `0px ${px}px`}} />
              <div style={{width: `${80 * px}px`, textAlign: 'center', color: '#921a2a'}}>
                {checkboxLeagueName && <h1 style={{fontSize: `${2.5 * px}px`, lineHeight: `${3 * px}px`, fontWeight: '600', textTransform: 'uppercase'}}>{league?.name}</h1>}
                {checkboxSeasonName && <h2 style={{fontSize: `${2.5 * px}px`, lineHeight: `${3 * px}px`, fontWeight: '600', textTransform: 'uppercase'}}>{seasonData?.name}</h2>}
                <div style={{width: '90%', height: '1px', backgroundColor: '#921a2a', margin: '0 auto', marginTop: `${1 * px}px`, marginBottom: `${1 * px}px`}} />
                <h1 style={{textAlign: 'center', fontSize: `${2.8 * px}px`, color: '#000', fontWeight: '600', lineHeight: `${3.5 * px}px`}}>DANH SÁCH THÀNH VIÊN CÂU LẠC BỘ</h1>
                <h1 style={{textAlign: 'center', fontSize: `${2.8 * px}px`, color: '#000', fontWeight: '600', lineHeight: `${3.5 * px}px`, textTransform: 'uppercase'}}>{teamData?.name}</h1>
              </div>
              <Image src={teamData?.logo} style={{width: `${10 * px}px`, height: `${10 * px}px`, margin: `0px ${px}px`}} />
            </div>
            {listPlayer?.football_staff_type?.length > 0 && (
              <div style={{marginTop: `${2 * px}px`}}>
                <div style={{width: '100%'}}>
                  <div style={{backgroundColor: '#921a2a', padding: `${1 * px}px ${1 * px}px ${0.5 * px}px ${0.5 * px}px`, boxShadow: `${0.4 * px}px ${0.4 * px}px #c7c7c7`}}>
                    <h3 style={{fontSize: `${2.2 * px}px`, color: '#fff', fontWeight: '600'}}>NHÂN SỰ</h3>
                  </div>
                  <div style={{width: playersWrapperWidth, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {listPlayer?.football_staff_type?.map((i, idx) => {
                      const itempx = (width - 10 * px) / 100
                      return (
                        <div
                          style={{
                            width: `${32 * itempx}px`,
                            marginLeft: [1, 4, 7, 10, 13, 16, 19].includes(idx) ? `${2 * itempx}px` : `0px`,
                            marginRight: [1, 4, 7, 10, 13, 16, 19].includes(idx) ? `${2 * itempx}px` : `0px`,
                            marginTop: `${2 * itempx}px`,
                            display: 'flex',
                            flexDirection: 'row',
                            border: `${0.5 * itempx}px solid #921a2a`,
                            backgroundColor: '#921a2a',
                            boxShadow: `${0.4 * itempx}px ${0.4 * itempx}px #c7c7c7`
                          }}>
                          <Image src={i.avatar} style={{width: `${((32 * itempx) / 100) * 40}px`, height: `${((32 * itempx) / 100) * 40}px`, objectFit: 'cover'}} />
                          <div style={{width: `${((32 * itempx) / 100) * 60}px`, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'center', padding: `${0.1 * itempx}px ${0.3 * itempx}px`}}>
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', fontWeight: '600', textTransform: 'uppercase'}}>{i.name}</h3>
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', borderTop: `${0.1 * itempx}px solid #fff`, borderBottom: `${0.1 * itempx}px solid #fff`}}>{moment(i.birthday).format('DD-MM-YYYY')}</h3>
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF'}}>{staffPosition[i.positions[0]]}</h3>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
            {listPlayer?.football_player_type?.length > 0 && (
              <div style={{marginTop: `${2 * px}px`}}>
                <div style={{width: '100%'}}>
                  <div style={{backgroundColor: '#921a2a', padding: `${1 * px}px ${1 * px}px ${0.5 * px}px ${0.5 * px}px`, boxShadow: `${0.4 * px}px ${0.4 * px}px #c7c7c7`}}>
                    <h3 style={{fontSize: `${2.2 * px}px`, color: '#fff', fontWeight: '600'}}>CẦU THỦ</h3>
                  </div>
                  <div style={{width: playersWrapperWidth, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {listPlayer?.football_player_type?.map((i, idx) => {
                      const itempx = (width - 10 * px) / 100
                      return (
                        <div
                          style={{
                            width: `${32 * itempx}px`,
                            marginLeft: [1, 4, 7, 10, 13, 16, 19].includes(idx) ? `${2 * itempx}px` : `0px`,
                            marginRight: [1, 4, 7, 10, 13, 16, 19].includes(idx) ? `${2 * itempx}px` : `0px`,
                            marginTop: `${2 * itempx}px`,
                            display: 'flex',
                            flexDirection: 'row',
                            border: `${0.5 * itempx}px solid #921a2a`,
                            backgroundColor: '#921a2a',
                            boxShadow: `${0.4 * itempx}px ${0.4 * itempx}px #c7c7c7`
                          }}>
                          <Image src={i.avatar} style={{width: `${((32 * itempx) / 100) * 40}px`, height: `${((32 * itempx) / 100) * 40}px`, objectFit: 'cover'}} />
                          <div style={{width: `${((32 * itempx) / 100) * 60}px`, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'center', padding: `${0.1 * itempx}px ${0.3 * itempx}px`}}>
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', fontWeight: '600', textTransform: 'uppercase'}}>{i.name}</h3>
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', borderTop: `${0.1 * itempx}px solid #fff`, borderBottom: `${0.1 * itempx}px solid #fff`}}>{moment(i.birthday).format('DD-MM-YYYY')}</h3>
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', fontWeight: '600'}}>Số áo: {i.shirtNumber ?? 0}</h3>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div style={{display: 'flex', flexDirection: 'column', paddingBottom: `${2 * px}px`}}>
            <Text fontWeight="700" fontSize={`${px}px`} color="#3E3E3E">
              WWW.VSPORTS.COM.VN
            </Text>
          </div>
        </div>
      </div>
    )
  },
  (p, n) => JSON.stringify(p.players) === JSON.stringify(n.players) && p.checkboxLeagueName === n.checkboxLeagueName && p.checkboxSeasonName === n.checkboxSeasonName
)

const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function getBase64(url) {
  return new Promise(resolve => {
    fetch(url, {cache: 'reload'})
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        }
        reader.readAsDataURL(blob)
      })
  })
}
function areEqual(p, n) {
  return true
}

export default React.memo(ExportMatchDetailAll, areEqual)
