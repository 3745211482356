import React, {useState, useEffect} from 'react'
import * as htmlToImage from 'html-to-image'
import JsZip from 'jszip'
import FileSaver from 'file-saver'
import {useParams} from 'react-router-dom'
import Mau1 from './layoutthedeo/mau1.js'
import Mau2 from './layoutthedeo/mau2.js'
import * as leaguesAPI from '../../api/leagues'
import {Box, Button, Checkbox, Flex, Text} from '@chakra-ui/react'
import config from '../../constants/config.js'
import {formatDateTime, useTranslation} from '../../constants/helpers.js'
import moment from 'moment'

const TheDeo = () => {
  const {t} = useTranslation()
  let {leagueId, seasonId, teamId} = useParams()
  const [exportIndex, setExportIndex] = React.useState(-1)
  const lang = window.sessionStorage.getItem('lang')
  const thumbnailMau1 = {
    en: '/thedeous1.png',
    vi: '/thedeomau1.jpg'
  }
  const thumbnailMau2 = {
    en: '/thedeous2.png',
    vi: 'https://hcm03.vstorage.vngcloud.vn/v1/AUTH_359c137a6cf647cdb070a4808cbeaf8d/vsports-files/thedeo-thumbnail.png'
  }
  const templates = [
    {key: 'mau1', thumbnail: thumbnailMau1[lang]},
    {key: 'mau2', thumbnail: thumbnailMau2[lang]}
  ]
  const [selectedShowBirthDay, setSelectedShowBirthDay] = React.useState(true)
  const [mauId, setMauId] = React.useState('mau1')
  const [seasonData, setSeasonData] = React.useState(null)
  const [teamData, setTeamData] = React.useState({})
  const [players, setPlayers] = React.useState([])
  const [blobs, setBlobs] = React.useState([])
  const [loadingSeason, setLoadingSeason] = useState(true)
  const [loadingTeam, setLoadingTeam] = useState(true)

  React.useEffect(() => {
    fetchData()
  }, [])
  React.useEffect(() => {
    if (exportIndex > -1) {
      if (exportIndex === players?.length) {
        exportZip()
      }
    }
  }, [exportIndex])

  const xuat = mauId => () => {
    if (exportIndex > -1) return
    if (!seasonData) return alert('Không tìm thấy mùa giải')
    if (!players.length) return alert('Không tìm thấy danh sách thành viên đội')
    setMauId(mauId)
    setExportIndex(0)
  }
  const fetchData = React.useCallback(async () => {
    try {
      const season = await leaguesAPI.getSeasonById(leagueId, seasonId).then(r => r)
      const team = await leaguesAPI.getTeamsBySeasonId(leagueId, seasonId, teamId).then(r => r)
      setSeasonData(season)
      setLoadingSeason(false)
      setTeamData(team)
      setLoadingTeam(false)
      setPlayers(team.members)
    } catch (error) {
      setLoadingSeason(false)
      setLoadingTeam(false)
    }
  }, [seasonId, teamId])

  const exportBlob = () => {
    htmlToImage
      .toBlob(document.getElementById('thedeo'), {pixelRatio: 1, quality: 0.95, cacheBust: true})
      .then(blob => {
        setBlobs(s => [...s, blob])
        setExportIndex(i => i + 1)
      })
      .catch(error => {
        console.error('oops, something went wrong!', error)
      })
  }

  const exportZip = () => {
    const zip = JsZip()
    const timeStamp = moment().format('yyyyMMDDHHmmss')
    const text = {
      vi: 'TheThiDau',
      en: 'RosterCard'
    }
    blobs.forEach((blob, idx) => {
      const fileName = players[idx]?.shirtNumber?.toString() ? `${text[lang]}_${teamData?.name}_${players[idx]?.shirtNumber}_${players[idx].name}.png` : `${text[lang]}_${teamData?.name}_${players[idx].name}.png`
      zip.file(fileName, blob)
    })
    zip.generateAsync({type: 'blob'}).then(zipFile => {
      const fileName = `${text[lang]}_${teamData?.name}_${timeStamp}.zip`
      setExportIndex(-1)
      setBlobs([])
      return FileSaver.saveAs(zipFile, fileName)
    })
  }
  const handleChangeSelect = ({target}) => {
    setSelectedShowBirthDay(s => !s)
  }
  useEffect(() => {
    // Send `message` to the parent using the postMessage method on the window.parent reference.
    window.parent.postMessage('ExportPlayersCard', '*')
  }, [])

  if (loadingSeason || loadingTeam) {
    return (
      <Box height={'50vh'} display="flex" flexDirection={'column'} alignItems={'center'} justifyContent="center">
        <Text>Loading . . . !</Text>
      </Box>
    )
  }
  return (
    <div>
      <div
        style={{
          opacity: 0,
          zIndex: '10',
          display: 'inline-flex',
          overflow: 'hidden',
          position: 'absolute',
          top: -400,
          left: 0
        }}>
        {mauId === 'mau1' && <Mau1 onSuccess={exportBlob} id="thedeo" player={players[exportIndex]} isShowBirthDay={selectedShowBirthDay} season={seasonData} team={teamData} />}
        {mauId === 'mau2' && players[exportIndex] && <Mau2 onSuccess={exportBlob} id="thedeo" isShowBirthDay={selectedShowBirthDay} player={players[exportIndex]} season={seasonData} team={teamData} />}
      </div>
      <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', paddingTop: 0}}>
        <Flex justifyContent="center" alignItems="center">
          <Flex ml="" alignItems="center">
            <Checkbox isChecked={selectedShowBirthDay} onChange={handleChangeSelect} />
            <Text ml="2" textAlign="center" fontFamily="SFUHelveticaCondensedBold" color="#1d4380">
              {t('show_date_birth')}
            </Text>
          </Flex>
        </Flex>
        <Flex>
          {templates.map((i, idx) => (
            <div key={idx} style={{margin: '8px', position: 'relative', cursor: 'pointer'}} onClick={() => setMauId(i.key)}>
              <img className={i?.key.includes(mauId) ? 'rounded-border-gradient' : ''} src={i.thumbnail ?? config.logo} style={{width: '200px', height: '300px', padding: '10px'}} />
              {exportIndex > -1 && i.key === mauId && (
                <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.7)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <span style={{color: '#fff', textAlign: 'center'}}>
                    {t('message.loading')} {Math.round((exportIndex * 100) / players.length)}%
                  </span>
                </div>
              )}
              {i.key === mauId && (
                <Flex marginTop="1rem" justifyContent="center">
                  {exportIndex === -1 && (
                    <Button ml={4} colorScheme="blue" onClick={xuat(mauId)}>
                      {t('download')}
                    </Button>
                  )}
                  {/* {exportIndex > -1 && i.key === mauId && (
                    <Button ml={4} colorScheme="blue" isDisabled={true}>
                      Đang tải {Math.round((exportIndex * 100) / players.length)}%
                    </Button>
                  )} */}
                </Flex>
              )}
            </div>
          ))}
        </Flex>
      </div>
    </div>
  )
}

function areEqual(p, n) {
  return true
}

export default React.memo(TheDeo, areEqual)
