import React from 'react'
import moment from 'moment'
import img from '../../../../public/static/image-background/logo-v-02.png'
import imgbgschedules from '../../../../public/static/image-background/bgitemschedules.png'
import {json} from 'react-router-dom'
import locales from '../../../../public/static/locales/vi.json'
import {useTranslation, formatDateTime, titleTemplate} from '../../../../constants/helpers'
import config from '../../../../constants/config'

const Mau1 = ({dataSeason, dataSchedules, size, id, numberRound, sponsorData, isShowSponsor}) => {
  const {t} = useTranslation()
  const width = size
  const px = size / 100
  const color = {
    first: '#f48220',
    second: '#325ea8',
    third: '#ffe5c2',
    fourth: '#1d4380'
  }
  const classCustom = size < 1000 ? 'view' : 'export'
  return (
    <div id={id} className="bg-match-mau1" style={{width: width, minHeight: width / 1.3, padding: `${1.6 * px}px`, fontFamily: 'SFUHelveticaCondensedBold'}}>
      <div style={{width: `100%`, minHeight: `calc(${width / 1.3}px - ${3.2 * px}px)`, padding: `${px * 1.3}px ${px * 1.3}px ${px * 4.2}px ${px * 1.3}px`, backgroundColor: '#fff', position: 'relative', borderRadius: `${1.5 * px}px`}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{flex: 1}}>
            <Image src={dataSeason?.logo} style={{width: `${px * 6}px`, height: 'max-content'}} />
          </div>
          <div style={{flex: 6, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <h1 style={{textAlign: 'center', color: color.fourth, fontSize: `${2.5 * px}px`, textTransform: 'uppercase'}}>{dataSeason?.name}</h1>
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <Image src={img} style={{width: `${px * 6}px`, height: 'max-content'}} />
          </div>
        </div>
        <h1 style={{textAlign: 'center', color: color.first, fontSize: `${4.5 * px}px`, textTransform: 'uppercase'}}>{t(titleTemplate(dataSchedules[0]?.dataGo))}</h1>
        <div style={{width: '100%'}}>
          <h2 style={{textTransform: 'uppercase', margin: '0 auto', width: 'max-content', color: color.fourth, padding: `${0.5 * px}px ${5 * px}px`, borderRadius: `${1.5 * px}px`, fontSize: `${2.6 * px}px`, textTransform: 'uppercase'}}>{t(numberRound)}</h2>
        </div>
        <div>
          {dataSchedules[0]?.dataGo?.map((i, idx) => (
            <Item key={`${idx}${new Date()}`} data={i} imgbgschedules={imgbgschedules} px={px} classCustom={classCustom} idx={idx} />
          ))}
        </div>
        {isShowSponsor && sponsorData?.length > 0 && (
          <div style={{height: `${8 * px}px`, width: `calc(${width - 2.4 * px})px`, backgroundImage: `url(/BG_DSPLAYER.png)`, bottom: px * 0.5, padding: `${0.8 * px}px 0px ${0.8 * px}px 0px`, borderRadius: `${px * 6}px`, marginTop: `${2 * px}px`}}>
            <div style={{width: '98%', height: '100%', backgroundColor: '#fff', margin: '0 auto', borderRadius: `${5 * px}px`, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              {sponsorData?.length > 0 && sponsorData?.slice(0, 10)?.map((i, idx) => <Image key={idx} src={i.logo || config.logo} style={{width: `auto`, maxHeight: `${px * 6}px`, backgroundColor: 'white', objectFit: 'cover', marginRight: `${2 * px}px`}} />)}
            </div>
          </div>
        )}
        <p style={{textAlign: 'center', color: color.first, fontSize: `${2 * px}px`, position: 'absolute', bottom: `0px`, right: `${1.2 * px}px`}}>VSPORTS.VN</p>
      </div>
    </div>
  )
}
const Item = React.memo(
  ({data, imgbgschedules, px, classCustom, idx}) => {
    const {t} = useTranslation()
    const color = {
      first: '#f48220',
      second: '#325ea8',
      third: '#ffe5c2',
      fourth: '#1d4380'
    }

    const arrRankName = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth']
    let teamHomeRankName = arrRankName.includes?.(data.homeTeam?.rankName) ? `${t(data.homeTeam?.rankName)}` : ''
    let teamAwayRankName = arrRankName.includes?.(data.awayTeam?.rankName) ? `${t(data.awayTeam?.rankName)}` : ''

    const arrName = ['Winquarterfinals_1', 'Winquarterfinals_2', 'Winquarterfinals_3', 'Winquarterfinals_4', 'Losesemifinal_1', 'Losesemifinal_2', 'Winsemifinal_1', 'Winsemifinal_2', 'UnknownName']

    let teamHomeName = arrName.includes(data.homeTeam?.name) || !data.homeTeam?.id ? `${t(data.homeTeam?.name) || data.homeTeam?.name}` : data.homeTeam?.name
    let teamAwayName = arrName.includes(data.awayTeam?.name) || !data.awayTeam?.id ? `${t(data.awayTeam?.name) || data.awayTeam?.name}` : data.awayTeam?.name

    let homeName = data?.homeTeam?.id ? teamHomeName : `${teamHomeRankName} ${teamHomeName}`
    let awayName = data.awayTeam?.id ? teamAwayName : `${teamAwayRankName} ${teamAwayName}`

    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', marginTop: `${2 * px}px`}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
          <div style={{flex: 2}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <Image src={data?.homeTeam?.logo} style={{width: `${px * 9}px`, height: `${9 * px}px`, borderRadius: `50%`, objectFit: 'cover', border: `${0.3 * px}px solid ${color.first}`}} />
            </div>
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
            <h2 style={{color: '#fff', textAlign: 'center', fontSize: `${2 * px}px`, color: color.fourth}}>
              {t('semifinal')} {idx + 1}
            </h2>
          </div>
          <div style={{flex: 2}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <Image src={data?.awayTeam?.logo} style={{width: `${px * 9}px`, height: `${9 * px}px`, borderRadius: `50%`, objectFit: 'cover', border: `${0.3 * px}px solid ${color.first}`}} />
            </div>
          </div>
        </div>
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundImage: `url(${imgbgschedules})`, height: `${11.3 * px}px`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
          <div style={{flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
            <Name propClass={`home${data?.homeTeam?.id}${new Date().getTime()}${classCustom}${idx}`} name={homeName} px={px} />
          </div>
          {data?.isCompleted ? (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
              <h2 style={{flex: 0.5, color: '#fff', textAlign: 'center', fontSize: `${1.4 * px}px`, marginTop: `${0.5 * px}px`}}>{formatDateTime(data?.dateTime, 'dateTime')}</h2>
              <div style={{flex: 1, color: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <div style={{marginBottom: data?.hasPenalty ? `${0.2 * px}px` : `${1.8 * px}px`}}>
                  {!data?.homeTeam?.isGiveUp &&
                    !data?.awayTeam?.isGiveUp && ( // ko doi nao bo cuoc
                      <p style={{lineHeight: `${2.5 * px}px`, fontSize: `${3.6 * px}px`, textAlign: 'center'}}>
                        {data?.homeTeam?.score}
                        <span style={{margin: `0 ${px}px`}}>-</span>
                        {data?.awayTeam?.score}
                      </p>
                    )}
                  {data?.homeTeam?.isGiveUp &&
                    !data?.awayTeam?.isGiveUp && ( // doi nha bo cuoc
                      <p style={{lineHeight: `${2.5 * px}px`, fontSize: `${3.6 * px}px`, textAlign: 'center'}}>
                        0<span style={{margin: `0 ${px}px`}}>-</span>
                        {data?.awayTeam?.giveUpScore}
                      </p>
                    )}
                  {!data?.homeTeam?.isGiveUp &&
                    data?.awayTeam?.isGiveUp && ( // doi khach bo cuoc
                      <p style={{lineHeight: `${2.5 * px}px`, fontSize: `${3.6 * px}px`, textAlign: 'center'}}>
                        {data?.homeTeam?.giveUpScore}
                        <span style={{margin: `0 ${px}px`}}>-</span>0
                      </p>
                    )}
                  {data?.homeTeam?.isGiveUp &&
                    data?.awayTeam?.isGiveUp && ( // ca 2 doi cung bo cuoc
                      <p style={{lineHeight: `${2.5 * px}px`, fontSize: `${3.6 * px}px`, textAlign: 'center'}}>
                        <span style={{margin: `0 ${px}px`}}>-</span>
                      </p>
                    )}
                </div>
                {data?.hasPenalty && (
                  <div style={{marginBottom: `${0.2 * px}px`}}>
                    <p style={{textTransform: 'uppercase', textAlign: 'center', lineHeight: `${1.5 * px}px`, fontSize: `${1.4 * px}px`, marginTop: `${0.5 * px}px`}}>{`(${t('pk')}: ${data?.homeTeam?.penaltyScore} - ${data?.awayTeam?.penaltyScore})`}</p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
              <h2 style={{color: '#fff', textAlign: 'center', fontSize: `${1.6 * px}px`, lineHeight: `${3 * px}px`, textTransform: 'uppercase', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `${12 * px}px`, overflow: 'hidden'}}>{data?.stadium ? data?.stadium : ''}</h2>
              <div style={{paddingBottom: `${2 * px}px`}}>
                <h2 style={{color: '#fff', textAlign: 'center', fontSize: `${1.5 * px}px`, lineHeight: `${2 * px}px`}}>{formatDateTime(data?.dateTime, 'date')}</h2>
                <h2 style={{color: '#fff', textAlign: 'center', fontSize: `${3.5 * px}px`, lineHeight: `${3 * px}px`}}>{formatDateTime(data?.dateTime, 'time')}</h2>
              </div>
            </div>
          )}
          <div style={{flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
            <Name propClass={`away${data?.awayTeam?.id}${new Date().getTime()}${classCustom}${idx}`} name={awayName} px={px} />
          </div>
        </div>
      </div>
    )
  },
  (p, n) => JSON.stringify(p.data) === JSON.stringify(n.data)
)

const Name = React.memo(
  ({name, px, propClass}) => {
    const reSizeBasedOnWidth = () => {
      let fontSize = window && window?.getComputedStyle(document.querySelector(`.${propClass}`))?.fontSize
      document.querySelector(`.${propClass}`).style.fontSize = parseFloat(fontSize) - 0.3 * px + 'px'
      if (document.querySelector(`.${propClass}`).clientHeight >= document.querySelector(`.contain${propClass}`).clientHeight) {
        reSizeBasedOnWidth()
      }
    }
    const processInput = () => {
      document.querySelector(`.${propClass}`).innerHTML = name
      document.querySelector(`.${propClass}`).style.fontSize = px * 3 + 'px' // Default font size
      reSizeBasedOnWidth()
    }

    React.useEffect(() => {
      processInput()
    }, [name])

    return (
      <div className={`contain${propClass}`} style={{flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: `${4 * px}px`, marginTop: `${1.5 * px}px`, textTransform: 'uppercase'}}>
        <h2 className={propClass} style={{textAlign: 'center', width: '80%', color: '#fff', fontSize: `${3 * px}px`, textTransform: 'uppercase'}}></h2>
      </div>
    )
  },
  (p, n) => JSON.stringify(p.name) === JSON.stringify(n.name)
)
const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function getBase64(url) {
  return new Promise(resolve => {
    fetch(url, {cache: 'reload'})
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        }
        reader.readAsDataURL(blob)
      })
  })
}
function areEqual(p, n) {
  return JSON.stringify(p.isDisabledTime) === JSON.stringify(n.isDisabledTime) && JSON.stringify(p.dataMatch) === JSON.stringify(n.dataMatch) && JSON.stringify(p.dataMatchDetail) === JSON.stringify(n.dataMatchDetail) && JSON.stringify(p.isShowSponsor) === JSON.stringify(n.isShowSponsor) && JSON.stringify(p.sponsorData) === JSON.stringify(n.sponsorData)
}

export default React.memo(Mau1, areEqual)
