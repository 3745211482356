import React, {useMemo, useEffect} from 'react'
import moment from 'moment'
import * as htmlToImage from 'html-to-image'
import ReactPaginate from 'react-paginate'
import {Button, Box, Text, Flex, Checkbox, Grid, GridItem} from '@chakra-ui/react'
import {useParams, useLocation, useSearchParams} from 'react-router-dom'
import {MdArrowBackIos} from 'react-icons/md'

import {MdUpdate} from 'react-icons/md'

import * as leaguesAPI from '../../api/leagues'
import PlayersList2 from './components/PlayersList2'
import PlayersList3 from './components/PlayersList3'
import PlayersList4 from './components/PlayersList4'
import PlayersList5 from './components/PlayersList5'
import PlayersList6 from './components/PlayersList6'
import PlayersList7 from './components/PlayersList7'
import PlayersList8 from './components/PlayersList8'

import config from '../../constants/config'
import {getBase64, useTranslation, formatDateTime} from '../../constants/helpers'
import Loading from '../Loading'
const ExportMatchDetail = () => {
  let {leagueId, seasonId, teamId} = useParams()
  const {t} = useTranslation()
  const [searchParams] = useSearchParams()
  const lang = window.sessionStorage.getItem('lang')
  // const {isOpen, onOpen, onClose} = useDisclosure()
  const [playerType, setPlayerType] = React.useState(null)
  const [state, setState] = React.useState({
    downloading: false,
    loading: true,
    loadingTeam: true,
    loadingSeason: true,
    loadingSponsor: true,
    players: [],
    dataSponsor: [],
    dataTeam: {},
    dataSeason: {},
    page: 0,
    total: 0,
    index: 0,
    content: '',
    league: {},
    checkboxStaff: true,
    checkboxPlayer: true,
    showBirthDate: true,
    showSponsor: true,
    checkboxLeagueName: true,
    checkboxSeasonName: true,
    numberPlayer: 21 // so luong hien thi trong 1 page giao dien dau tien
  })

  const [show, setShow] = React.useState(false)
  const [selected, setSelected] = React.useState(0)
  const [ready, setReady] = React.useState(false)
  const nodeplayer1 = React.useRef(new Date().toISOString() + 'player')
  const nodeplayer2 = React.useRef(new Date().toISOString() + 'player')
  const nodeplayer3 = React.useRef(new Date().toISOString() + 'player')
  const nodeplayer4 = React.useRef(new Date().toISOString() + 'player')
  const nodeplayer5 = React.useRef(new Date().toISOString() + 'player')
  const nodeplayer6 = React.useRef(new Date().toISOString() + 'player')
  const nodeplayer7 = React.useRef(new Date().toISOString() + 'player')
  const nodeplayer8 = React.useRef(new Date().toISOString() + 'player')

  const select = value => () => {
    setSelected(value)
    setShow(true)
  }
  const fetchData = React.useCallback(() => {
    leaguesAPI
      .getTeamsBySeasonId(leagueId, seasonId, teamId)
      .then(r => {
        const listTemp = r?.members?.reduce(
          (p, c) => {
            if (!p[c.types]) p[c.types] = []
            if (c.types.length > 1) {
              p['football_player_type'].push(c)
              p['football_staff_type'].push(c)
              return p
            }
            p[c.types].push(c)
            return p
          },
          {football_player_type: [], football_staff_type: []}
        )
        const listPlayer = [...listTemp.football_staff_type, ...listTemp.football_player_type]

        setState(s => ({...s, dataTeam: r, players: listPlayer, total: r.members?.length, loadingTeam: false}))
        if (r.members.filter(i => i.types.includes('football_staff_type')).length > 1) {
          setState(s => ({...s, numberPlayer: 24}))
        }
      })
      .catch(e => {
        setState(s => ({...s, loadingTeam: false}))
      })
    leaguesAPI
      .getLeagueById(leagueId)
      .then(r => {
        setState(s => ({...s, league: r, dataSeason: r.seasons.filter(i => i.id === seasonId)[0], loadingSeason: false}))
      })
      .catch(e => {
        setState(s => ({...s, loadingSeason: false}))
      })
    leaguesAPI
      .getSponsor(leagueId, seasonId)
      .then(r => {
        const dataTemp = r.reduce((p, n) => {
          if (n.sponsors.length) {
            p = [...p, ...n.sponsors]
          }
          return p
        }, [])
        setState(s => ({...s, dataSponsor: dataTemp, loadingSponsor: false}))
      })
      .catch(e => {
        setState(s => ({...s, loadingSponsor: false}))
      })
  }, [leagueId, seasonId, teamId])

  const setShows = () => {
    setShow(false)
    setReady(false)
  }

  React.useEffect(() => {
    fetchData()
  }, [fetchData])
  React.useEffect(() => {
    switch (selected) {
      case 1:
        setPlayerType(nodeplayer1.current)
        break
      case 2:
        setPlayerType(nodeplayer2.current)
        break
      case 3:
        setPlayerType(nodeplayer3.current)
        break
      case 4:
        setPlayerType(nodeplayer4.current)
        break
      case 5:
        setPlayerType(nodeplayer5.current)
        break
      case 6:
        setPlayerType(nodeplayer6.current)
        break
      case 7:
        setPlayerType(nodeplayer7.current)
        break
      case 8:
        setPlayerType(nodeplayer8.current)
        break
      default:
        break
    }
  }, [selected])

  // const footballPlayerType = state.checkboxPlayer ? state?.players.filter(i => i.types.includes('football_player_type')) : []
  // const footballStaffTypes = state.checkboxStaff ? state?.players.filter(i => i.types.includes('football_staff_type')) : []
  const players1 = [...new Map(state?.players?.slice(state.page * state.numberPlayer, state.page * state.numberPlayer + state.numberPlayer).map(c => [c['id'], c])).values()] //fix duplicate player and staff
  const handlePageClick = ({selected}) => {
    setState(s => ({...s, page: selected}))
  }
  // const onChangeText = e => {
  //   e.persist()
  //   setState(s => ({...s, content: e.target.value}))
  // }
  const handleChangeCheckbox = ({target}) => {
    setState(s => ({...s, [target.name]: target.checked}))
  }
  const css = {
    cursor: 'pointer',
    border: '2px solid #fff',
    borderRadius: '2px'
  }
  /////////////
  ///////////
  /////////
  ///////
  const exportImage = async () => {
    if (state.downloading) return
    setState(s => ({...s, downloading: true}))
    const timeStamp = moment().format('yyyyMMDDHHmmss')
    const text = {
      vi: 'DanhSachThanhVien',
      en: 'RosterList'
    }
    htmlToImage
      .toJpeg(document.getElementById(playerType), {pixelRatio: 3, quality: 1})
      .then(dataUrl => {
        var link = document.createElement('a')
        link.download = `${text[lang]}_${state?.dataTeam?.name}_${timeStamp}.jpeg`
        link.href = dataUrl
        link.click()
        setState(s => ({...s, downloading: false}))
      })
      .catch(function (error) {
        setState(s => ({...s, downloading: false}))
        console.error('oops, something went wrong!', error)
      })
  }
  useEffect(() => {
    // Send `message` to the parent using the postMessage method on the window.parent reference.
    window.parent.postMessage('ExportPlayers', '*')
  }, [])

  const setAgain = () => {
    setState(s => ({...s, checkboxPlayer: true, checkboxStaff: true}))
  }
  if (state.loadingSeason || state.loadingTeam || state.loadingSponsor) {
    return (
      <Box height={'50vh'} display="flex" flexDirection={'column'} alignItems={'center'} justifyContent="center">
        <Text>Loading . . . !</Text>
      </Box>
    )
  }
  const thumbnail = {
    type1: {
      en: '/chooseImgXuatAnhUs.jpeg',
      vi: '/chooseImgXuatAnh.jpg'
    },
    type2: {
      en: '/template_2us.jpeg',
      vi: '/template_2.jpeg'
    },
    type3: {
      en: '/template_3us.jpeg',
      vi: '/template_3.png'
    },
    type4: {
      // en: '/cu18chooseus.jpeg',
      // vi: '/cu18choose.jpeg'
      en: '/mau4en.jpeg',
      vi: '/mau4vi.jpeg'
    },
    type5: {
      // en: '/cu18chooseus.jpeg',
      // vi: '/cu18choose.jpeg'
      en: '/mau_5_en.jpg',
      vi: '/mau_5_vi.jpg'
    },
    type6: {
      // en: '/cu18chooseus.jpeg',
      // vi: '/cu18choose.jpeg'
      en: '/mau6en.jpg',
      vi: '/mau6vi.jpg'
    },
    type7: {
      // en: '/cu18chooseus.jpeg',
      // vi: '/cu18choose.jpeg'
      en: '/ds_mau7_vi.jpeg',
      vi: '/ds_mau7_vi.jpeg'
    },
    type8: {
      // en: '/cu18chooseus.jpeg',
      // vi: '/cu18choose.jpeg'
      en: '/ds_mau8_vi.jpeg',
      vi: '/ds_mau8_vi.jpeg'
    }
  }
  if (!show) {
    return (
      <Box height={'100%'} display="flex" flexDirection={'column'} justifyContent="space-between">
        <Grid templateColumns="repeat(5, 1fr)" gap={6}>
          <GridItem w="100%">
            <Box _hover={{borderColor: '#11368f !important'}} backgroundColor={'#eeeeee'} style={css} className="pointer" height={'100%'} onClick={select(1)}>
              <img style={{height: '420px', margin: '0 auto'}} src={thumbnail['type1'][lang]} />
            </Box>
          </GridItem>
          <GridItem w="100%">
            <Box _hover={{borderColor: '#11368f !important'}} backgroundColor={'#eeeeee'} style={css} className="pointer" height={'100%'} onClick={select(2)}>
              <img style={{height: '420px', margin: '0 auto'}} src={thumbnail['type2'][lang]} />
            </Box>
          </GridItem>
          <GridItem w="100%">
            <Box _hover={{borderColor: '#11368f !important'}} backgroundColor={'#eeeeee'} style={css} className="pointer" height={'100%'} onClick={select(3)}>
              <img style={{height: '420px', margin: '0 auto'}} src={thumbnail['type3'][lang]} />
            </Box>
          </GridItem>
          <GridItem w="100%">
            <Box _hover={{borderColor: '#11368f !important'}} backgroundColor={'#eeeeee'} height="100%" alignItems={'center'} display="flex" flexDirection={'column'} justifyContent="center" style={css} className="pointer" width={'100%'} onClick={select(4)}>
              <img style={{margin: '0 auto'}} src={thumbnail['type4'][lang]} />
            </Box>
          </GridItem>
          <GridItem w="100%">
            <Box _hover={{borderColor: '#11368f !important'}} backgroundColor={'#eeeeee'} height="100%" alignItems={'center'} display="flex" flexDirection={'column'} justifyContent="center" style={css} className="pointer" width={'100%'} onClick={select(5)}>
              <img style={{margin: '0 auto'}} src={thumbnail['type5'][lang]} />
            </Box>
          </GridItem>
          <GridItem w="100%">
            <Box _hover={{borderColor: '#11368f !important'}} backgroundColor={'#eeeeee'} height="100%" alignItems={'center'} display="flex" flexDirection={'column'} justifyContent="center" style={css} className="pointer" width={'100%'} onClick={select(6)}>
              <img style={{margin: '0 auto'}} src={thumbnail['type6'][lang]} />
            </Box>
          </GridItem>
          <GridItem w="100%">
            <Box _hover={{borderColor: '#11368f !important'}} backgroundColor={'#eeeeee'} height="100%" alignItems={'center'} display="flex" flexDirection={'column'} justifyContent="center" style={css} className="pointer" width={'100%'} onClick={select(7)}>
              <img style={{margin: '0 auto'}} src={thumbnail['type7'][lang]} />
            </Box>
          </GridItem>
          <GridItem w="100%">
            <Box _hover={{borderColor: '#11368f !important'}} backgroundColor={'#eeeeee'} height="100%" alignItems={'center'} display="flex" flexDirection={'column'} justifyContent="center" style={css} className="pointer" width={'100%'} onClick={select(8)}>
              <img style={{margin: '0 auto'}} src={thumbnail['type8'][lang]} />
            </Box>
          </GridItem>
        </Grid>
      </Box>
    )
  } else {
    return (
      <Box maxWidth={1480} margin="0 auto" display="flex" flexDirection={'column'} justifyContent="center">
        <Flex display={'flex'} marginBottom="10px" flexDirection="row" justifyContent={'space-between'}>
          <Box display={'flex'} flexDirection={'row'} mb={3} onClick={() => setShows()} cursor="pointer">
            <MdArrowBackIos size={'28px'} />
            <Text>{t('back')}</Text>
          </Box>
          <Flex display="flex" flexDirection={'row'} justifyContent="flex-end">
            <Checkbox textTransform={'capitalize'} mr={2} name={'checkboxStaff'} colorScheme="red" isChecked={state.checkboxStaff} onChange={handleChangeCheckbox}>
              {t('staff')}
            </Checkbox>
            <Checkbox textTransform={'capitalize'} mr={2} name={'checkboxPlayer'} colorScheme="green" isChecked={state.checkboxPlayer} onChange={handleChangeCheckbox}>
              {t('player')}
            </Checkbox>
            <Checkbox textTransform={'capitalize'} mr={2} name={'showBirthDate'} colorScheme="green" isChecked={state.showBirthDate} onChange={handleChangeCheckbox}>
              {t('birthday')}
            </Checkbox>
            {state.dataSponsor.length > 0 && (
              <Checkbox textTransform={'capitalize'} mr={2} name={'showSponsor'} colorScheme="green" isChecked={state.showSponsor} onChange={handleChangeCheckbox}>
                {t('sponsor')}
              </Checkbox>
            )}
            {selected === 1 && (
              <Checkbox textTransform={'capitalize'} mr={2} name={'checkboxLeagueName'} colorScheme="green" isChecked={state.checkboxLeagueName} onChange={handleChangeCheckbox}>
                {t('league_name')}
              </Checkbox>
            )}
            {selected === 1 && (
              <Checkbox textTransform={'capitalize'} mr={2} name={'checkboxSeasonName'} colorScheme="green" isChecked={state.checkboxSeasonName} onChange={handleChangeCheckbox}>
                {t('season_name')}
              </Checkbox>
            )}
            <Button textTransform={'capitalize'} ml={4} isLoading={state.downloading} isDisabled={!ready} colorScheme="blue" onClick={exportImage}>
              {t('download')}
            </Button>
          </Flex>
        </Flex>
        <Flex display={'flex'} flexDirection="row" justifyContent={'center'}>
          <Box>
            {selected === 1 && (
              <Box>
                <>
                  <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <PlayersVerticalType1
                      isShowSponsor={state.showSponsor}
                      sponsorData={state.dataSponsor}
                      setIsReady={setReady}
                      id={nodeplayer1.current}
                      isShowBirthDay={state.showBirthDate}
                      players={players1}
                      size={2480}
                      setCheckBox={setAgain}
                      page={state.page}
                      checkboxPlayer={state.checkboxPlayer}
                      checkboxStaff={state.checkboxStaff}
                      seasonData={state.dataSeason}
                      teamData={state.dataTeam}
                      league={state.league}
                      checkboxLeagueName={state.checkboxLeagueName}
                      checkboxSeasonName={state.checkboxSeasonName}
                    />
                  </div>
                  <PlayersVerticalType1
                    isShowSponsor={state.showSponsor}
                    sponsorData={state.dataSponsor}
                    setCheckBox={setAgain}
                    size={480}
                    page={state.page}
                    isShowBirthDay={state.showBirthDate}
                    players={players1}
                    seasonData={state.dataSeason}
                    teamData={state.dataTeam}
                    checkboxPlayer={state.checkboxPlayer}
                    checkboxStaff={state.checkboxStaff}
                    league={state.league}
                    checkboxLeagueName={state.checkboxLeagueName}
                    checkboxSeasonName={state.checkboxSeasonName}
                  />
                </>
                <ReactPaginate initialPage={state.page} previousLabel={'<'} nextLabel={'>'} breakLabel={'...'} breakClassName={'break-me'} pageCount={Math.ceil(state.total / state.numberPlayer)} marginPagesDisplayed={2} pageRangeDisplayed={1} onPageChange={handlePageClick} containerClassName={'pagination'} subContainerClassName={'pages pagination'} activeClassName={'active'} />
              </Box>
            )}
          </Box>
          {selected === 2 && (
            <>
              {/* <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <PlayersList2 id={nodeplayer1.current} players={players1} size={2480} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
                  </div> */}
              <Flex width="1100" id={nodeplayer2.current} height="100%" backgroundImage="url(/BG_DSPLAYER.png)" justifyContent="center" padding="20px">
                <PlayersList2 size={1080} setIsReady={setReady} isShowSponsor={state.showSponsor} isShowBirthDay={state.showBirthDate} checkboxPlayer={state.checkboxPlayer} checkboxStaff={state.checkboxStaff} sponsorData={state.dataSponsor} players={state.dataTeam.members} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
              </Flex>
            </>
          )}
          {selected === 3 && (
            <>
              {/* <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <PlayersList2 id={nodeplayer1.current} players={players1} size={2480} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
                  </div> */}
              <Flex id={nodeplayer3.current} height="100%" backgroundImage="url(/BG_DSPLAYER.png)" justifyContent="center" padding="20px">
                <PlayersList3 size={1080} setIsReady={setReady} isShowSponsor={state.showSponsor} isShowBirthDay={state.showBirthDate} checkboxPlayer={state.checkboxPlayer} checkboxStaff={state.checkboxStaff} sponsorData={state.dataSponsor} players={state.dataTeam.members} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
              </Flex>
            </>
          )}
          {selected === 4 && (
            <>
              {/* <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <PlayersList2 id={nodeplayer1.current} players={players1} size={2480} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
                  </div> */}
              <Flex id={nodeplayer4.current} width={1240} minHeight={778} backgroundImage="url(/BG-01.png)" justifyContent="center" padding="20px" backgroundSize={'cover'} backgroundPosition={'center'}>
                <PlayersList4 size={1240} setIsReady={setReady} isShowSponsor={state.showSponsor} isShowBirthDay={state.showBirthDate} checkboxPlayer={state.checkboxPlayer} checkboxStaff={state.checkboxStaff} sponsorData={state.dataSponsor} players={state.dataTeam.members} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
              </Flex>
            </>
          )}
          {selected === 5 && (
            <>
              {/* <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <PlayersList2 id={nodeplayer1.current} players={players1} size={2480} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
                  </div> */}
              <Flex id={nodeplayer5.current} width={1240} minHeight={778} backgroundImage="url(/Mau-5.png)" justifyContent="center" padding="20px" backgroundSize={'cover'} backgroundPosition={'center'}>
                <PlayersList5 size={1240} setIsReady={setReady} isShowSponsor={state.showSponsor} isShowBirthDay={state.showBirthDate} checkboxPlayer={state.checkboxPlayer} checkboxStaff={state.checkboxStaff} sponsorData={state.dataSponsor} players={state.dataTeam.members} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
              </Flex>
            </>
          )}
          {selected === 6 && (
            <>
              {/* <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <PlayersList2 id={nodeplayer1.current} players={players1} size={2480} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
                  </div> */}
              <Flex id={nodeplayer6.current} width={1240} minHeight={778} backgroundImage="url(/mau6bg.png)" justifyContent="center" padding="20px" backgroundSize={'cover'} backgroundPosition={'center'}>
                <PlayersList6 size={1240} setIsReady={setReady} isShowSponsor={state.showSponsor} isShowBirthDay={state.showBirthDate} checkboxPlayer={state.checkboxPlayer} checkboxStaff={state.checkboxStaff} sponsorData={state.dataSponsor} players={state.dataTeam.members} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
              </Flex>
            </>
          )}
          {selected === 7 && (
            <>
              <Flex id={nodeplayer7.current} height="100%" backgroundImage="url(/BG_DSPLAYER.png)" justifyContent="center" padding="20px">
                <PlayersList7 size={1080} setIsReady={setReady} isShowSponsor={state.showSponsor} isShowBirthDay={state.showBirthDate} checkboxPlayer={state.checkboxPlayer} checkboxStaff={state.checkboxStaff} sponsorData={state.dataSponsor} players={state.dataTeam.members} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
              </Flex>
            </>
          )}
          {selected === 8 && (
            <>
              {/* <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <PlayersList2 id={nodeplayer1.current} players={players1} size={2480} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
                  </div> */}
              <Flex width="1100" id={nodeplayer2.current} height="100%" backgroundImage="url(/BG_DSPLAYER.png)" justifyContent="center" padding="20px">
                <PlayersList8 size={1080} setIsReady={setReady} isShowSponsor={state.showSponsor} isShowBirthDay={state.showBirthDate} checkboxPlayer={state.checkboxPlayer} checkboxStaff={state.checkboxStaff} sponsorData={state.dataSponsor} players={state.dataTeam.members} seasonData={state.dataSeason} teamData={state.dataTeam} league={state.league} />
              </Flex>
            </>
          )}
        </Flex>
      </Box>
    )
  }
}
const PlayersVerticalType1 = React.memo(
  ({id, players, league, size, page, setCheckBox, setIsReady, checkboxPlayer, checkboxStaff, isShowBirthDay, seasonData, teamData, checkboxLeagueName, checkboxSeasonName, sponsorData, isShowSponsor}) => {
    const {t} = useTranslation()
    const [searchParams] = useSearchParams()
    const lang = searchParams.get('local') || 'en'
    const width = size
    const height = width * 1.5
    const [listUser, setListUser] = React.useState([])
    const [playerState, setPlayerState] = React.useState(players || [])
    const px = width / 100
    const playersWrapperWidth = 90 * px
    const listPlayer = playerState.reduce(
      (p, c) => {
        if (!p[c.types]) p[c.types] = []
        if (c.types.length > 1) {
          p['football_player_type'].push(c)
          p['football_staff_type'].push(c)
          return p
        }
        p[c.types].push(c)
        return p
      },
      {football_player_type: [], football_staff_type: []}
    )

    const arrPriority = ['football_team_manager', 'football_coach', 'football_coach_assistant', 'football_medical_staff', 'football_marketing', 'football_other_staff']
    const maxSortIndex = arrPriority.length
    const _staffs = listPlayer?.football_staff_type
      ?.map(item => {
        const positions = item?.positions
          ?.map(i => {
            const sortIndex = arrPriority.findIndex(p => p == i)
            return {value: i, sortIndex: sortIndex == -1 ? maxSortIndex : sortIndex}
          })
          .sort((a, b) => a.sortIndex - b.sortIndex)
          ?.map(p => p.value)
        const sortIndex = arrPriority?.findIndex(p => p == positions[0])
        return {...item, positions, sortIndex: sortIndex == -1 ? maxSortIndex : sortIndex}
      })
      .sort((a, b) => a.sortIndex - b.sortIndex)

    React.useEffect(() => {
      setPlayerState(players)
    }, [players])

    React.useEffect(() => {
      setIsReady?.(false)
    }, [playerState])

    React.useEffect(() => {
      setListUser([])
      setCheckBox?.()
    }, [page])

    const onLoaded = value => {
      setListUser(s => [...s, value])
    }

    React.useEffect(() => {
      if (listUser.length === listPlayer?.football_staff_type.length + listPlayer.football_player_type.length) {
        setIsReady?.(true)
      }
    }, [listUser, listPlayer])

    const staffPosition = {football_coach: t('football_coach'), football_coach_assistant: t('football_coach_assistant'), football_marketing: t('football_marketing'), football_medical_staff: t('football_medical_staff'), football_other_staff: t('football_other_staff'), football_team_manager: t('football_team_manager')}
    return (
      <div id={id} style={{backgroundColor: '#eadadd', minHeight: height, width: width, padding: `${3 * px}px`, borderRadius: `${2 * px}px`}}>
        <div style={{width: '100%', height: '100%', backgroundColor: '#fff', padding: `${2 * px}px`, borderRadius: `${2 * px}px`, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', boxShadow: `${0.5 * px}px ${0.5 * px}px #921a2a`, border: `${0.2 * px}px solid #921a2a`}}>
          <div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <Image src={seasonData?.logo} style={{width: `${10 * px}px`, height: `${10 * px}px`, margin: `0px ${px}px`}} />
              <div style={{width: `${80 * px}px`, textAlign: 'center', color: '#921a2a'}}>
                {checkboxLeagueName && <h1 style={{fontSize: `${2.5 * px}px`, lineHeight: `${3 * px}px`, fontWeight: '600', textTransform: 'uppercase'}}>{league?.name}</h1>}
                {checkboxSeasonName && <h2 style={{fontSize: `${2.5 * px}px`, lineHeight: `${3 * px}px`, fontWeight: '600', textTransform: 'uppercase'}}>{seasonData?.name}</h2>}
                <div style={{width: '90%', height: '1px', backgroundColor: '#921a2a', margin: '0 auto', marginTop: `${1 * px}px`, marginBottom: `${1 * px}px`}} />
                <h1 style={{textAlign: 'center', fontSize: `${2.8 * px}px`, color: '#000', fontWeight: '600', lineHeight: `${3.5 * px}px`, textTransform: 'uppercase'}}>{t('list_team_member')}</h1>
                <h1 style={{textAlign: 'center', fontSize: `${2.8 * px}px`, color: '#000', fontWeight: '600', lineHeight: `${3.5 * px}px`, textTransform: 'uppercase'}}>{teamData?.name}</h1>
              </div>
              <Image src={teamData?.logo} style={{width: `${10 * px}px`, height: `${10 * px}px`, margin: `0px ${px}px`}} />
            </div>
            {_staffs?.length > 0 && checkboxStaff && (
              <div style={{marginTop: `${2 * px}px`}}>
                <div style={{width: '100%'}}>
                  <div style={{backgroundColor: '#921a2a', padding: `${1 * px}px ${1 * px}px ${0.5 * px}px ${0.5 * px}px`, boxShadow: `${0.4 * px}px ${0.4 * px}px #c7c7c7`}}>
                    <h3 style={{fontSize: `${2.2 * px}px`, color: '#fff', fontWeight: '600'}}>{t('staff')}</h3>
                  </div>
                  <div style={{width: playersWrapperWidth, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {_staffs?.map((i, idx) => {
                      const itempx = (width - 10 * px) / 100
                      return (
                        <div
                          key={`${idx}${i?.id}`}
                          style={{
                            width: `${32 * itempx}px`,
                            marginLeft: [1, 4, 7, 10, 13, 16, 19].includes(idx) ? `${2 * itempx}px` : `0px`,
                            marginRight: [1, 4, 7, 10, 13, 16, 19].includes(idx) ? `${2 * itempx}px` : `0px`,
                            marginTop: `${2 * itempx}px`,
                            display: 'flex',
                            flexDirection: 'row',
                            border: `${0.5 * itempx}px solid #921a2a`,
                            backgroundColor: '#921a2a',
                            boxShadow: `${0.4 * itempx}px ${0.4 * itempx}px #c7c7c7`
                          }}>
                          <Image onload={onLoaded} src={i.avatar} style={{width: `${((32 * itempx) / 100) * 40}px`, height: `${((32 * itempx) / 100) * 40}px`, objectFit: 'cover'}} />
                          <div style={{width: `${((32 * itempx) / 100) * 60}px`, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'center', padding: `${0.1 * itempx}px ${0.3 * itempx}px`}}>
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', fontWeight: '600', textTransform: 'uppercase'}}>{i.name}</h3>
                            {isShowBirthDay && <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', borderTop: `${0.1 * itempx}px solid #fff`, borderBottom: `${0.1 * itempx}px solid #fff`}}>{formatDateTime(i.birthday, 'date')}</h3>}
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF'}}>{staffPosition[i.positions[0]] || i?.positions[0]}</h3>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
            {listPlayer?.football_player_type?.length > 0 && checkboxPlayer && (
              <div style={{marginTop: `${2 * px}px`}}>
                <div style={{width: '100%'}}>
                  <div style={{backgroundColor: '#921a2a', padding: `${1 * px}px ${1 * px}px ${0.5 * px}px ${0.5 * px}px`, boxShadow: `${0.4 * px}px ${0.4 * px}px #c7c7c7`}}>
                    <h3 style={{fontSize: `${2.2 * px}px`, color: '#fff', fontWeight: '600'}}>{t('player')}</h3>
                  </div>
                  <div style={{width: playersWrapperWidth, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {listPlayer?.football_player_type?.map((i, idx) => {
                      const itempx = (width - 10 * px) / 100
                      return (
                        <div
                          key={`${idx}${i?.id}`}
                          style={{
                            width: `${32 * itempx}px`,
                            marginLeft: [1, 4, 7, 10, 13, 16, 19].includes(idx) ? `${2 * itempx}px` : `0px`,
                            marginRight: [1, 4, 7, 10, 13, 16, 19].includes(idx) ? `${2 * itempx}px` : `0px`,
                            marginTop: `${2 * itempx}px`,
                            display: 'flex',
                            flexDirection: 'row',
                            border: `${0.5 * itempx}px solid #921a2a`,
                            backgroundColor: '#921a2a',
                            boxShadow: `${0.4 * itempx}px ${0.4 * itempx}px #c7c7c7`
                          }}>
                          <Image onload={onLoaded} src={i.avatar} style={{width: `${((32 * itempx) / 100) * 40}px`, height: `${((32 * itempx) / 100) * 40}px`, objectFit: 'cover'}} />
                          <div style={{width: `${((32 * itempx) / 100) * 60}px`, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'center', padding: `${0.1 * itempx}px ${0.3 * itempx}px`}}>
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', fontWeight: '600', textTransform: 'uppercase'}}>{i.name}</h3>
                            {isShowBirthDay && <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', borderTop: `${0.1 * itempx}px solid #fff`, borderBottom: `${0.1 * itempx}px solid #fff`}}>{formatDateTime(i.birthday, 'date')}</h3>}
                            <h3 style={{fontSize: `${2 * itempx}px`, color: '#FFF', fontWeight: '600'}}>
                              {t('shirt_number')}: {i.shirtNumber ?? 0}
                            </h3>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
          {isShowSponsor && sponsorData?.length > 0 && (
            <div style={{height: `${5 * px}px`, width: '100%', padding: `${0.5 * px}px 0px ${0.5 * px}px 0px`, borderRadius: '50px', marginTop: `${3 * px}px`}}>
              <div style={{width: '95%', height: '100%', backgroundColor: '#fff', margin: '0 auto', borderRadius: `${2 * px}px`, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                {sponsorData?.length > 0 && sponsorData?.slice(0, 10)?.map((i, idx) => <Image key={idx} src={i.logo || config.logo} style={{width: `auto`, maxHeight: `${px * 6}px`, backgroundColor: 'white', objectFit: 'cover', marginRight: `${1 * px}px`}} />)}
              </div>
            </div>
          )}
          <div style={{display: 'flex', flexDirection: 'column', paddingBottom: `${0.5 * px}px`}}>
            <Text fontWeight="700" fontSize={`${px}px`} color="#3E3E3E">
              WWW.VSPORTS.COM.VN
            </Text>
          </div>
        </div>
      </div>
    )
  },
  (p, n) =>
    JSON.stringify(p.page) === JSON.stringify(n.page) &&
    JSON.stringify(p.players) === JSON.stringify(n.players) &&
    JSON.stringify(p.checkboxLeagueName) === JSON.stringify(n.checkboxLeagueName) &&
    JSON.stringify(p.checkboxSeasonName) === JSON.stringify(n.checkboxSeasonName) &&
    JSON.stringify(p.checkboxPlayer) === JSON.stringify(n.checkboxPlayer) &&
    JSON.stringify(p.checkboxStaff) === JSON.stringify(n.checkboxStaff) &&
    JSON.stringify(p.isShowBirthDay) === JSON.stringify(n.isShowBirthDay) &&
    JSON.stringify(p.isShowSponsor) === JSON.stringify(n.isShowSponsor)
)

const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    if (props?.src?.includes('ui-avatars')) {
      getBase64(config.logo).then(r => {
        setData(r)
      })
    } else {
      getBase64(props.src).then(r => {
        setData(r)
      })
    }
  }, [props.src])
  return data ? <img {...props} src={data} onLoad={props.onload} alt="" /> : null
}

function areEqual(p, n) {
  return true
}

export default React.memo(ExportMatchDetail, areEqual)
