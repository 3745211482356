import React from 'react'
import moment from 'moment'
import img from '../../../public/static/image-background/logo-v-02.png'
import {json} from 'react-router-dom'
import config from '../../../constants/config'

const Mau3 = ({isDisabledTime, dataMatch, dataMatchDetail, size, id}) => {
  const width = size
  const px = size / 100
  const color = {
    first: '#f48220',
    second: '#325ea8',
    third: '#ffe5c2',
    fourth: '#1d4380'
  }
  const [elements, setElements] = React.useState([
    {id: 'seasonName', type: 'text', content: dataMatch?.season?.name, style: {fontSize: 12, color: '#000', lineHeight: 20, position: 'absolute'}},
    {id: 'homeName', type: 'text', content: dataMatch?.homeTeam?.name, style: {fontSize: 12, color: '#000', lineHeight: 20, position: 'absolute'}},
    {id: 'awayName', type: 'text', content: dataMatch?.awayTeam?.name, style: {fontSize: 12, color: '#000', lineHeight: 20, position: 'absolute'}},
    {id: 'roundName', type: 'text', content: dataMatch?.numberRound, style: {fontSize: 12, color: '#000', lineHeight: 20, position: 'absolute'}},
    {id: 'seasonLogo', type: 'image', url: dataMatch?.season?.logo, style: {width: '60px', height: '60px', position: 'absolute', left: '8px', top: '8px'}},
    {id: 'logoVsport', type: 'image', url: img, style: {width: '60px', height: '60px', position: 'absolute', right: '8px', top: '8px'}},
    {id: 'logoHomeTeam', type: 'image', url: dataMatch?.homeTeam?.logo, style: {width: '80px', height: '80px', borderRadius: '50%', position: 'absolute'}},
    {id: 'logoAwayTeam', type: 'image', url: dataMatch?.awayTeam?.logo, style: {width: '80px', height: '80px', borderRadius: '50%', position: 'absolute'}}
  ])
  const [matchDetail, setMatchDetail] = React.useState(dataMatchDetail)
  React.useEffect(() => {
    setMatchDetail(dataMatchDetail)
  }, [])
  let matchDetailHome = dataMatchDetail
    .filter(i => i.team.id == dataMatch?.homeTeam?.id)
    ?.sort(function (a, b) {
      return a.time - b.time
    })
  let matchDetailAway = dataMatchDetail
    .filter(i => i.team.id == dataMatch?.awayTeam?.id)
    ?.sort(function (a, b) {
      return a.time - b.time
    })

  const arrName = {quarterfinals: 'TỨ KẾT', semifinal: 'BÁN KẾT', '3rdPlace': 'TRANH HẠNG 3', final: 'CHUNG KẾT'}
  console.log('elements', elements)
  return (
    <div id={id} className="bg-match-mau1" style={{width: width, minHeight: width, padding: `${1.6 * px}px`, fontFamily: 'SFUHelveticaCondensedBold'}}>
      <div style={{width: `100%`, minHeight: `calc(${width}px - ${3.2 * px}px)`, padding: `${px * 1.2}px`, backgroundColor: '#fff', position: 'relative'}}>
        {elements?.map((i, idx) => {
          if (i.type == 'image') {
            return <Image key={idx} src={i.url} style={i.style} />
          }
          return (
            <p key={idx} style={i.style}>
              {i.content}
            </p>
          )
        })}
        {/* <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{flex: 1, paddingRight: `${5 * px}px`}}>
            {matchDetailHome?.map((i, idx) => (
              <Item key={`${idx}-${new Date()}`} dataItem={i} typeTeam="home" px={px} matchDetail={matchDetailHome} isDisabledTime={isDisabledTime} />
            ))}
          </div>
          <div style={{flex: 1, paddingLeft: `${5 * px}px`}}>
            {matchDetailAway?.map((i, idx) => (
              <Item key={`${idx}-${new Date()}`} dataItem={i} typeTeam="away" px={px} matchDetail={matchDetailAway} isDisabledTime={isDisabledTime} />
            ))}
          </div>
        </div> */}
        <p style={{textAlign: 'center', color: color.first, fontSize: `${2 * px}px`, position: 'absolute', bottom: `${2.8 * px}px`, width: '100%'}}>VSPORTS.VN</p>
      </div>
    </div>
  )
}
const Item = ({dataItem, typeTeam, px, matchDetail, isDisabledTime}) => {
  console.log('isDisabledTime', isDisabledTime)
  let img = ''
  let h = ''
  let w = ''
  switch (dataItem.type) {
    case 'goal':
      img = config.goal
      h = '15px'
      w = '15px'
      break
    case 'own_goal':
      img = config.own_goal
      h = '15px'
      w = '15px'
      break
    case 'yellow_card':
      img = config.yellow_card
      h = '15px'
      w = '11px'
      break
    case 'red_card':
      img = config.red_card
      h = '15px'
      w = '11px'
      break
    case 'change_player':
      img = config.change_player
      h = '15px'
      w = '15px'
      break

    default:
      img = config.yellow_red_card
      h = '15px'
      w = '15px'
  }
  const yellow_card_by_id = matchDetail
    ?.sort(function (a, b) {
      return a.time - b.time
    })
    ?.filter(i => i?.player?.id === dataItem?.player?.id && i?.type === 'yellow_card')
  if (typeTeam == 'home') {
    return (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: `${px}px`}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: `${px}px`, fontSize: `${2 * px}px`}}>
          {dataItem?.type === 'own_goal' && <p>{`(OG)`}</p>}
          <p style={{marginRight: `${px}px`}}>{dataItem?.player?.name}</p>
          <p>{dataItem?.player?.shirtNumber !== null && dataItem?.player?.shirtNumber !== undefined && !dataItem?.player?.shirtNumber?.trim?.() ? `(${dataItem?.player?.shirtNumber})` : ''}</p>
        </div>
        {!isDisabledTime && (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: `${px}px`, fontSize: `${2 * px}px`}}>
            <p>{Math.floor(dataItem?.time / 60)}'</p>
          </div>
        )}
        {yellow_card_by_id[yellow_card_by_id.length - 1]?.time === dataItem?.time && yellow_card_by_id.length > 1 ? (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: `${2 * px}px`, justifyContent: 'center'}}>
            <Image src={config.yellow_red_card} style={{width: `${2 * px}px`}} />
          </div>
        ) : (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: `${2 * px}px`, justifyContent: 'center'}}>
            <Image src={img} style={{width: `${2 * px}px`}} />
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: `${px}px`}}>
        {yellow_card_by_id[yellow_card_by_id.length - 1]?.time === dataItem?.time && yellow_card_by_id.length > 1 ? (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: `${2 * px}px`, justifyContent: 'center'}}>
            <Image src={config.yellow_red_card} style={{width: `${2 * px}px`}} />
          </div>
        ) : (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: `${2 * px}px`, justifyContent: 'center'}}>
            <Image src={img} style={{width: `${2 * px}px`}} />
          </div>
        )}
        {!isDisabledTime && (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: `${px}px`, fontSize: `${2 * px}px`}}>
            <p>{Math.floor(dataItem?.time / 60)}'</p>
          </div>
        )}
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: `${px}px`, fontSize: `${2 * px}px`}}>
          <p>{dataItem?.player?.shirtNumber !== null && dataItem?.player?.shirtNumber !== undefined && !dataItem?.player?.shirtNumber?.trim?.() ? `(${dataItem?.player?.shirtNumber})` : ''}</p>
          <p style={{marginLeft: `${px}px`}}>{dataItem?.player?.name}</p>
          {dataItem?.type === 'own_goal' && <p>{`(OG)`}</p>}
        </div>
      </div>
    )
  }
}
const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function getBase64(url) {
  return new Promise(resolve => {
    fetch(url, {cache: 'reload'})
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        }
        reader.readAsDataURL(blob)
      })
  })
}
function areEqual(p, n) {
  return JSON.stringify(p.isDisabledTime) === JSON.stringify(n.isDisabledTime) && JSON.stringify(p.dataMatch) === JSON.stringify(n.dataMatch) && JSON.stringify(p.dataMatchDetail) === JSON.stringify(n.dataMatchDetail)
}

export default React.memo(Mau3, areEqual)
