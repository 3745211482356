import React from 'react'
import moment from 'moment'
import * as htmlToImage from 'html-to-image'
import ReactPaginate from 'react-paginate'
import {Button, Box, Text, Flex, Checkbox} from '@chakra-ui/react'
import {useParams, useLocation} from 'react-router-dom'
import {MdArrowBackIos} from 'react-icons/md'
import Mau1 from './components/mau1'
import Mau2 from './components/mau2'
import Mau3 from './components/mau3'
import {MdUpdate} from 'react-icons/md'
import imgVi from '../../public/static/image-background/match.jpeg'
import imgUs from '../../public/static/image-background/matchus.jpeg'
import img2Vi from '../../public/static/image-background/match2.jpeg'
import img2Us from '../../public/static/image-background/matchus2.jpeg'
import * as leaguesAPI from '../../api/leagues'
import {useTranslation} from '../../constants/helpers'

const ExportLayer = () => {
  const data = useParams()
  let {leagueId, seasonId, matchId} = useParams()
  const {t} = useTranslation()
  // const {isOpen, onOpen, onClose} = useDisclosure()
  const [type, setType] = React.useState(null)
  const lang = window.sessionStorage.getItem('lang')
  const thumbnailImg = {
    imgbg: {
      en: imgUs,
      vi: imgVi
    },
    imgbg2: {
      en: img2Us,
      vi: img2Vi
    }
  }
  const [state, setState] = React.useState({
    downloading: false,
    loading: true,
    loadingTeam: true,
    loadingMatch: true,
    loadingMatchDetail: true
  })
  const [show, setShow] = React.useState(false)
  const [selected, setSelected] = React.useState(0)

  const mau1 = React.useRef(new Date().toISOString() + 'match')
  const mau2 = React.useRef(new Date().toISOString() + 'match')
  const mau3 = React.useRef(new Date().toISOString() + 'match')

  const idMau1 = mau1.current
  const idMau2 = mau2.current
  const [isDisabledTime, setIsDisabledTime] = React.useState(false)

  const select = value => () => {
    setSelected(value)
    setShow(true)
  }
  const fetchData = React.useCallback(() => {
    leaguesAPI
      .getMatchById(leagueId, matchId)
      .then(r => {
        setState(s => ({...s, dataMatch: r, loadingMatch: false}))
      })
      .catch(e => {
        setState(s => ({...s, loadingMatch: false}))
      })
    leaguesAPI
      .getMatchDetails(leagueId, seasonId, matchId)
      .then(r => {
        setState(s => ({...s, dataMatchDetail: r, loadingMatchDetail: false}))
      })
      .catch(e => {
        setState(s => ({...s, loadingMatchDetail: false}))
        console.log('e', e)
      })
  }, [leagueId, matchId])
  React.useEffect(() => {
    fetchData()
  }, [fetchData])

  React.useEffect(() => {
    switch (selected) {
      case 1:
        setType(mau1.current)
        break
      case 2:
        setType(mau2.current)
        break
      case 3:
        setType(mau3.current)
        break
      default:
        break
    }
  }, [selected])

  const css = {
    cursor: 'pointer',
    border: '2px solid #fff',
    borderRadius: '2px'
  }
  /////////////
  ///////////
  /////////
  ///////

  const exportImage = async () => {
    if (state.downloading) return
    setState(s => ({...s, downloading: true}))
    htmlToImage
      .toJpeg(document.getElementById(type), {pixelRatio: 3, quality: 1})
      .then(dataUrl => {
        var link = document.createElement('a')
        const timeStamp = moment().format('yyyyMMDDHHmmss')
        const text = {
          vi: 'KetQuaTranDau',
          en: 'MatchResult'
        }
        const nameHome = `${state.dataMatch?.homeTeam?.name}`
        const nameAway = `${state.dataMatch?.awayTeam?.name}`
        link.download = `${text[lang]}_${nameHome}_${nameAway}_${timeStamp}.jpeg`
        link.href = dataUrl
        link.click()
        setState(s => ({...s, downloading: false}))
      })
      .catch(function (error) {
        setState(s => ({...s, downloading: false}))
        console.error('oops, something went wrong!', error)
      })
  }
  const handleChangeCheckbox = ({target}) => {
    setIsDisabledTime(target.checked)
  }
  React.useEffect(() => {
    // Send `message` to the parent using the postMessage method on the window.parent reference.
    window.parent.postMessage('exportmatchdetail', '*')
  }, [])

  if (state.loadingMatch || state.loadingMatchDetail) {
    return (
      <Box height={'50vh'} display="flex" flexDirection={'column'} alignItems={'center'} justifyContent="center">
        <Text>Loading . . . !</Text>
      </Box>
    )
  }
  if (show) {
    return (
      <Box maxWidth={'920px'} margin="0 auto" display="flex" flexDirection={'column'} justifyContent="center">
        <Flex display={'flex'} flexDirection="row" justifyContent={'space-between'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            mb={3}
            onClick={() => {
              setShow(false)
              setIsDisabledTime(false)
            }}
            cursor="pointer">
            <MdArrowBackIos size={'28px'} />
            <Text>{t('back')}</Text>
          </Box>
          <Flex display="flex" flexDirection={'row'} justifyContent="flex-end">
            <Checkbox mr={2} name={'checkboxStaff'} colorScheme="blue" isChecked={state.checkboxStaff} onChange={handleChangeCheckbox}>
              {t('not_display_time')}
            </Checkbox>
            <Button ml={4} isLoading={state.downloading} colorScheme="blue" onClick={exportImage}>
              {t('download')}
            </Button>
          </Flex>
        </Flex>
        <Flex mt={4} display={'flex'} flexDirection="row" justifyContent={'center'}>
          <Box>
            <Box>
              {selected === 1 && (
                <>
                  <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <Mau1 isDisabledTime={isDisabledTime} dataMatch={state.dataMatch} dataMatchDetail={state.dataMatchDetail} id={idMau1} size={1500} />
                  </div>
                  <Mau1 isDisabledTime={isDisabledTime} dataMatch={state.dataMatch} dataMatchDetail={state.dataMatchDetail} size={700} />
                </>
              )}
              {selected === 2 && (
                <>
                  <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <Mau2 isDisabledTime={isDisabledTime} dataMatch={state.dataMatch} dataMatchDetail={state.dataMatchDetail} id={idMau2} size={1500} />
                  </div>
                  <Mau2 isDisabledTime={isDisabledTime} dataMatch={state.dataMatch} dataMatchDetail={state.dataMatchDetail} size={700} />
                </>
              )}
              {/* {selected === 3 && (
                <>
                  <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <Mau3 isDisabledTime={isDisabledTime} dataMatch={state.dataMatch} dataMatchDetail={state.dataMatchDetail} id={idMau1} size={1500} />
                  </div>
                  <Mau3 isDisabledTime={isDisabledTime} dataMatch={state.dataMatch} dataMatchDetail={state.dataMatchDetail} size={700} />
                </>
              )} */}
            </Box>
          </Box>
        </Flex>
      </Box>
    )
  } else {
    return (
      <Box height={'100%'} display="flex" flexDirection={'column'} justifyContent="space-between">
        <Flex display={'flex'} flexDirection={'row'} justifyContent={'space-between'} w={'100%'} height={'46%'}>
          <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} height={'100%'} onClick={select(1)}>
            <Image style={{height: '100%', margin: '0 auto'}} src={thumbnailImg['imgbg'][lang]} />
          </Box>
          <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} height={'100%'} onClick={select(2)}>
            <Image style={{height: '100%', margin: '0 auto'}} src={thumbnailImg['imgbg2'][lang]} />
          </Box>
          <Box backgroundColor={'#eeeeee'} className="pointer" p={4} width={'24%'} height={'auto'} position="relative">
            <MdUpdate size={30} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%'}} />
          </Box>
          <Box backgroundColor={'#eeeeee'} className="pointer" p={4} width={'24%'} height={'auto'} position="relative">
            <MdUpdate size={30} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%'}} />
          </Box>
        </Flex>
      </Box>
    )
  }
}

const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function getBase64(url) {
  return new Promise(resolve => {
    fetch(url, {cache: 'reload'})
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        }
        reader.readAsDataURL(blob)
      })
  })
}
function areEqual(p, n) {
  return true
}

export default React.memo(ExportLayer, areEqual)
