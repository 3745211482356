import React from 'react'
import {LuckyWheel} from 'react-luck-draw'
import WheelComponent from './lucky'
import * as APIPrizes from '../../../api/userLuckyDraw'
import {Box, Text, Flex, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure} from '@chakra-ui/react'

const Rotationluck = React.memo(
  ({data, onChangeItemWin, code}) => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [itemWin, setItemWin] = React.useState('')
    const isMobile = window.screen.width < 480 ? true : false
    const [dataEvent, setDataEvent] = React.useState(data)
    const [dataSpin, setDataSpin] = React.useState(data?.present)
    const [canDraw, setCanDraw] = React.useState(dataEvent?.canDraw)
    const bgimg = isMobile ? 'url(/Vongquay02mb.jpg)' : 'url(/bg04.jpg)'
    React.useEffect(() => {
      setDataEvent(data)
      setCanDraw(dataEvent?.canDraw)
      setDataSpin(data?.present)
    }, [data])
    const handleChangeItemWin = win => {
      setItemWin(win)
      onChangeItemWin && onChangeItemWin(win)
      setTimeout(() => {
        setCanDraw(false)
        onOpen()
      }, 1000)
    }
    const reloadData = data => {
      setDataSpin(data)
    }
    if (!canDraw && !isOpen) {
      return (
        <div className="pyro" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', maxWidth: '1920px', height: '100vh', backgroundImage: bgimg, backgroundSize: 'cover', backgroundPosition: 'center'}}>
          <SpinIsOver data={dataSpin} />
        </div>
      )
    }
    return (
      <div className="pyro" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh', backgroundImage: bgimg, backgroundSize: 'cover', backgroundPosition: 'center'}}>
        {isOpen && (
          <div className="pyro">
            <div className="before"></div>
            <div className="after"></div>
          </div>
        )}
        <Alert isOpen={isOpen} dataSpin={dataSpin} onOpen={onOpen} onClose={onClose} text={itemWin} />
        <WheelComponent data={dataEvent} code={code} onChangeItemWin={handleChangeItemWin} onReloadData={reloadData} />
      </div>
    )
  },
  (p, n) => JSON.stringify(p.data) === JSON.stringify(n.data) && JSON.stringify(p.code) === JSON.stringify(n.code)
)
const Alert = ({children, isOpen, onOpen, onClose, text, dataSpin}) => {
  return (
    <>
      <div onClick={onOpen}>{children}</div>
      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader backgroundColor={'#FB8C00'}>
            <Box>
              <Text color="#fff"> Xin chúc mừng bạn !</Text>
              {dataSpin?.code && (
                <Text color={'#fff'} fontSize="xs">
                  {' '}
                  Mã tham gia: {dataSpin?.code}
                </Text>
              )}
            </Box>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody minHeight={'30vh'} display="flex" flexDirection={'row'} alignItems="center" justifyContent={'center'}>
            <Text color={'#FB8C00'} fontSize="3xl" fontWeight={500}>
              {text}
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
const SpinIsOver = React.memo(
  ({data}) => {
    const isMobile = window.screen.width < 480 ? true : false

    return (
      <Box position={'relative'} width={isMobile ? '350px' : '400px'} height={'200px'} display="flex" justifyContent="center" alignItems={'center'} backgroundColor="#fff" borderRadius={'4px'}>
        <Text textAlign={'center'} color={'#FB8C00'} fontSize="2xl">
          {' '}
          Bạn đã hết lượt quay !{' '}
        </Text>
        <Flex position={'absolute'} bottom={4} right={4} mt={4}>
          <Present data={data}>
            <Button color={'#fff'} _hover={{backgroundColor: '#F08600'}} ml={2} height={'40px'} backgroundColor={'#FB8C00'} borderRadius={'8px'} fontSize={12}>
              Quà của bạn
            </Button>
          </Present>
        </Flex>
      </Box>
    )
  },
  (p, n) => JSON.stringify(p.data) === JSON.stringify(n.data)
)
const Present = React.memo(
  ({children, data}) => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    return (
      <>
        <div onClick={onOpen}>{children}</div>
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
          <ModalOverlay />
          <ModalContent borderRadius={4} overflow="hidden">
            <ModalHeader backgroundColor={'#FB8C00'}>
              <Text color={'#fff'}> Phần quà của bạn !</Text>
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody minHeight={'30vh'} display="flex" flexDirection={'row'} justifyContent={'center'}>
              <Box width={'100%'}>
                <Text color={'#FB8C00'} width="100%" textAlign={'left'} fontWeight={500}>
                  {1}. {data?.present?.name} - Mã: {data?.code}
                </Text>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  },
  (p, n) => JSON.stringify(p.data) === JSON.stringify(n.data)
)
function areEqual(p, n) {
  return JSON.stringify(p.data) === JSON.stringify(n.data)
}

export default React.memo(Rotationluck, areEqual)
