import React from 'react'
import {Box, Image, Text} from '@chakra-ui/react'

const emptyToken = ({message, style, size}) => {
  return (
    <Box style={{width: '100%', maxWidth: 300, padding: 16, margin: '0 auto', ...style}}>
      <Image src="https://hcm03.vstorage.vngcloud.vn/v1/AUTH_359c137a6cf647cdb070a4808cbeaf8d/vstationfiles/yU99yVZLgP.png" width={size != '300' ? '100%' : '300'} height={size} />
      <Text textAlign="center" mt={4} fontWeight="700" color="#d9d9d9" fontSize={16}>
        {message}
      </Text>
    </Box>
  )
}

function areEqual(p, n) {
  return true
}

export default React.memo(emptyToken, areEqual)
