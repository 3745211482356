import {Box, Button, Flex, Input, Radio, RadioGroup, Stack, Text, Tooltip, useToast} from '@chakra-ui/react'
import React from 'react'
import {BiErrorCircle} from 'react-icons/bi'
import * as APIPrizes from '../../../api/userLuckyDraw'
import config from '../../../constants/config'
const FormRegister = ({onChangeView, onChangeData, onChangeCode}) => {
  const isMobile = window.screen.width < 480 ? true : false
  const bgimg = isMobile ? 'url(/Vongquay02mb.jpg)' : 'url(/bg04.jpg)'
  const [state, setState] = React.useState({
    formView: 'register'
  })
  const changeView = () => {
    setState(s => ({...s, formView: 'confirm'}))
  }
  const handleChangeView = () => {
    onChangeView && onChangeView()
  }
  const handleChangeData = data => {
    onChangeData && onChangeData(data)
  }
  const handleChangeCode = data => {
    onChangeCode && onChangeCode(data)
  }
  const view = {
    register: <Register onChangeForm={changeView} />,
    confirm: <Confirm onChangeView={handleChangeView} onChangeData={handleChangeData} onChangeCode={handleChangeCode} />
  }
  return (
    <Box width={'100%'} height={'100vh'} backgroundImage={bgimg} display="flex" backgroundSize={'cover'} alignItems="center" justifyContent={'center'}>
      {view[state.formView]}
    </Box>
  )
}

const Register = ({onChangeForm}) => {
  const [state, setState] = React.useState({
    name: '',
    phone: '',
    gender: 'male',
    email: '',
    school: '',
    birthday: '',
    isLoading: false
  })
  const toast = useToast()
  const error = React.useRef(false)
  const [errors, setErrors] = React.useState(false)
  const handleChangeText = ({target}) => {
    setState(s => ({...s, [target.name]: target.value}))
  }
  const handleChangeRadio = value => {
    setState(s => ({...s, gender: value}))
  }
  const handleChangeDate = ({target}) => {
    setState(s => ({...s, birthday: target.value}))
  }
  const handleBlur = () => {
    const isValid = validateAll()
    error.current = isValid
    setErrors(isValid)
  }
  const validateAll = () => {
    const isPhoneValid = /((^(\+84|84|0|0084){1})(3|5|7|8|9))+([0-9]{8})$/.test(state.phone)
    if (!isPhoneValid) {
      return true // loi
    }
    return false
  }
  const submit = async () => {
    try {
      if (state.isLoading) return
      const params = {
        name: state.name,
        schoolName: state.school,
        email: state.email,
        phone: state.phone,
        birthday: new Date(state.birthday).toISOString(),
        gender: state.gender
      }
      // await APIPrizes.checkUser(config.eventId, {email: state.email, phone: state.phone})
      await APIPrizes.register(config.eventId, params)
      onChangeForm && onChangeForm()
    } catch (error) {
      error?.response?.data?.errors?.forEach((i, idx) => {
        setTimeout(() => {
          toast({
            position: 'top-right',
            status: 'error',
            title: `${i.message == 'error_provided_code' ? 'Số điện thoại hoặc email đã được cấp mã' : i.message}`,
            description: '',
            duration: 9000,
            isClosable: true
          })
        }, 500 * idx)
      })
    }
  }

  return (
    <Box width={'400px'} p={4} backgroundColor="#fff" borderRadius={'4px'}>
      <Text fontSize="xl" textAlign={'center'} mb={4}>
        Đăng ký
      </Text>
      <Flex align={'center'} justifyContent="space-between">
        <Text>
          Họ Tên<span style={{color: 'red'}}>*</span>
        </Text>
        <Input value={state.name} name="name" onChange={handleChangeText} maxW={'250px'} variant="outline" ml={2} />
      </Flex>
      <Flex align={'center'} mt={4} justifyContent="space-between">
        <Text>Giới tính</Text>
        <RadioGroup defaultValue={state.gender} onChange={handleChangeRadio}>
          <Stack spacing={4} direction="row">
            <Radio value="male">Nam</Radio>
            <Radio value="female">Nữ</Radio>
            <Radio value="other">Khác</Radio>
          </Stack>
        </RadioGroup>
      </Flex>
      <Flex align={'center'} mt={4} justifyContent="space-between">
        <Text>
          Ngày sinh<span style={{color: 'red'}}>*</span>
        </Text>
        <Input onChange={handleChangeDate} maxW={'250px'} variant="outline" ml={2} type="date" />
      </Flex>
      <Flex align={'center'} mt={4} justifyContent="space-between" position={'relative'}>
        <Text>
          SĐT<span style={{color: 'red'}}>*</span>
        </Text>
        <Input maxLength={12} type={'number'} name="phone" onChange={handleChangeText} maxW={'250px'} variant="outline" ml={2} onBlur={handleBlur} />
        {errors && (
          <Tooltip label={'Không đúng định dạng số điện thoại'}>
            <Box position={'absolute'} top={'25%'} right={'10px'} style={{cursor: 'pointer'}}>
              <BiErrorCircle color="red" />
            </Box>
          </Tooltip>
        )}
      </Flex>
      <Flex align={'center'} mt={4} justifyContent="space-between">
        <Text>Email</Text>
        <Input type={'email'} name="email" onChange={handleChangeText} maxW={'250px'} variant="outline" ml={2} />
      </Flex>
      <Flex align={'center'} mt={4} justifyContent="space-between">
        <Text>Trường học</Text>
        <Input name="school" onChange={handleChangeText} maxW={'250px'} variant="outline" ml={2} />
      </Flex>
      <Flex justifyContent={'center'} mt={4}>
        <Button onClick={submit} isDisabled={errors || state.name.length == '' || state.phone.length == '' || state.birthday.length == ''} color={'#fff'} _hover={{backgroundColor: '#1971B6'}} ml={2} height={'40px'} backgroundColor={'#2196F3'} borderRadius={'8px'} fontSize={12}>
          Tiếp tục
        </Button>
      </Flex>
      <Text
        fontSize={'xs'}
        textAlign="center"
        mt={4}
        cursor="pointer"
        _hover={{color: '#2196F3'}}
        onClick={() => {
          onChangeForm && onChangeForm()
        }}>
        Bạn đã đăng ký, tiến hành nhập mã
      </Text>
    </Box>
  )
}
const Confirm = ({onChangeData, onChangeView, onChangeCode}) => {
  const [state, setState] = React.useState({
    otp: '',
    isLoading: false,
    data: {}
  })
  const toast = useToast()

  const handleChangeText = ({target}) => {
    setState(s => ({...s, [target.name]: target.value}))
  }
  const submit = async () => {
    try {
      if (state.isLoading) return
      const data = await APIPrizes.getEventById(config.eventId, state.otp)
      setState(s => ({...s, data: data}))
      onChangeData && onChangeData(data)
      onChangeView && onChangeView()
      onChangeCode && onChangeCode(state.otp)
    } catch (error) {
      error?.response?.data?.errors?.forEach((i, idx) => {
        setTimeout(() => {
          toast({
            position: 'top-right',
            status: 'error',
            title: `${i.message}`,
            description: '',
            duration: 9000,
            isClosable: true
          })
        }, 500 * idx)
      })
    }
  }
  return (
    <Box width={'400px'} p={4} backgroundColor="#fff" borderRadius={'4px'}>
      <Text fontSize="xl" textAlign={'center'} mb={4}>
        Xác nhận
      </Text>
      <Text textAlign={'center'} color="red" fontSize={'xs'}>
        Mã tham gia sẽ được gửi qua Số điện thoại
      </Text>
      <Flex align={'center'} mt={4} justifyContent="space-between">
        <Text>
          Mã tham gia<span style={{color: 'red'}}>*</span>
        </Text>
        <Input value={state.otp} name="otp" type={'number'} onChange={handleChangeText} maxW={'250px'} variant="outline" ml={2} />
      </Flex>
      <Flex justifyContent={'center'} mt={4}>
        <Button isDisabled={state.otp === ''} onClick={submit} color={'#fff'} _hover={{backgroundColor: '#1971B6'}} ml={2} height={'40px'} backgroundColor={'#2196F3'} borderRadius={'8px'} fontSize={12}>
          Xác nhận
        </Button>
      </Flex>
    </Box>
  )
}
function areEqual(p, n) {
  return true
}

export default React.memo(FormRegister, areEqual)
