import {useEffect, memo, useState, useCallback} from 'react'

const Loading = ({}) => {
  return (
    <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
      <div className="loader"></div>
    </div>
  )
}

function areEqual(p, n) {
  return true
}

export default memo(Loading, areEqual)
