import {Text} from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import bg from '../../../public/static/image-background/LOGO_02.png'
import config from '../../../constants/config'
import {useCallback} from 'react'

const PlayersList2 = ({id, players, league, size, isShowBirthDay, firstLoad, seasonData, teamData, checkboxPlayer, checkboxStaff, onLoadedParent}) => {
  const width = size
  const height = width / 1.5
  const px = width / 100
  const sizeItemLogo = px * 15
  const [listImage, setListImage] = React.useState([])
  const playersWrapperWidth = 90 * px
  let football_staff_type = []
  let football_player_type = []
  // const listPlayer = players?.reduce(
  //   (p, c) => {
  //     if (!p[c.types]) p[c.types] = []
  //     p[c.types].push(c)
  //     return p
  //   },
  //   {football_staff_type, football_player_type}
  // )

  const reSizeBasedOnWidth = () => {
    let fontSize = window && window?.getComputedStyle(document.querySelector('.text-2-line-custom'))?.fontSize
    document.querySelector('.text-2-line-custom').style.fontSize = parseFloat(fontSize) - 0.5 + 'px'
    if (document.querySelector('.text-2-line-custom').clientHeight >= document.querySelector('.container').clientHeight) {
      reSizeBasedOnWidth()
    }
  }

  const processInput = () => {
    document.querySelector('.text-2-line-custom').innerHTML = seasonData?.name
    document.querySelector('.text-2-line-custom').style.fontSize = px * 20 + 'pt' // Default font size
    reSizeBasedOnWidth()
  }

  React.useEffect(() => {
    if (seasonData.name) {
      processInput()
    }
  }, [seasonData])

  const saveImg = value => {
    setListImage(s => [...s, value])
  }

  React.useEffect(() => {
    setListImage([])
  }, [players])

  React.useEffect(() => {
    if (listImage.length === players.length) {
      onLoadedParent?.()
      setListImage([])
    }
  }, [listImage])

  const staffPosition = {football_coach: 'Huấn luyện viên', football_coach_assistant: 'Trợ lý HLV', football_marketing: 'Truyền thông', football_medical_staff: 'Y tế', football_other_staff: 'Thành viên', football_team_manager: 'Trưởng đoàn'}

  return (
    <div className="container-custom" style={{backgroundColor: 'white', width: width, paddingBottom: px * 5}}>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative'}}>
        <div id="outer" className="container" style={{textTransform: 'uppercase', width: '100%', paddingRight: '200px', marginTop: '4px', paddingLeft: '200px', height: '55px', fontSize: '20px'}}>
          <div className="text-2-line-custom" style={{textAlign: 'center', wordWrap: 'break-word', fontFamily: 'SFUHelveticaCondensedBold', fontSize: '50pt', color: styleConfig.colorText}}></div>
        </div>
        <div style={{position: 'absolute', display: 'flex', right: 10, top: teamData.name.length > 90 ? 11 : 12}}>
          <img src={seasonData?.logo} style={{width: sizeItemLogo / 2.5, marginRight: 15}} />
          <img src={bg} style={{width: sizeItemLogo / 2.5}} />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 10, top: teamData.name.length > 90 ? 12 : 18}}>
          <img src={teamData.logo} style={{width: sizeItemLogo / 3}} />
          <h3 style={{...styleConfig.fontStyleTextNormal, fontSize: teamData.name.length > 24 ? '8pt' : '12pt'}}>{teamData.name}</h3>
        </div>
        <h3 style={{...styleConfig.fontStyleTextNormal, fontSize: '20pt', textAlign: 'center', color: '#f48220', paddingTop: '0px'}} className="text-2-line-custom">
          DANH SÁCH THÀNH VIÊN
        </h3>
      </div>
      {players?.length > 0 && (
        <div style={{marginTop: `${(width - 10 * px) / 100}px`}}>
          <div style={{width: '100%'}}>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
              {players?.map((i, idx) => {
                const itempx = (width - 10 * px) / 100
                return (
                  <div
                    key={`${idx}+${i.id}`}
                    style={{
                      display: 'flex',
                      width: `${((19.5 * itempx) / 50) * 40}px`,
                      marginTop: idx > 6 ? `${((1 * itempx) / 50) * 40}px` : `${((5 * itempx) / 50) * 40}px`,
                      marginBottom: `${((1 * itempx) / 50) * 40}px`,
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}>
                    <div style={{display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      <Image onLoad={saveImg} alt={i.name} src={i.avatar || config.logo} style={{width: `${((23 * itempx) / 100) * 40}px`, height: `${((23 * itempx) / 100) * 40}px`, border: '4px solid #f48220', backgroundColor: 'white', padding: `${((5 * itempx) / 1200) * 40}px`, borderRadius: '50%', objectFit: 'cover'}} />
                      <h3 style={{marginTop: '5px', ...styleConfig.fontStyleTextNormal, fontSize: '11pt', textTransform: 'uppercase'}}>{staffPosition[i.positions[0]]}</h3>
                      <h3 style={{...styleConfig.fontStyleTextNormal, paddingLeft: '2px', paddingRight: '2px', fontSize: i.name.length > 20 ? `calc(${width / (i.name.length * 5.6)}pt) ` : '10pt', width: '100%', textAlign: 'center', textTransform: 'uppercase'}}>{i.name}</h3>
                      {isShowBirthDay && <h3 style={{...styleConfig.fontStyleTextNormal, fontSize: '10pt', textAlign: 'center', textTransform: 'uppercase'}}>{moment(i.birthday).format('DD-MM-YYYY')}</h3>}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
      {/* {listPlayer?.football_player_type?.length > 0 && checkboxPlayer && (
        <div style={{width: '100%'}}>
          <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            {listPlayer?.football_player_type?.map((i, idx) => {
              const itempx = (width - 10 * px) / 100
              return (
                <div
                  key={`${idx}+${i.id}`}
                  style={{
                    display: 'flex',
                    width: `${((19.5 * itempx) / 50) * 40}px`,
                    marginTop: (listPlayer?.football_staff_type?.length < 1 || !listPlayer?.football_staff_type) && idx < 7 ? `${((4 * itempx) / 50) * 100}px` : `${((1 * itempx) / 50) * 40}px`,
                    marginBottom: `${((1 * itempx) / 50) * 40}px`,
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}>
                  <div style={{display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
                    <Image onLoad={saveImg} src={i.avatar || config.logo} style={{width: `${((23 * itempx) / 100) * 40}px`, height: `${((23 * itempx) / 100) * 40}px`, border: '4px solid #325ea8', backgroundColor: 'white', padding: `${((5 * itempx) / 1200) * 40}px`, borderRadius: '50%', objectFit: 'cover'}} />
                    <div className="half-circle">
                      <h3 style={{color: '#fff', fontSize: '9pt', fontFamily: 'SFUHelveticaCondensedBold', paddingBottom: '5px'}}>{i.shirtNumber}</h3>
                    </div>
                    <div style={{height: `${((12 * itempx) / 100) * 40}px`, width: `${((18.5 * itempx) / 50) * 40}px`}}>
                      <h3 style={{...styleConfig.fontStyleTextNormal, paddingLeft: '2px', paddingRight: '2px', fontSize: i.name.length > 20 ? `calc(${width / (i.name.length * 5.6)}pt) ` : '10pt', width: '100%', textAlign: 'center', textTransform: 'uppercase'}}>{i.name}</h3>
                      {isShowBirthDay && <h3 style={{...styleConfig.fontStyleTextNormal, fontSize: '10pt', textAlign: 'center', textTransform: 'uppercase'}}>{moment(i.birthday).format('DD-MM-YYYY')}</h3>}{' '}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )} */}
    </div>
  )
}

const styleConfig = {
  fontStyleTextNormal: {fontFamily: 'SFUHelveticaCondensedBold', color: '#1d4380', fontSize: '8pt'},
  fontStyleNameOfTable: {fontFamily: 'SFUHelveticaCondensedBold', fontSize: '10pt', textAlign: 'center'},
  paddingTable: {padding: '8px 8px 8px 8x'},
  colorHeader: '#325ea8',
  colorText: '#1d4380',
  colorBodyTable: '#ffe5c2',
  colorTextName: '#f48220',
  colorTextHeader: '#fff'
}

const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return data ? <img {...props} src={data} alt="" onLoad={props.onLoad} /> : null
}

function getBase64(url) {
  return new Promise(resolve => {
    fetch(url, {cache: 'reload'})
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        }
        reader.readAsDataURL(blob)
      })
  })
}

const areEqual = (p, n) => {
  return JSON.stringify(p.players) === JSON.stringify(n.players) && JSON.stringify(p.checkboxPlayer) === JSON.stringify(n.checkboxPlayer) && JSON.stringify(p.checkboxStaff) === JSON.stringify(n.checkboxStaff) && JSON.stringify(p.isShowBirthDay) === JSON.stringify(n.isShowBirthDay)
}

export default React.memo(PlayersList2, areEqual)
