import React from 'react'
import moment from 'moment'
import img from '../../../../public/static/image-background/logo-v-02.png'
import locales from '../../../../public/static/locales/vi.json'
import imgbgschedules from '../../../../public/static/image-background/bgitemschedules.png'
import vs from '../../../../public/static/image-background/vs-02.png'
import {useTranslation, formatDateTime} from '../../../../constants/helpers'
import config from '../../../../constants/config'

const Mau1 = ({isDisabledTime, dataMatch, dataMatchDetail, size, id, sponsorData, isShowSponsor}) => {
  const {t} = useTranslation()
  const width = size

  const px = size / 100
  const color = {
    first: '#f48220',
    second: '#325ea8',
    third: '#ffe5c2',
    fourth: '#1d4380'
  }

  const arrNameRound = {quarterfinals: 'TỨ KẾT', semifinal: 'BÁN KẾT', '3rdPlace': 'TRANH HẠNG 3', final: 'CHUNG KẾT'}

  const arrRankName = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth']
  let teamHomeRankName = arrRankName.includes?.(dataMatch.homeTeam?.rankName) ? `${t(dataMatch.homeTeam?.rankName)}` : ''
  let teamAwayRankName = arrRankName.includes?.(dataMatch.awayTeam?.rankName) ? `${t(dataMatch.awayTeam?.rankName)}` : ''

  const arrName = ['Winquarterfinals_1', 'Winquarterfinals_2', 'Winquarterfinals_3', 'Winquarterfinals_4', 'Losesemifinal_1', 'Losesemifinal_2', 'Winsemifinal_1', 'Winsemifinal_2', 'UnknownName']

  let teamHomeName = arrName.includes(dataMatch.homeTeam?.name) || !dataMatch.homeTeam?.id ? `${t(dataMatch.homeTeam?.name) || dataMatch.homeTeam?.name}` : dataMatch.homeTeam?.name
  let teamAwayName = arrName.includes(dataMatch.awayTeam?.name) || !dataMatch.awayTeam?.id ? `${t(dataMatch.awayTeam?.name) || dataMatch.awayTeam?.name}` : dataMatch.awayTeam?.name

  let homeName = dataMatch?.homeTeam?.id ? teamHomeName : `${teamHomeRankName} ${teamHomeName}`
  let awayName = dataMatch.awayTeam?.id ? teamAwayName : `${teamAwayRankName} ${teamAwayName}`

  return (
    <div id={id} className="bg-match-mau1" style={{width: width, minHeight: width / 1.3, padding: `${1.6 * px}px`, fontFamily: 'SFUHelveticaCondensedBold'}}>
      <div style={{width: `100%`, minHeight: `calc(${width / 1.3}px - ${3.2 * px}px)`, padding: `${px * 1.2}px`, backgroundColor: '#fff', position: 'relative'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{flex: 1}}>
            <Image src={dataMatch?.season?.logo} style={{width: `${px * 9}px`, height: 'max-content'}} />
          </div>
          <div style={{flex: 6}}>
            <h1 style={{textAlign: 'center', color: color.fourth, fontSize: `${2.5 * px}px`, textTransform: 'uppercase'}}>{dataMatch?.season?.name}</h1>
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <Image src={img} style={{width: `${px * 9}px`, height: 'max-content'}} />
          </div>
        </div>
        <h1 style={{textAlign: 'center', color: color.first, fontSize: `${4.5 * px}px`, textTransform: 'uppercase', marginTop: `${px}px`}}>MATCH DAY</h1>

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: `${5 * px}px`}}>
          <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <Image src={dataMatch?.homeTeam?.logo} style={{width: `${20 * px}px`, height: `${20 * px}px`, borderRadius: '50%', border: `${0.4 * px}px solid ${color.fourth}`, objectFit: 'contain'}} />
            </div>
          </div>
          <div style={{flex: 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <Image src={vs} style={{width: `${10 * px}px`}} />
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <Image src={dataMatch?.awayTeam?.logo} style={{width: `${20 * px}px`, height: `${20 * px}px`, borderRadius: '50%', border: `${0.4 * px}px solid ${color.fourth}`, objectFit: 'contain'}} />
            </div>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: `${5 * px}px`, backgroundImage: `url(${imgbgschedules})`, height: `${12 * px}px`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}>
          <div style={{flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
            <Name propClass={`home${dataMatch?.homeTeam?.id}${new Date().getTime()}`} name={homeName} px={px} />
          </div>
          {dataMatch?.isCompleted ? (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
              <h2 style={{flex: 0.5, color: '#fff', textAlign: 'center', fontSize: `${1.4 * px}px`, marginTop: `${0.5 * px}px`}}>{formatDateTime(dataMatch?.dateTime, 'dateTime')}</h2>
              <div style={{flex: 1, color: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <div style={{marginBottom: dataMatch?.hasPenalty ? `${0.8 * px}px` : `${1.8 * px}px`}}>
                  {!dataMatch?.homeTeam?.isGiveUp &&
                    !dataMatch?.awayTeam?.isGiveUp && ( // ko doi nao bo cuoc
                      <p style={{lineHeight: `${2.5 * px}px`, fontSize: `${3.6 * px}px`, textAlign: 'center'}}>
                        {dataMatch?.homeTeam?.score}
                        <span style={{margin: `0 ${px}px`}}>-</span>
                        {dataMatch?.awayTeam?.score}
                      </p>
                    )}
                  {dataMatch?.homeTeam?.isGiveUp &&
                    !dataMatch?.awayTeam?.isGiveUp && ( // doi nha bo cuoc
                      <p style={{lineHeight: `${2.5 * px}px`, fontSize: `${3.6 * px}px`, textAlign: 'center'}}>
                        0<span style={{margin: `0 ${px}px`}}>-</span>
                        {dataMatch?.awayTeam?.giveUpScore}
                      </p>
                    )}
                  {!dataMatch?.homeTeam?.isGiveUp &&
                    dataMatch?.awayTeam?.isGiveUp && ( // doi khach bo cuoc
                      <p style={{lineHeight: `${2.5 * px}px`, fontSize: `${3.6 * px}px`, textAlign: 'center'}}>
                        {dataMatch?.homeTeam?.giveUpScore}
                        <span style={{margin: `0 ${px}px`}}>-</span>0
                      </p>
                    )}
                  {dataMatch?.homeTeam?.isGiveUp &&
                    dataMatch?.awayTeam?.isGiveUp && ( // ca 2 doi cung bo cuoc
                      <p style={{lineHeight: `${2.5 * px}px`, fontSize: `${3.6 * px}px`, textAlign: 'center'}}>
                        <span style={{margin: `0 ${px}px`}}>-</span>
                      </p>
                    )}
                </div>
                {dataMatch?.hasPenalty && (
                  <div style={{marginBottom: `${1 * px}px`}}>
                    <p style={{textTransform: 'uppercase', textAlign: 'center', lineHeight: `${1.5 * px}px`, fontSize: `${1.4 * px}px`, marginTop: `${0.5 * px}px`}}>{`(${t('pk')}: ${dataMatch?.homeTeam?.penaltyScore} - ${dataMatch?.awayTeam?.penaltyScore})`}</p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
              <h2 style={{color: '#fff', textAlign: 'center', fontSize: `${1.6 * px}px`, lineHeight: `${3 * px}px`, textTransform: 'uppercase', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `${12 * px}px`, overflow: 'hidden'}}>{dataMatch?.stadium ? dataMatch?.stadium : ''}</h2>
              <div style={{paddingBottom: `${2 * px}px`}}>
                <h2 style={{color: '#fff', textAlign: 'center', fontSize: `${1.5 * px}px`, lineHeight: `${2 * px}px`}}>{formatDateTime(dataMatch?.dateTime, 'date')}</h2>
                <h2 style={{color: '#fff', textAlign: 'center', fontSize: `${3.5 * px}px`, lineHeight: `${3 * px}px`}}>{formatDateTime(dataMatch?.dateTime, 'time')}</h2>
              </div>
            </div>
          )}
          <div style={{flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
            <Name propClass={`away${dataMatch?.awayTeam?.id}${new Date().getTime()}`} name={awayName} px={px} />
          </div>
        </div>
        {isShowSponsor && sponsorData?.length > 0 && (
          <div style={{height: `${8 * px}px`, width: `calc(${width - 2.4 * px})px`, backgroundImage: `url(/BG_DSPLAYER.png)`, bottom: px * 0.5, padding: `${0.8 * px}px 0px ${0.8 * px}px 0px`, borderRadius: `${px * 6}px`, marginTop: `${2 * px}px`}}>
            <div style={{width: '98%', height: '100%', backgroundColor: '#fff', margin: '0 auto', borderRadius: `${5 * px}px`, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              {sponsorData?.length > 0 && sponsorData?.slice(0, 10)?.map((i, idx) => <Image key={idx} src={i.logo || config.logo} style={{width: `auto`, maxHeight: `${px * 6}px`, backgroundColor: 'white', objectFit: 'cover', marginRight: `${2 * px}px`}} />)}
            </div>
          </div>
        )}
        <p style={{textAlign: 'center', color: color.first, fontSize: `${2 * px}px`, position: 'absolute', bottom: `${0.5 * px}px`, width: '100%'}}>VSPORTS.VN</p>
      </div>
    </div>
  )
}
const Name = React.memo(
  ({name, px, propClass}) => {
    const reSizeBasedOnWidth = () => {
      let fontSize = window && window?.getComputedStyle(document.querySelector(`.${propClass}`))?.fontSize
      document.querySelector(`.${propClass}`).style.fontSize = parseFloat(fontSize) - 0.01 * px + 'px'
      if (document.querySelector(`.${propClass}`).clientHeight >= document.querySelector(`.contain${propClass}`).clientHeight) {
        reSizeBasedOnWidth()
      }
    }
    const processInput = () => {
      document.querySelector(`.${propClass}`).innerHTML = name
      document.querySelector(`.${propClass}`).style.fontSize = px * 4 + 'px' // Default font size
      reSizeBasedOnWidth()
    }

    React.useEffect(() => {
      processInput()
    }, [name])

    return (
      <div className={`contain${propClass}`} style={{flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: `${4.5 * px}px`, marginTop: `${px}px`, maxWidth: '100%'}}>
        <h2 className={propClass} style={{textAlign: 'center', width: '80%', color: '#fff', textTransform: 'uppercase'}}></h2>
      </div>
    )
  },
  (p, n) => JSON.stringify(p.name) === JSON.stringify(n.name)
)
const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function getBase64(url) {
  return new Promise(resolve => {
    fetch(url, {cache: 'reload'})
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        }
        reader.readAsDataURL(blob)
      })
  })
}
function areEqual(p, n) {
  return JSON.stringify(p.isDisabledTime) === JSON.stringify(n.isDisabledTime) && JSON.stringify(p.dataMatch) === JSON.stringify(n.dataMatch) && JSON.stringify(p.dataMatchDetail) === JSON.stringify(n.dataMatchDetail) && JSON.stringify(p.isShowSponsor) === JSON.stringify(n.isShowSponsor) && JSON.stringify(p.sponsorData) === JSON.stringify(n.sponsorData)
}

export default React.memo(Mau1, areEqual)
