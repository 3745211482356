import React from 'react'
import moment from 'moment'
import config from '../../../constants/config'

const Mau2 = ({id, player, season, team, isShowBirthDay, onLoadedParent}) => {
  const width = 640
  const height = width * 1.41
  const px = width / 100
  const fontSizeTable = px * 2 + 'pt'
  const fontSizeHeader = px * 3 + 'pt'
  const sizeItemLogo = px * 15
  const styleConfig = {
    fontStyleTextNormal: {fontFamily: 'SFUHelveticaCondensedBold', color: '#1d4380', fontSize: fontSizeTable},
    fontStyleNameOfTable: {fontFamily: 'SFUHelveticaCondensedBold', fontSize: fontSizeHeader, textAlign: 'center'},
    paddingTable: {padding: '8px 8px 8px 8x'},
    colorHeader: '#325ea8',
    colorText: '#1d4380',
    colorBodyTable: '#ffe5c2',
    colorTextName: '#c74a1b',
    colorTextHeader: '#fff'
  }
  const member_type = {
    football_coach: 'HUẤN LUYỆN VIÊN',
    football_player_type: 'VẬN ĐỘNG VIÊN',
    football_medical_staff: 'SĂN SÓC VIÊN',
    football_marketing: 'TRUYỀN THÔNG',
    football_coach_assistant: 'TRỢ LÝ HUẤN LUYỆN VIÊN',
    football_other_staff: 'THÀNH VIÊN',
    football_team_manager: 'TRƯỞNG ĐOÀN'
  }

  const reSizeBasedOnWidth = name => {
    if (name === 'seasonName') {
      let fontSize = window && window?.getComputedStyle(document.querySelector('.text-2-line-custom'))?.fontSize
      document.querySelector('.text-2-line-custom').style.fontSize = parseFloat(fontSize) - 0.2 + 'px'
      if (document.querySelector('.text-2-line-custom').clientHeight >= document.querySelector('.container').clientHeight) {
        reSizeBasedOnWidth('seasonName')
      }
    }
    if (name === 'playerType') {
      let fontSize = window && window?.getComputedStyle(document.querySelector('.text-2-line-custom2'))?.fontSize
      document.querySelector('.text-2-line-custom2').style.fontSize = parseFloat(fontSize) - 0.2 + 'px'
      if (document.querySelector('.text-2-line-custom2').clientHeight >= document.querySelector('.container2').clientHeight) {
        reSizeBasedOnWidth('playerType')
      }
    }
    if (name === 'playerName') {
      let fontSize = window && window?.getComputedStyle(document.querySelector('.text-2-line-custom3'))?.fontSize
      document.querySelector('.text-2-line-custom3').style.fontSize = parseFloat(fontSize) - 1 + 'px'
      if (document.querySelector('.text-2-line-custom3').clientHeight >= document.querySelector('.container3').clientHeight) {
        reSizeBasedOnWidth('playerName')
      }
    }
    if (name === 'teamName') {
      let fontSize = window && window?.getComputedStyle(document.querySelector('.text-2-line-custom4'))?.fontSize
      document.querySelector('.text-2-line-custom4').style.fontSize = parseFloat(fontSize) - 1 + 'px'
      if (document.querySelector('.text-2-line-custom4').clientHeight >= document.querySelector('.container4').clientHeight) {
        reSizeBasedOnWidth('teamName')
      }
    }
  }

  const processInput = (name, value) => {
    if (name === 'seasonName') {
      document.querySelector('.text-2-line-custom').innerHTML = value
      document.querySelector('.text-2-line-custom').style.fontSize = px * 10 + 'pt' // Default font size
      reSizeBasedOnWidth('seasonName')
    }
    if (name === 'playerType') {
      document.querySelector('.text-2-line-custom2').innerHTML = value
      document.querySelector('.text-2-line-custom2').style.fontSize = px * 10 + 'pt' // Default font size
      document.querySelector('.text-2-line-custom2').style.fontWeight = 'bold' // Default font size
      reSizeBasedOnWidth('playerType')
    }
    if (name === 'playerName') {
      document.querySelector('.text-2-line-custom3').innerHTML = value
      document.querySelector('.text-2-line-custom3').style.fontSize = px * 8 + 'pt' // Default font size
      reSizeBasedOnWidth('playerName')
    }
    if (name === 'teamName') {
      document.querySelector('.text-2-line-custom4').innerHTML = value
      document.querySelector('.text-2-line-custom4').style.fontSize = px * 6 + 'pt' // Default font size
      reSizeBasedOnWidth('teamName')
    }
  }

  React.useLayoutEffect(() => {
    if (season?.name) {
      processInput('seasonName', season?.name)
    }
  }, [season?.name])

  React.useLayoutEffect(() => {
    if (player?.types?.[0]) {
      processInput('playerType', player?.types?.[0].includes('player') ? member_type[player?.types?.[0]] : member_type[player.positions[0]])
    }
    if (player?.name) {
      processInput('playerName', player?.name?.toUpperCase())
    }
  }, [player])

  React.useLayoutEffect(() => {
    if (team?.name) {
      processInput('teamName', team?.name)
    }
  }, [team])

  if (!player) return null

  return (
    <div id={id} style={{backgroundImage: player?.types?.[0] !== 'football_player_type' ? 'url(/BG-BHL.png)' : 'url(/BG-VDV.png)', width, height, backgroundSize: 'cover', position: 'relative'}}>
      <div style={{width: '100%', display: 'flex', position: 'absolute', top: 6.5 * px, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 13 * px}}>
        <img src={season.logo} style={{width: sizeItemLogo / 1.5, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: sizeItemLogo / 1.5}} />
      </div>
      <div id="outer" className="container" style={{width: '100%', display: 'flex', position: 'absolute', top: 17.5 * px, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 12 * px}}>
        <div className="text-2-line-custom" style={{width: '83%', textAlign: 'center', wordWrap: 'break-word', fontFamily: 'SFUHelveticaCondensedBold', fontSize: '50pt', textTransform: 'uppercase', color: styleConfig.colorText}}></div>
      </div>
      <Image onLoad={onLoadedParent} crossOrigin="anonymous" alt={player?.name || ''} src={player?.avatar} style={{position: 'absolute', padding: px, top: 34.2 * px, left: 27.2 * px, width: 46 * px, height: 46 * px, borderRadius: '50%', objectFit: 'contain', backgroundColor: '#fff'}} />
      <div id="outer2" className="container2" style={{width: '100%', display: 'flex', position: 'absolute', top: 0 * px, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 13 * px}}>
        <div className="text-2-line-custom2" style={{width: '80%', marginTop: 168 * px, fontFamily: 'SFUHelveticaCondensedBold', fontWeight: '900', textAlign: 'center', textTransform: 'uppercase', color: styleConfig.colorTextName}}></div>
      </div>
      <div id="outer3" className="container3" style={{width: '100%', display: 'flex', position: 'absolute', top: 0 * px, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 10 * px}}>
        <div className="text-2-line-custom3" style={{width: '80%', marginTop: 192 * px, fontSize: 7 * px, fontFamily: 'SFUHelveticaCondensedBold', fontWeight: '600', textAlign: 'center', textTransform: 'uppercase', color: styleConfig.colorText}}></div>
      </div>
      <div style={{width: '100%', display: 'flex', position: 'absolute', marginTop: player?.types?.[0].includes('player') ? 108 * px : 105 * px, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 10 * px}}>
        {isShowBirthDay && <div style={{width: '80%', fontFamily: 'SFUHelveticaCondensedBold', marginTop: !isShowBirthDay ? '-60px' : 0, fontWeight: '900', textAlign: 'center', textTransform: 'uppercase', color: styleConfig.colorText, fontSize: 5 * px}}>NGÀY SINH: {moment(player?.birthday)?.format('DD/MM/YYYY')}</div>}
        {player.types?.[0] === 'football_player_type' && <div style={{width: '80%', fontFamily: 'SFUHelveticaCondensedBold', fontWeight: '900', textAlign: 'center', textTransform: 'uppercase', fontSize: 5 * px, color: styleConfig.colorText}}>SỐ ÁO: {player?.shirtNumber}</div>}
      </div>
      <div id="outer4" className="container4" style={{width: '100%', display: 'flex', position: 'absolute', top: 0 * px, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 8 * px}}>
        <div className="text-2-line-custom4" style={{width: '80%', marginTop: 243.5 * px, fontSize: 7 * px, fontFamily: 'SFUHelveticaCondensedBold', fontWeight: '600', textAlign: 'center', textTransform: 'uppercase'}}></div>
      </div>
    </div>
  )
}

const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return data ? <img {...props} onLoad={props.onLoad} src={data} /> : null
}

function getBase64(url) {
  return new Promise(resolve => {
    fetch(url, {cache: 'reload'})
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        }
        reader.readAsDataURL(blob)
      })
  })
}

function areEqual(p, n) {
  return JSON.stringify(p.team) === JSON.stringify(n.team) && JSON.stringify(p.player) === JSON.stringify(n.player) && JSON.stringify(p.season) === JSON.stringify(n.season)
}

export default React.memo(Mau2, areEqual)
