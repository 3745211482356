import React from 'react'
import FormRegister from './component/formregister'
import RotationLuck from './component/rotationluck'
import * as APIPrizes from '../../api/userLuckyDraw'

const Home = ({}) => {
  const eventId = localStorage.getItem('eventId') || ''
  const code = localStorage.getItem('code') || ''
  const defaultView = code !== '' && eventId !== '' ? 'rotation' : 'form'
  const [state, setState] = React.useState({
    view: defaultView,
    data: {},
    code: '' //truyen vao vong quay
    // data event dung de truyen prop cho Rotation
  })
  const [itemWin, setItemWin] = React.useState('')

  React.useEffect(() => {
    if (eventId.length > 0 && code.length > 0) {
      // neu ton tai localstorage luu user thi goi data de truyen vao component RotationLuck de hien thi phan qua dc trung thuong
      fetchData()
    }
  }, [])
  const fetchData = () => {
    APIPrizes.getEventById(eventId, code).then(res => {
      setState(s => ({...s, data: res}))
    })
  }
  const handleChangeItemWin = data => {
    setItemWin(data)
  }
  const view = {
    form: (
      <FormRegister
        onChangeView={() => {
          setState(s => ({...s, view: 'rotation'}))
        }}
        onChangeData={data => {
          setState(s => ({...s, data: data}))
        }}
        onChangeCode={data => {
          setState(s => ({...s, code: data}))
        }}
      />
    ),
    rotation: <RotationLuck data={state.data} code={state.code} onChangeItemWin={handleChangeItemWin} />
  }
  return view[state.view]
  // return <RotationLuck data={state.data} onChangeItemWin={handleChangeItemWin} />
}

function areEqual(p, n) {
  return true
}

export default React.memo(Home, areEqual)
