import React from 'react'
import moment from 'moment'
import * as htmlToImage from 'html-to-image'
import ReactPaginate from 'react-paginate'
import {Button, Box, Text, Flex, Checkbox} from '@chakra-ui/react'
import {useParams, useLocation} from 'react-router-dom'
import {MdArrowBackIos} from 'react-icons/md'
import Mau1 from './components/mau1'
import Mau2 from './components/mau2'
import {MdUpdate} from 'react-icons/md'
import imgbg from '../../../public/static/image-background/imgChooseResultsC1Mau3.jpeg'
import imgbg2 from '../../../public/static/image-background/c2choosemausemifinal.jpeg'

import * as leaguesAPI from '../../../api/leagues'
import {BrowserRouter, Routes, Route, useSearchParams} from 'react-router-dom'

const ExportResults = () => {
  let {leagueId, seasonId} = useParams()
  const [searchParams] = useSearchParams()
  const numberRound = searchParams.get('numberRound')
  // const {isOpen, onOpen, onClose} = useDisclosure()
  const [type, setType] = React.useState(null)
  const [state, setState] = React.useState({
    downloading: false,
    loading: true,
    loadingTeam: true,
    dataResults: [],
    dataSeason: {},
    loadingDataSeason: true,
    loadingDataResults: true
  })
  const [show, setShow] = React.useState(false)
  const [selected, setSelected] = React.useState(0)

  const mau1 = React.useRef(new Date().toISOString() + 'match')
  const mau2 = React.useRef(new Date().toISOString() + 'match')
  const mau3 = React.useRef(new Date().toISOString() + 'match')

  const idMau1 = mau1.current
  const idMau2 = mau2.current

  const select = value => () => {
    setSelected(value)
    setShow(true)
  }
  const fetchData = React.useCallback(() => {
    leaguesAPI
      .getSchedulesBySeasonIdV2(leagueId, seasonId, numberRound, 'results', 'all', 'all')
      .then(res => {
        console.log('res', res)
        setState(s => ({...s, dataResults: res, loadingDataResults: false}))
      })
      .catch(e => {
        setState(s => ({...s, loadingDataResults: false}))

        console.log('error', e)
      })
    leaguesAPI
      .getSeasonByIdSeasonAndIdLeague(leagueId, seasonId)
      .then(res => {
        setState(s => ({...s, dataSeason: res, loadingDataSeason: false}))
      })
      .catch(e => {
        setState(s => ({...s, loadingDataSeason: false}))
        console.log('error', e)
      })
  }, [leagueId, numberRound])

  React.useEffect(() => {
    fetchData()
  }, [fetchData])

  React.useEffect(() => {
    switch (selected) {
      case 1:
        setType(mau1.current)
        break
      case 2:
        setType(mau2.current)
        break
      case 3:
        setType(mau3.current)
        break
      default:
        break
    }
  }, [selected])

  const css = {
    cursor: 'pointer',
    border: '2px solid #fff',
    borderRadius: '2px'
  }
  /////////////
  ///////////
  /////////
  ///////

  const exportImage = async () => {
    if (state.downloading) return
    setState(s => ({...s, downloading: true}))
    htmlToImage
      .toJpeg(document.getElementById(type), {pixelRatio: 3, quality: 1})
      .then(dataUrl => {
        var link = document.createElement('a')
        link.download = `lichthidau.jpeg`
        link.href = dataUrl
        link.click()
        setState(s => ({...s, downloading: false}))
      })
      .catch(function (error) {
        setState(s => ({...s, downloading: false}))
        console.error('oops, something went wrong!', error)
      })
  }
  React.useEffect(() => {
    // Send `message` to the parent using the postMessage method on the window.parent reference.
    window.parent.postMessage('exportResultsSemiFinal', '*')
  }, [])
  if (state.loadingDataSeason || state.loadingDataResults) {
    return (
      <Box height={'50vh'} display="flex" flexDirection={'column'} alignItems={'center'} justifyContent="center">
        <Text>Đang tải dữ liệu . . . !</Text>
      </Box>
    )
  }
  if (show) {
    return (
      <Box>
        <Flex display={'flex'} flexDirection="row" justifyContent={'space-between'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            mb={3}
            onClick={() => {
              setShow(false)
            }}
            cursor="pointer">
            <MdArrowBackIos size={'28px'} />
            <Text>Trở lại</Text>
          </Box>
          <Flex display="flex" flexDirection={'row'} justifyContent="flex-end">
            <Button ml={4} isLoading={state.downloading} colorScheme="blue" onClick={exportImage}>
              Tải xuống
            </Button>
          </Flex>
        </Flex>
        <Flex mt={4} display={'flex'} flexDirection="row" justifyContent={'center'}>
          <Box>
            <Box maxWidth={'920px'} margin="0 auto" display="flex" flexDirection={'column'} justifyContent="center">
              {selected === 1 && (
                <>
                  <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <Mau1 dataSeason={state.dataSeason} dataResults={state.dataResults} id={idMau1} size={1800} numberRound={numberRound} />
                  </div>
                  <Mau1 dataSeason={state.dataSeason} dataResults={state.dataResults} size={900} numberRound={numberRound} />
                </>
              )}
              {/* {selected === 2 && (
                <>
                  <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <Mau2 dataSeason={state.dataSeason} dataResults={state.dataResults} id={idMau2} size={1800} numberRound={numberRound} />
                  </div>
                  <Mau2 dataSeason={state.dataSeason} dataResults={state.dataResults} size={900} numberRound={numberRound} />
                </>
              )} */}
            </Box>
          </Box>
        </Flex>
      </Box>
    )
  } else {
    return (
      <Box height={'100%'} display="flex" flexDirection={'column'} justifyContent="space-between">
        <Flex display={'flex'} flexDirection={'row'} justifyContent={'space-between'} w={'100%'} height={'46%'}>
          <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} height={'100%'} onClick={select(1)}>
            <Image style={{height: '100%', margin: '0 auto'}} src={imgbg} />
          </Box>
          <Box backgroundColor={'#eeeeee'} className="pointer" p={4} width={'24%'} height={'auto'} position="relative">
            <MdUpdate size={30} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%'}} />
          </Box>
          {/* <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} height={'100%'}>
            <Image style={{height: '100%', margin: '0 auto'}} src={imgbg2} />
          </Box> */}
          <Box backgroundColor={'#eeeeee'} className="pointer" p={4} width={'24%'} height={'auto'} position="relative">
            <MdUpdate size={30} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%'}} />
          </Box>
          <Box backgroundColor={'#eeeeee'} className="pointer" p={4} width={'24%'} height={'auto'} position="relative">
            <MdUpdate size={30} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%'}} />
          </Box>
        </Flex>
      </Box>
    )
  }
}

const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function getBase64(url) {
  return new Promise(resolve => {
    fetch(url, {cache: 'reload'})
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        }
        reader.readAsDataURL(blob)
      })
  })
}
function areEqual(p, n) {
  return true
}

export default React.memo(ExportResults, areEqual)
