import React from 'react'
import * as htmlToImage from 'html-to-image'
import JsZip from 'jszip'
import FileSaver from 'file-saver'
import {useParams} from 'react-router-dom'
import Mau1 from './layoutthedeo/mau1.js'
// import Mau5 from './layoutthedeo/mau5.js'
import * as leaguesAPI from '../../api/leagues'
import {Button} from '@chakra-ui/react'

const TheDeo = () => {
  let {leagueId, seasonId, teamId} = useParams()
  const [exportIndex, setExportIndex] = React.useState(-1)
  const templates = [
    {key: 'mau1', thumbnail: '/thedeomau1.jpg'}
    // {key: 'mau2', thumbnail: '/thedeomau2.jpg'},
    // {key: 'mau3', thumbnail: '/thedeomau3.jpg'},
    // {key: 'mau4', thumbnail: '/thedeomau4.jpg'},
    // {key: 'mau5', thumbnail: '/thedeomau5.jpg'}
  ]
  const [mauId, setMauId] = React.useState('mau1')
  const [leagueData, setLeagueData] = React.useState(null)

  const [players, setPlayers] = React.useState([])

  const [blobs, setBlobs] = React.useState([])

  React.useEffect(() => {
    fetchData()
  }, [])
  React.useEffect(() => {
    if (exportIndex > -1) {
      if (exportIndex === players?.length) {
        exportZip()
      } else {
        exportBlob()
      }
    }
  }, [exportIndex])

  const xuat = mauId => () => {
    if (exportIndex > -1) return
    if (!leagueData) return alert('Không tìm thấy giải dấu')
    if (!players.length) return alert('Không tìm thấy danh sách thành viên đội')

    setMauId(mauId)
    setExportIndex(0)
  }
  const fetchData = React.useCallback(async () => {
    try {
      const league = await leaguesAPI.old_getLeagueById(leagueId, seasonId).then(r => r.data)
      const players = await leaguesAPI.old_getTeamPlayersBySeasonId(seasonId, teamId).then(r => r.data)
      setLeagueData(league)
      setPlayers(players)
    } catch (error) {}
  }, [seasonId, teamId])

  const exportBlob = () => {
    htmlToImage
      .toBlob(document.getElementById('thedeo'), {pixelRatio: 1, quality: 1, cacheBust: true})
      .then(blob => {
        setBlobs(s => [...s, blob])
        setExportIndex(i => i + 1)
      })
      .catch(error => {
        console.error('oops, something went wrong!', error)
      })
  }

  const exportZip = () => {
    const zip = JsZip()
    blobs.forEach((blob, idx) => {
      zip.file(`TheThiDau-${players[idx].name}.png`, blob)
    })
    zip.generateAsync({type: 'blob'}).then(zipFile => {
      const fileName = `TheDeo-Team-${teamId}.zip`
      setExportIndex(-1)
      setBlobs([])
      return FileSaver.saveAs(zipFile, fileName)
    })
  }

  return (
    <div>
      <div style={{opacity: 0, position: 'absolute', top: 0, left: 0}}>{mauId === 'mau1' && <Mau1 id="thedeo" player={players[exportIndex]} league={leagueData} />}</div>
      <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
        {templates.map((i, idx) => (
          <div key={idx} style={{margin: '8px', position: 'relative', cursor: 'pointer'}} onClick={xuat(i.key)}>
            <img src={i.thumbnail} style={{width: '200px', height: '300px', borderRadius: '8px'}} />
            {exportIndex > -1 && i.key === mauId && (
              <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.7)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <span style={{color: '#fff', textAlign: 'center'}}>Đang tải {Math.round((exportIndex * 100) / players.length)}%</span>
              </div>
            )}
            <Button w="100%" mt={1} colorScheme="blue" onClick={xuat(i.key)}>
              Tải xuống
            </Button>
          </div>
        ))}
      </div>
    </div>
  )
}

function areEqual(p, n) {
  return true
}

export default React.memo(TheDeo, areEqual)
