export const VS = ({color}) => (
  <svg width="30" height="22" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2218_2103)">
      <path d="M4.41514 0.764648L5.17902 7.87616L9.74968 0.764648H13.3054L2.79708 16.7646L0.96593 1.63508L0.956901 1.65314L0.308594 0.764648H4.41514Z" fill="#FB8C00" />
      <path
        d="M15.021 8.76465C12.0576 8.76465 10.9379 7.41928 11.6603 4.72853C12.3646 2.08654 14.1993 0.764648 17.1628 0.764648H22.4955L17.9899 4.32041H16.2129C15.6206 4.32041 15.2883 4.45765 15.2178 4.72853C15.1294 5.04817 15.3804 5.20889 15.9727 5.20889H17.7497C20.7131 5.20889 21.8418 6.53079 21.1357 9.17458C20.4133 11.8653 18.5695 13.2107 15.6061 13.2107H6.80611L9.31627 9.65494H16.5578C17.1501 9.65494 17.4914 9.49422 17.5799 9.17458C17.6504 8.90189 17.3903 8.76465 16.798 8.76465H15.021Z"
        fill="#FB8C00"
      />
    </g>
    <defs>
      <clipPath id="clip0_2218_2103">
        <rect width="22.1869" height="16" fill="white" transform="translate(0.308594 0.764648)" />
      </clipPath>
    </defs>
  </svg>
)
