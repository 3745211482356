import React from 'react'
import moment from 'moment'
import img from '../../../../public/static/image-background/logo-v-02.png'
import imgbgschedules from '../../../../public/static/image-background/bgresultsandschedules.png'
import {json} from 'react-router-dom'
import locales from '../../../../public/static/locales/vi.json'
import {useTranslation, formatDateTime, titleTemplate} from '../../../../constants/helpers'

const Mau2 = ({dataSeason, dataSchedules, size, id, numberRound}) => {
  const {t} = useTranslation()

  const width = size
  const px = size / 100
  const color = {
    first: '#000',
    second: '#c4161c',
    third: '#fff'
  }
  const classCustom = size < 1000 ? 'view' : 'export'
  return (
    <div id={id} className="bg-match-mau2-2" style={{width: width, height: `${width / 1.5}px`, padding: `${2 * px}px`, fontFamily: 'SFUHelveticaCondensedBold', position: 'relative'}}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <div style={{flex: 1}}>
          <Image src={dataSeason?.logo} style={{width: `${px * 6}px`, height: `${px * 6}px`, margin: `${px}px 0 0 ${1.8 * px}px`, borderRadius: '50%'}} />
        </div>
        <div style={{flex: 6, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <h1 style={{textAlign: 'center', color: color.first, fontSize: `${2.8 * px}px`, textTransform: 'uppercase'}}>{dataSeason?.name}</h1>
        </div>
        <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
          <Image src={img} style={{width: `${px * 6}px`, height: 'max-content', margin: `${px}px ${1.8 * px}px 0 0`}} />
        </div>
      </div>
      <h1 style={{fontFamily: 'SFUHelveticaCondensedBlack', textAlign: 'center', color: color.second, fontSize: `${5 * px}px`, textTransform: 'uppercase', lineHeight: `${6 * px}px`}}>{t(titleTemplate(dataSchedules[0]?.dataGo))}</h1>
      <div style={{width: '100%'}}>
        <h2 style={{fontFamily: 'SFUHelveticaCondensedBlack', textTransform: 'uppercase', margin: '0 auto', width: 'max-content', color: color.fourth, padding: `${0.5 * px}px ${5 * px}px`, borderRadius: `${1.5 * px}px`, fontSize: `${2.6 * px}px`, textTransform: 'uppercase'}}>
          {' '}
          {t('final')}-{t('has_3rd_place')}
        </h2>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        {dataSchedules
          ?.find(i => i.numberRound?.numberRound === 'final')
          ?.dataGo?.map((i, idx) => (
            <Item key={`${idx}${new Date()}`} data={i} imgbgschedules={imgbgschedules} px={px} classCustom={`${classCustom}final`} idx={idx} />
          ))}
        {dataSchedules
          ?.find(i => i.numberRound?.numberRound === '3rdPlace')
          ?.dataGo?.map((i, idx) => (
            <Item key={`${idx}${new Date()}`} data={i} imgbgschedules={imgbgschedules} px={px} classCustom={`${classCustom}3rdPlace`} idx={idx} />
          ))}
      </div>
      <p style={{textAlign: 'center', color: color.first, fontSize: `${2 * px}px`, position: 'absolute', bottom: `${2 * px}px`, right: `${5.2 * px}px`}}>VSPORTS.VN</p>
    </div>
  )
}
const Item = React.memo(
  ({data, imgbgschedules, px, classCustom, idx}) => {
    const color = {
      first: '#000',
      second: '#c4161c',
      third: '#fff'
    }
    const {t} = useTranslation()

    const arrRankName = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth']
    let teamHomeRankName = arrRankName.includes?.(data.homeTeam?.rankName) ? `${t(data.homeTeam?.rankName)}` : ''
    let teamAwayRankName = arrRankName.includes?.(data.awayTeam?.rankName) ? `${t(data.awayTeam?.rankName)}` : ''

    const arrName = ['Winquarterfinals_1', 'Winquarterfinals_2', 'Winquarterfinals_3', 'Winquarterfinals_4', 'Losesemifinal_1', 'Losesemifinal_2', 'Winsemifinal_1', 'Winsemifinal_2', 'UnknownName']

    let teamHomeName = arrName.includes(data.homeTeam?.name) || !data.homeTeam?.id ? `${t(data.homeTeam?.name) || data.homeTeam?.name}` : data.homeTeam?.name
    let teamAwayName = arrName.includes(data.awayTeam?.name) || !data.awayTeam?.id ? `${t(data.awayTeam?.name) || data.awayTeam?.name}` : data.awayTeam?.name

    let homeName = data?.homeTeam?.id ? teamHomeName : `${teamHomeRankName} ${teamHomeName}`
    let awayName = data.awayTeam?.id ? teamAwayName : `${teamAwayRankName} ${teamAwayName}`

    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', marginTop: `${2 * px}px`, width: `${90 * px}px`}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
          <div style={{flex: 1.2}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: `-${4.5 * px}px`}}>
              <Image src={data?.homeTeam?.logo} style={{width: `${px * 9}px`, height: `${9 * px}px`, borderRadius: `50%`, objectFit: 'cover'}} />
            </div>
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
            <h2 style={{textAlign: 'center', fontSize: `${2 * px}px`, color: color.second}}>{data?.numberRound === '3rdPlace' ? `${t('has_3rd_place')}` : `${t('final')}`}</h2>
          </div>
          <div style={{flex: 1.2}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: `-${4.5 * px}px`}}>
              <Image src={data?.awayTeam?.logo} style={{width: `${px * 9}px`, height: `${9 * px}px`, borderRadius: `50%`, objectFit: 'cover'}} />
            </div>
          </div>
        </div>
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundImage: `url(${imgbgschedules})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: `${11.8 * px}px`, marginTop: `${px}px`}}>
          <div style={{flex: 1.2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginBottom: `-${3.6 * px}px`}}>
            <Name propClass={`home${data?.homeTeam?.id}${new Date().getTime()}${classCustom}${idx}`} name={homeName} px={px} />
          </div>
          {data?.isCompleted ? (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: `${1.6 * px}px`}}>
                <h2 style={{color: '#fff', textAlign: 'center', fontSize: `${1.8 * px}px`, lineHeight: `${1 * px}px`}}>{formatDateTime(data?.dateTime, 'dateTime')}</h2>
              </div>
              <div style={{color: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: `${2.6 * px}px`}}>
                {!data?.homeTeam?.isGiveUp &&
                  !data?.awayTeam?.isGiveUp && ( // ko doi nao bo cuoc
                    <p style={{lineHeight: `${2.5 * px}px`, fontSize: `${3.6 * px}px`, textAlign: 'center'}}>
                      {data?.homeTeam?.score}
                      <span style={{margin: `0 ${px}px`}}>-</span>
                      {data?.awayTeam?.score}
                    </p>
                  )}
                {data?.homeTeam?.isGiveUp &&
                  !data?.awayTeam?.isGiveUp && ( // doi nha bo cuoc
                    <p style={{lineHeight: `${2.5 * px}px`, fontSize: `${3.6 * px}px`, textAlign: 'center'}}>
                      0<span style={{margin: `0 ${px}px`}}>-</span>
                      {data?.awayTeam?.giveUpScore}
                    </p>
                  )}
                {!data?.homeTeam?.isGiveUp &&
                  data?.awayTeam?.isGiveUp && ( // doi khach bo cuoc
                    <p style={{lineHeight: `${2.5 * px}px`, fontSize: `${3.6 * px}px`, textAlign: 'center'}}>
                      {data?.homeTeam?.giveUpScore}
                      <span style={{margin: `0 ${px}px`}}>-</span>0
                    </p>
                  )}
                {data?.homeTeam?.isGiveUp &&
                  data?.awayTeam?.isGiveUp && ( // ca 2 doi cung bo cuoc
                    <p style={{lineHeight: `${2.5 * px}px`, fontSize: `${3.6 * px}px`, textAlign: 'center'}}>
                      <span style={{margin: `0 ${px}px`}}>-</span>
                    </p>
                  )}
              </div>
              {data?.hasPenalty && (
                <div style={{width: '100%', marginTop: `${0.6 * px}px`, color: '#fff'}}>
                  <p style={{textTransform: 'uppercase', textAlign: 'center', lineHeight: `${1.5 * px}px`, fontSize: `${1.4 * px}px`, width: '100%'}}>{`(${t('pk')}: ${data?.homeTeam?.penaltyScore} - ${data?.awayTeam?.penaltyScore})`}</p>
                </div>
              )}
            </div>
          ) : (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: `${1.6 * px}px`}}>
                <p style={{color: '#fff', textAlign: 'center', fontSize: `${1.8 * px}px`, lineHeight: `${1.6 * px}px`, minHeight: `${1.6 * px}px`, textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `${12 * px}px`, overflow: 'hidden'}}>{data?.stadium ? data?.stadium : ''}</p>
              </div>
              <div style={{color: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: `${1.5 * px}px`}}>
                <p style={{textTransform: 'uppercase', textAlign: 'center', lineHeight: `${1.5 * px}px`, fontSize: `${2 * px}px`, width: '100%'}}>{formatDateTime(data?.dateTime, 'time')}</p>
                <p style={{textTransform: 'uppercase', textAlign: 'center', fontSize: `${3 * px}px`, width: '100%'}}>{formatDateTime(data?.dateTime, 'date')}</p>
              </div>
            </div>
          )}
          <div style={{flex: 1.2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: `-${3.6 * px}px`}}>
            <Name propClass={`away${data?.awayTeam?.id}${new Date().getTime()}${classCustom}${idx}`} name={awayName} px={px} />
          </div>
        </div>
      </div>
    )
  },
  (p, n) => JSON.stringify(p.data) === JSON.stringify(n.data)
)

const Name = React.memo(
  ({name, px, propClass}) => {
    const reSizeBasedOnWidth = () => {
      let fontSize = window && window?.getComputedStyle(document.querySelector(`.${propClass}`))?.fontSize
      document.querySelector(`.${propClass}`).style.fontSize = parseFloat(fontSize) - 0.3 * px + 'px'
      if (document.querySelector(`.${propClass}`).clientHeight >= document.querySelector(`.contain${propClass}`).clientHeight) {
        reSizeBasedOnWidth()
      }
    }
    const processInput = () => {
      document.querySelector(`.${propClass}`).innerHTML = name
      document.querySelector(`.${propClass}`).style.fontSize = px * 3 + 'px' // Default font size
      reSizeBasedOnWidth()
    }

    React.useEffect(() => {
      processInput()
    }, [name])

    return (
      <div className={`contain${propClass}`} style={{flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: `${4.5 * px}px`, textTransform: 'uppercase'}}>
        <h2 className={propClass} style={{textAlign: 'center', width: '85%', color: '#fff', fontSize: `${3 * px}px`, textTransform: 'uppercase'}}></h2>
      </div>
    )
  },
  (p, n) => JSON.stringify(p.name) === JSON.stringify(n.name)
)
const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function getBase64(url) {
  return new Promise(resolve => {
    fetch(url, {cache: 'reload'})
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        }
        reader.readAsDataURL(blob)
      })
  })
}
function areEqual(p, n) {
  return JSON.stringify(p.isDisabledTime) === JSON.stringify(n.isDisabledTime) && JSON.stringify(p.dataMatch) === JSON.stringify(n.dataMatch) && JSON.stringify(p.dataMatchDetail) === JSON.stringify(n.dataMatchDetail)
}

export default React.memo(Mau2, areEqual)
