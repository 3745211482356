import React from 'react'
import {LuckyWheel} from 'react-luck-draw'
import {SketchPicker, SwatchesPicker} from 'react-color'
import {Box, Text, Flex, Input, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverCloseButton, Tabs, TabList, TabPanels, Tab, TabPanel} from '@chakra-ui/react'
import {DeleteIcon} from '@chakra-ui/icons'
const AdminManagement = props => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const myLucky = React.useRef()
  const [fireWork, setFireWork] = React.useState(false)
  const [itemWin, setItemWin] = React.useState({})
  const [turn, setTurn] = React.useState([])
  const [prizes, setPrizes] = React.useState([])
  const [state, setState] = React.useState({
    blocks: [{padding: '0px', background: '#d64737'}],
    prizes: [
      {
        title: 'Hieu123',
        background: `#3d3d3d`,
        fonts: [
          {text: '2 Hieu', top: '18%'},
          {text: '20 Hieu', top: '10%'}
        ]
      },
      {title: 'An', background: '#f8d384', fonts: [{text: '100 An', top: '18%'}]},
      {title: 'Thien', background: '#f9e3bb', fonts: [{text: 'Thien', top: '18%'}]},
      {title: 'Dieu', background: '#f8d384', fonts: [{text: 'Dieu Map', top: '18%'}]},
      {title: 'Vinh', background: '#f9e3bb', fonts: [{text: 'Vinh ', top: '18%'}]},
      {title: 'Phong', background: '#f8d384', fonts: [{text: 'Phong', top: '18%'}]}
    ],
    buttons: [
      {radius: '50px', background: '#f4a41b'},
      {radius: '45px', background: '#fff'},
      {radius: '41px', background: '#f6c66f', pointer: true},
      {
        radius: '35px',
        background: '#ffdea0',
        fonts: [{text: 'Quay', fontSize: '18px', top: -18}]
      }
    ],
    defaultStyle: {
      fontColor: '#000',
      fontSize: '15px'
    }
  })
  const handleChangeData = value => {
    const dataTemp = value.map(i => ({title: i.name, background: i.bg, fonts: [{text: i.name, top: '18%'}], amount: i.amount, id: i.id}))
    setPrizes(dataTemp)
  }
  return (
    <Draws onChange={handleChangeData} turn={turn}>
      <Button>Cài đặt</Button>
    </Draws>
  )
}

const Draws = ({children, onChange, turn}) => {
  const [data, setData] = React.useState([])
  const [state, setState] = React.useState({
    bg: '',
    name: '',
    amount: null
  })
  React.useEffect(() => {
    onChange && onChange(data)
  }, [data])
  const handleChange = ({target}) => {
    if (target.name === 'amount') {
      setState(s => ({...s, [target.name]: parseInt(target.value)}))
    } else {
      setState(s => ({...s, [target.name]: target.value}))
    }
  }
  const handleAdd = () => {
    const params = {
      id: new Date().getTime(),
      name: state.name,
      amount: state.amount,
      bg: state.bg
    }
    setData(s => [...s, params])
    setState(s => ({...s, name: '', amount: '', bg: ''}))
  }
  const handleDelete = id => () => {
    setData(s => s.filter(i => i.id !== id))
  }
  const handleChangeComplete = color => {
    setState(s => ({...s, bg: color.hex}))
  }
  return (
    <>
      <Tabs>
        <TabList>
          <Tab>Phần thưởng</Tab>
          <Tab>Danh sách đã quay</Tab>
          <Tab>Danh sách người tham gia</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box>
              <Flex>
                <Input name="name" value={state.name} onChange={handleChange} placeholder="Tên phần thưởng" width="40%" />
                <Input name="amount" value={state.amount} onChange={handleChange} placeholder="Số lượng" type={'number'} width="30%" ml={2} />
                <Popover>
                  <PopoverTrigger>
                    <Button backgroundColor={state.bg} _hover={{backgroundColor: state.bg}} ml={2} width="10%" />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverCloseButton />
                    <PopoverBody maxW={'max-content'}>
                      <SketchPicker color={state.bg} onChangeComplete={handleChangeComplete} />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
                <Button colorScheme="blue" ml={2} cursor="pointer" isDisabled={state.name === '' || state.amount === 0} width="20%" onClick={handleAdd}>
                  Thêm
                </Button>
              </Flex>
            </Box>
            <Box maxHeight={'50vh'} overflowY="scroll">
              {data?.map((i, idx) => (
                <Draw key={idx} data={i} idx={idx} onDelete={handleDelete(i.id)} />
              ))}
            </Box>
          </TabPanel>
          <TabPanel>
            <Box maxHeight={'50vh'} overflowY="scroll">
              {turn?.map((i, idx) => (
                <Text mt={2}>
                  {idx + 1}.{i.title}
                </Text>
              ))}
            </Box>
          </TabPanel>
          <TabPanel>
            <Box maxHeight={'50vh'} overflowY="scroll">
              {turn?.map((i, idx) => (
                <Text mt={2}>
                  {idx + 1}.{i.title}
                </Text>
              ))}
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}
const Draw = React.memo(
  ({data, idx, onDelete}) => {
    const deleteDraw = () => {
      onDelete && onDelete()
    }
    return (
      <Flex display={'flex'} alignItems="center" flexDirection={'row'} mt={2} py={1} backgroundColor={(idx + 1) % 2 === 0 ? '#D3D3D3' : 'unset'}>
        <Text width={'40%'} px={4}>
          {data?.name}
        </Text>
        <Text width={'30%'} px={4}>
          {data?.amount}
        </Text>
        <Button width={'10%'} backgroundColor={data.bg} _hover={{backgroundColor: data.bg}} />
        <Box width={'20%'} display="flex" flexDirection={'row'} justifyContent="center" alignItems={'center'}>
          <DeleteIcon color={'red'} onClick={deleteDraw} cursor="pointer" />
        </Box>
      </Flex>
    )
  },
  (p, n) => JSON.stringify(p.data) === JSON.stringify(n.data)
)

function areEqual(p, n) {
  return true
}

export default React.memo(AdminManagement, areEqual)
