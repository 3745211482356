export default {
  logo: 'https://hcm03.vstorage.vngcloud.vn/v1/AUTH_359c137a6cf647cdb070a4808cbeaf8d/vsports-files/vstation.jpg',
  background: 'https://hcm03.vstorage.vngcloud.vn/v1/AUTH_359c137a6cf647cdb070a4808cbeaf8d/vsports-files/cover.jpg',
  eventId: 'SuEG6sF1',
  goal: 'https://hcm03.vstorage.vngcloud.vn/v1/AUTH_359c137a6cf647cdb070a4808cbeaf8d/vsports-files/1659517848472.png',
  own_goal: 'https://hcm03.vstorage.vngcloud.vn/v1/AUTH_359c137a6cf647cdb070a4808cbeaf8d/vsports-files/1659517848322.png',
  yellow_card: 'https://hcm03.vstorage.vngcloud.vn/v1/AUTH_359c137a6cf647cdb070a4808cbeaf8d/vsports-files/1659517847822.png',
  red_card: 'https://hcm03.vstorage.vngcloud.vn/v1/AUTH_359c137a6cf647cdb070a4808cbeaf8d/vsports-files/1659517847658.png',
  change_player: 'https://hcm03.vstorage.vngcloud.vn/v1/AUTH_359c137a6cf647cdb070a4808cbeaf8d/vsports-files/1659517848186.png',
  yellow_red_card: 'https://hcm03.vstorage.vngcloud.vn/v1/AUTH_359c137a6cf647cdb070a4808cbeaf8d/vstationfiles/hRkGaUoDjO.png'
}
