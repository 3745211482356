import React from 'react'
import * as htmlToImage from 'html-to-image'
import {useParams} from 'react-router-dom'
import * as leaguesAPI from '../../api/leagues'
import * as Icon from '../../constants/icon'
import {Button} from '@chakra-ui/react'
import moment from 'moment'
import {sortMatchesAndRounds} from '../../constants/helpers'

/// vong 1-6
const TreeDiargram = () => {
  const ref = React.useRef()
  let {leagueId, seasonId} = useParams()
  const lang = window.sessionStorage.getItem('lang')
  const [px, setPx] = React.useState(0)
  const [heightAuto, setHeightAuto] = React.useState()
  const [data, setData] = React.useState([])
  const [seasonData, setSeasonData] = React.useState({})
  const [state, setState] = React.useState({
    loading: true,
    downloading: false
  })
  const type = seasonData?.settings?.type?.type
  React.useEffect(() => {
    leaguesAPI
      .getTreeDiargram(leagueId, seasonId, type)
      .then(r => {
        const rounds = r.data?.filter(i => i?.series != 'B')
        const sortedMatchesAndRounds = sortMatchesAndRounds([...rounds])
        // console.log('sortedMatchesAndRounds', rounds, sortedMatchesAndRounds)
        setData([...sortedMatchesAndRounds])
        setState(s => ({...s, loading: false}))
      })
      .catch(e => {
        setState(s => ({...s, loading: false}))
      })
  }, [type])
  React.useEffect(() => {
    setPx(ref.current?.offsetWidth / 100)
    setHeightAuto(ref.current?.offsetWidth / 1.5)
    leaguesAPI
      .getSeasonById(leagueId, seasonId)
      .then(r => {
        setSeasonData(r)
      })
      .catch(e => {})
  }, [leagueId, seasonId])

  const exportImage = async () => {
    if (state.downloading) return
    setState(s => ({...s, downloading: true}))
    htmlToImage
      .toJpeg(document.getElementById('treeDiargram'), {pixelRatio: 2, quality: 1})
      .then(dataUrl => {
        var link = document.createElement('a')
        const timeStamp = moment().format('yyyyMMDDHHmmss')
        const text = {
          vi: 'SoDoThiDau',
          en: 'TreeChart'
        }
        link.download = `${text[lang]}_${seasonData?.name}_${timeStamp}.jpeg`
        link.href = dataUrl
        link.click()
        setState(s => ({...s, downloading: false}))
      })
      .catch(function (error) {
        setState(s => ({...s, downloading: false}))
        console.error('oops, something went wrong!', error)
      })
  }
  let widthResponsive = window?.screen?.width > 920 ? '800px' : window?.screen?.width > 768 ? '600px' : '500px'
  let styleCss = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: widthResponsive,
    height: heightAuto,
    backgroundImage: 'url(/bgtrediargram.png)',
    backgroundSize: 'cover',
    color: '#fff',
    padding: '20px'
  }
  if (data.length < 1 && !state.loading) {
    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <div id={'treeDiargram'} ref={ref} style={styleCss}>
          <h1>Chưa có dữ liệu</h1>
        </div>
      </div>
    )
  }
  if (data.length < 1 && state.loading) {
    return (
      <div style={styleCss}>
        <div id={'treeDiargram'} ref={ref} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '800px', minWidth: '420px', height: heightAuto, backgroundImage: 'url(/bgtrediargram.png)', backgroundSize: 'cover', color: '#fff'}}>
          <h1>Đang tải dữ liệu</h1>
        </div>
      </div>
    )
  }
  const widthAvatar = 4 * px
  const styleAvatar = {width: widthAvatar, height: widthAvatar, borderRadius: '50%', border: '1px solid #fff'}
  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <div id={'treeDiargram'} ref={ref} style={styleCss}>
        <h1 style={{textTransform: 'uppercase', fontWeight: 'bold'}}>{seasonData?.name}</h1>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
          <div style={{display: 'flex', flex: 0.4, height: '100%', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <div style={{display: 'flex', flex: 0.6, flexDirection: 'column'}}>
              {data[0]?.dataGo
                // ?.sort((a, b) => a.nextGame - b.nextGame)
                ?.slice(0, 2)
                .map((i, idx) => {
                  return <Col2Item widthAvatar={widthAvatar} styleAvatar={styleAvatar} px={px} heightAuto={heightAuto} data={i} />
                })}
            </div>
            <div style={{display: 'flex', flex: 0.4}}>
              <Col3Item widthAvatar={widthAvatar} styleAvatar={styleAvatar} px={px} heightAuto={heightAuto} data={data[1].dataGo[0]} />
            </div>
          </div>
          <div style={{display: 'flex', flex: 0.2, height: '100%', flexDirection: 'column'}}>
            <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
              <Image style={{...styleAvatar, width: 2 * widthAvatar, height: 2 * widthAvatar}} src={seasonData?.logo} />
            </div>
            <div style={{display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center'}}>
              <div style={{display: 'flex', flex: 1, alignItems: 'center'}}>
                <div style={{height: '1px', width: widthAvatar / 2, backgroundColor: '#fff'}}></div>
                <Image style={styleAvatar} src={data[2].dataGo[0]?.homeTeam?.logo} />
              </div>
              <ColCenterItem data={data[2].dataGo[0]} px={px} />
              <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end'}}>
                <Image style={styleAvatar} src={data[2].dataGo[0]?.awayTeam?.logo} />
                <div style={{height: '1px', width: widthAvatar / 2, backgroundColor: '#fff'}}></div>
              </div>
            </div>
            <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}></div>
          </div>
          <div style={{display: 'flex', flex: 0.4, height: '100%'}}>
            <div style={{display: 'flex', flex: 0.4}}>
              <Col3ItemRight widthAvatar={widthAvatar} styleAvatar={styleAvatar} px={px} heightAuto={heightAuto} data={data[1].dataGo[1]} />
            </div>
            <div style={{display: 'flex', flex: 0.6, flexDirection: 'column'}}>
              {data[0]?.dataGo
                // ?.sort((a, b) => a.nextGame - b.nextGame)
                ?.slice(2, 4)
                .map((i, idx) => {
                  return <Col2ItemRight widthAvatar={widthAvatar} styleAvatar={styleAvatar} px={px} heightAuto={heightAuto} data={i} />
                })}
            </div>
          </div>
        </div>
      </div>
      <Button colorScheme="blue" mt={8} onClick={exportImage}>
        Tải xuống
      </Button>
    </div>
  )
}
const ColCenterItem = ({data, px}) => {
  if (data.isCompleted)
    return (
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%'}}>
          {data?.hasPenalty && data?.isCompleted && <p style={{fontSize: 1.4 * px, marginRight: 0.2 * px, textAlign: 'right'}}>{`(${data?.homeTeam?.penaltyScore})`}</p>}
          <p style={{fontSize: 1.5 * px, fontWeight: 500, marginRight: 0.2 * px, textAlign: 'right'}}>{data?.isCompleted ? data?.homeTeam?.score : ''}</p>
        </div>
        -
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%'}}>
          <p style={{fontSize: 1.5 * px, fontWeight: 500, marginRight: 0.2 * px, textAlign: 'right'}}>{data?.isCompleted ? data?.awayTeam?.score : ''}</p>
          {data?.hasPenalty && data?.isCompleted && <p style={{fontSize: 1.4 * px, marginRight: 0.2 * px, textAlign: 'left'}}>{`(${data?.awayTeam?.penaltyScore})`}</p>}
        </div>
      </div>
    )
  return <Icon.VS />
}
const Col2Item = ({widthAvatar, styleAvatar, heightAuto, data, px}) => {
  return (
    <div style={{display: 'flex', flex: 0.5, flexDirection: 'column', justifyContent: 'center'}}>
      <div style={{borderWidth: '0px 1px 0px 0px', borderColor: '#fff', borderStyle: 'solid', height: heightAuto / 4, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
          <div style={{flex: 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
            <p style={{fontSize: 1.4 * px, marginTop: -0.5 * widthAvatar, marginRight: 1 * px, textAlign: 'right'}}>{data?.homeTeam?.id ? data?.homeTeam?.name : 'Chưa xác định'}</p>
            <Image style={{...styleAvatar, marginTop: -0.5 * widthAvatar}} src={data?.homeTeam?.logo} />
          </div>
          <div style={{flex: 0.2, borderTop: '1px', borderColor: '#fff', borderStyle: 'solid'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%'}}>
              {data?.hasPenalty && data?.isCompleted && <p style={{fontSize: 1.4 * px, marginRight: 0.2 * px, textAlign: 'right'}}>{`(${data?.homeTeam?.penaltyScore})`}</p>}
              <p style={{fontSize: 1.5 * px, fontWeight: 500, marginRight: 0.2 * px, textAlign: 'right'}}>{data?.isCompleted ? data?.homeTeam?.score : ''}</p>
            </div>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
          <div style={{flex: 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
            <p style={{fontSize: 1.4 * px, marginBottom: -0.5 * widthAvatar, marginRight: 1 * px, textAlign: 'right'}}>{data?.awayTeam?.id ? data?.awayTeam?.name : 'Chưa xác định'}</p>
            <Image style={{...styleAvatar, marginBottom: -0.5 * widthAvatar}} src={data?.awayTeam?.logo} />
          </div>
          <div style={{flex: 0.2, borderBottom: '1px', borderColor: '#fff', borderStyle: 'solid'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%'}}>
              {data?.hasPenalty && data?.isCompleted && <p style={{fontSize: 1.4 * px, marginRight: 0.2 * px, textAlign: 'right'}}>{`(${data?.awayTeam?.penaltyScore})`}</p>}
              <p style={{fontSize: 1.5 * px, fontWeight: 500, marginRight: 0.2 * px, textAlign: 'right'}}>{data?.isCompleted ? data?.awayTeam?.score : ''}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const Col2ItemRight = ({widthAvatar, styleAvatar, heightAuto, data, px}) => {
  return (
    <div style={{display: 'flex', flex: 0.5, flexDirection: 'column', justifyContent: 'center'}}>
      <div style={{borderWidth: '0px 0px 0px 1px', borderColor: '#fff', borderStyle: 'solid', height: heightAuto / 4, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
          <div style={{flex: 0.2, borderTop: '1px', borderColor: '#fff', borderStyle: 'solid'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%'}}>
              <p style={{fontSize: 1.5 * px, fontWeight: 500, marginLeft: 0.2 * px, textAlign: 'right'}}>{data?.isCompleted ? data?.homeTeam?.score : ''}</p>
              {data?.hasPenalty && data?.isCompleted && <p style={{fontSize: 1.4 * px, marginLeft: 0.2 * px, textAlign: 'right'}}>{`(${data?.homeTeam?.penaltyScore})`}</p>}
            </div>
          </div>
          <div style={{flex: 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            <Image style={{...styleAvatar, marginTop: -0.5 * widthAvatar}} src={data?.homeTeam?.logo} />
            <p style={{fontSize: 1.4 * px, marginTop: -0.5 * widthAvatar, marginLeft: 1 * px, textAlign: 'right'}}>{data?.homeTeam?.id ? data?.homeTeam?.name : 'Chưa xác định'}</p>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
          <div style={{flex: 0.2, borderBottom: '1px', borderColor: '#fff', borderStyle: 'solid'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%'}}>
              <p style={{fontSize: 1.5 * px, fontWeight: 500, marginLeft: 0.2 * px, textAlign: 'right'}}>{data?.isCompleted ? data?.awayTeam?.score : ''}</p>
              {data?.hasPenalty && data?.isCompleted && <p style={{fontSize: 1.4 * px, marginLeft: 0.2 * px, textAlign: 'right'}}>{`(${data?.awayTeam?.penaltyScore})`}</p>}
            </div>
          </div>
          <div style={{flex: 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            <Image style={{...styleAvatar, marginBottom: -0.5 * widthAvatar}} src={data?.awayTeam?.logo} />
            <p style={{fontSize: 1.4 * px, marginBottom: -0.5 * widthAvatar, marginLeft: 1 * px, textAlign: 'right'}}>{data?.awayTeam?.id ? data?.awayTeam?.name : 'Chưa xác định'}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
const Col3Item = ({widthAvatar, styleAvatar, heightAuto, data, px}) => {
  return (
    <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center'}}>
      <div style={{borderWidth: '1px 1px 1px 0px', borderColor: '#fff', borderStyle: 'solid', height: heightAuto / 2, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
          <div style={{flex: 0.25}}></div>
          <div style={{flex: 0.5, display: 'flex', justifyContent: 'center'}}>
            <Image style={{...styleAvatar, marginTop: -0.5 * widthAvatar}} src={data?.homeTeam?.logo} />
          </div>
          <div style={{flex: 0.25}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%'}}>
              {data?.hasPenalty && data?.isCompleted && <p style={{fontSize: 1.4 * px, marginRight: 0.2 * px, textAlign: 'right'}}>{`(${data?.homeTeam?.penaltyScore})`}</p>}
              <p style={{fontSize: 1.5 * px, fontWeight: 500, marginRight: 0.2 * px, textAlign: 'right'}}>{data?.isCompleted ? data?.homeTeam?.score : ''}</p>
            </div>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
          <div style={{flex: 0.25}}></div>
          <div style={{flex: 0.5, display: 'flex', justifyContent: 'center'}}>
            <Image style={{...styleAvatar, marginBottom: -0.5 * widthAvatar}} src={data?.awayTeam?.logo} />
          </div>
          <div style={{flex: 0.25}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%'}}>
              {data?.hasPenalty && data?.isCompleted && <p style={{fontSize: 1.4 * px, marginRight: 0.2 * px, textAlign: 'right'}}>{`(${data?.awayTeam?.penaltyScore})`}</p>}
              <p style={{fontSize: 1.5 * px, fontWeight: 500, marginRight: 0.2 * px, textAlign: 'right'}}>{data?.isCompleted ? data?.awayTeam?.score : ''}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const Col3ItemRight = ({widthAvatar, styleAvatar, heightAuto, data, px}) => {
  return (
    <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center'}}>
      <div style={{borderWidth: '1px 0 1px 1px', borderColor: '#fff', borderStyle: 'solid', height: heightAuto / 2, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
          <div style={{flex: 0.25}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%'}}>
              <p style={{fontSize: 1.5 * px, fontWeight: 500, marginLeft: 0.2 * px, textAlign: 'right'}}>{data?.isCompleted ? data?.homeTeam?.score : ''}</p>
              {data?.hasPenalty && data?.isCompleted && <p style={{fontSize: 1.4 * px, marginLeft: 0.2 * px, textAlign: 'right'}}>{`(${data?.homeTeam?.penaltyScore})`}</p>}
            </div>
          </div>
          <div style={{flex: 0.5, display: 'flex', justifyContent: 'center'}}>
            <Image style={{...styleAvatar, marginTop: -0.5 * widthAvatar}} src={data?.homeTeam?.logo} />
          </div>
          <div style={{flex: 0.25}}></div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
          <div style={{flex: 0.25}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%'}}>
              <p style={{fontSize: 1.5 * px, fontWeight: 500, marginLeft: 0.2 * px, textAlign: 'right'}}>{data?.isCompleted ? data?.awayTeam?.score : ''}</p>
              {data?.hasPenalty && data?.isCompleted && <p style={{fontSize: 1.4 * px, marginLeft: 0.2 * px, textAlign: 'right'}}>{`(${data?.awayTeam?.penaltyScore})`}</p>}
            </div>
          </div>
          <div style={{flex: 0.5, display: 'flex', justifyContent: 'center'}}>
            <Image style={{...styleAvatar, marginBottom: -0.5 * widthAvatar}} src={data?.awayTeam?.logo} />
          </div>
          <div style={{flex: 0.25}}></div>
        </div>
      </div>
    </div>
  )
}

const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function getBase64(url) {
  return new Promise(resolve => {
    fetch(url, {cache: 'reload'})
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        }
        reader.readAsDataURL(blob)
      })
  })
}
function areEqual(p, n) {
  return true
}

export default React.memo(TreeDiargram, areEqual)
