import React, {Children} from 'react'
import moment from 'moment'
import img from '../../../public/static/image-background/logo-v-02.png'
import config from '../../../constants/config'
import {useTranslation, formatDateTime} from '../../../constants/helpers'

const Mau2 = ({isDisabledTime, dataMatch, dataMatchDetail, size, id}) => {
  const {t} = useTranslation()
  const width = size
  const px = size / 100
  const color = {
    first: '#fff',
    second: '#cb2441',
    third: '#ffde01',
    fourth: '#ec1e27',
    fifth: '#000'
  }
  const [matchDetail, setMatchDetail] = React.useState(dataMatchDetail)
  React.useEffect(() => {
    setMatchDetail(dataMatchDetail)
  }, [])
  let matchDetailHome = dataMatchDetail
    .filter(i => (i?.team?.id == dataMatch?.homeTeam?.id && i?.type != 'own_goal') || (i?.team?.id == dataMatch?.awayTeam?.id && i?.type === 'own_goal'))
    ?.sort(function (a, b) {
      return a.time - b.time
    })
  let matchDetailAway = dataMatchDetail
    .filter(i => (i?.team?.id == dataMatch?.awayTeam?.id && i?.type != 'own_goal') || (i?.team?.id == dataMatch?.homeTeam?.id && i?.type === 'own_goal'))
    ?.sort(function (a, b) {
      return a.time - b.time
    })

  const arrName = {quarterfinals: 'TỨ KẾT', semifinal: 'BÁN KẾT', '3rdPlace': 'TRANH HẠNG 3', final: 'CHUNG KẾT'}
  const classCss = {
    fontSizeHome: size < 1000 ? 'font-size-home1' : 'font-size-home2',
    fontSizeAway: size < 1000 ? 'font-size-away1' : 'font-size-away2',
    conTainerHome: size < 1000 ? 'containerHome1' : 'containerHome2',
    conTainerAway: size < 1000 ? 'containerAway1' : 'containerAway2'
  }
  const reSizeBasedOnWidth = (children, parent) => {
    let fontSize = window && window?.getComputedStyle(document.querySelector(children))?.fontSize
    document.querySelector(children).style.fontSize = parseFloat(fontSize) - 3 + 'px'
    if (document.querySelector(children).clientHeight >= document.querySelector(parent).clientHeight) {
      reSizeBasedOnWidth(children, parent)
    }
  }

  const processInput = (children, parent) => {
    document.querySelector(children).innerHTML = children == `.${classCss.fontSizeHome}` ? dataMatch?.homeTeam?.name : dataMatch?.awayTeam?.name
    document.querySelector(children).style.fontSize = px * 3 + 'px' // Default font size
    reSizeBasedOnWidth(children, parent)
  }

  React.useEffect(() => {
    if (dataMatch.homeTeam?.name) {
      processInput(`.${classCss.fontSizeHome}`, `.${classCss.conTainerHome}`)
    }
    if (dataMatch.awayTeam?.name) {
      processInput(`.${classCss.fontSizeAway}`, `.${classCss.conTainerAway}`)
    }
  }, [dataMatch])

  return (
    <div id={id} className="bg-match-mau2" style={{width: width, minHeight: width, padding: `${1.6 * px}px`, fontFamily: 'SFUHelveticaCondensedBold'}}>
      <div style={{width: `100%`, minHeight: `calc(${width}px - ${3.2 * px}px)`, padding: `${px * 1.2}px ${px * 1.2}px ${px * 3}px ${px * 1.2}px`, position: 'relative'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{flex: 1}}>
            <Image src={dataMatch?.season?.logo} style={{width: `${px * 6}px`, height: 'max-content'}} />
          </div>
          <div style={{flex: 6}}>
            <h1 style={{textAlign: 'center', color: color.first, fontSize: `${2.8 * px}px`, textTransform: 'uppercase'}}>{dataMatch?.season?.name}</h1>
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <Image src={img} style={{width: `${px * 6}px`, height: 'max-content'}} />
          </div>
        </div>
        <h1 style={{fontFamily: 'SFUHelveticaCondensedBlack', textAlign: 'center', color: color.third, fontSize: `${6 * px}px`, textTransform: 'uppercase', marginTop: `${px}px`}}>{t('match_result')}</h1>
        <div style={{width: '100%', marginBottom: `${2 * px}px`}}>
          <h2 style={{fontFamily: 'SFUHelveticaCondensedBlack', textTransform: 'uppercase', margin: '0 auto', width: 'max-content', color: color.fifth, backgroundColor: color.first, padding: `${0.5 * px}px ${7 * px}px`, marginTop: `${px}px`, fontSize: `${2.6 * px}px`}}>
            {t('round')} {t(dataMatch?.numberRound) || dataMatch?.numberRound}
          </h2>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: `${8 * px}px`, padding: `${1 * px}px 0px`, width: `${80 * px}px`, margin: `0 auto`}}>
          <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Image src={dataMatch?.homeTeam?.logo} style={{width: `${12 * px}px`, height: `${12 * px}px`, borderRadius: '50%', border: `${0.4 * px}px solid ${color.fourth}`, objectFit: 'contain'}} />
            </div>
          </div>
          <div style={{flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            {!dataMatch?.homeTeam?.isGiveUp &&
              !dataMatch?.awayTeam?.isGiveUp && ( // ko doi nao bo cuoc
                <div style={{color: color.second, fontSize: `${6 * px}px`, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{width: `${12 * px}px`, height: `${10 * px}px`, border: `${0.7 * px}px solid ${color.second}`, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <p>{dataMatch?.homeTeam?.score}</p>
                  </div>
                  <div style={{margin: `0px ${2 * px}px`}}>
                    <p style={{width: `${2 * px}px`, height: `${px}px`, backgroundColor: color.second}} />
                  </div>
                  <div style={{width: `${12 * px}px`, height: `${10 * px}px`, border: `${0.7 * px}px solid ${color.second}`, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <p>{dataMatch?.awayTeam?.score}</p>
                  </div>
                </div>
              )}
            {dataMatch?.homeTeam?.isGiveUp &&
              !dataMatch?.awayTeam?.isGiveUp && ( // doi nha bo cuoc
                <div style={{color: color.second, fontSize: `${6 * px}px`, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{width: `${12 * px}px`, height: `${10 * px}px`, border: `${0.7 * px}px solid ${color.second}`, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <p>0</p>
                  </div>
                  <div style={{margin: `0px ${2 * px}px`}}>
                    <p style={{width: `${2 * px}px`, height: `${px}px`, backgroundColor: color.second}} />
                  </div>
                  <div style={{width: `${12 * px}px`, height: `${10 * px}px`, border: `${0.7 * px}px solid ${color.second}`, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <p>{dataMatch?.awayTeam?.giveUpScore}</p>
                  </div>
                </div>
              )}
            {!dataMatch?.homeTeam?.isGiveUp &&
              dataMatch?.awayTeam?.isGiveUp && ( // doi khach bo cuoc
                <div style={{color: color.second, fontSize: `${6 * px}px`, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{width: `${12 * px}px`, height: `${10 * px}px`, border: `${0.7 * px}px solid ${color.second}`, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <p>{dataMatch?.homeTeam?.giveUpScore}</p>
                  </div>
                  <div style={{margin: `0px ${2 * px}px`}}>
                    <p style={{width: `${2 * px}px`, height: `${px}px`, backgroundColor: color.second}} />
                  </div>
                  <div style={{width: `${12 * px}px`, height: `${10 * px}px`, border: `${0.7 * px}px solid ${color.second}`, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <p>0</p>
                  </div>
                </div>
              )}
            {dataMatch?.homeTeam?.isGiveUp &&
              dataMatch?.awayTeam?.isGiveUp && ( // ca 2 doi cung bo cuoc
                <div style={{color: color.second, fontSize: `${6 * px}px`, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{margin: `0px ${2 * px}px`}}>
                    <p style={{width: `${2 * px}px`, height: `${px}px`, backgroundColor: color.second}} />
                  </div>
                </div>
              )}
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Image src={dataMatch?.awayTeam?.logo} style={{width: `${12 * px}px`, height: `${12 * px}px`, borderRadius: '50%', border: `${0.4 * px}px solid ${color.fourth}`, objectFit: 'contain'}} />
            </div>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
          <div style={{display: 'flex', flexDirection: 'column', backgroundColor: '#fff', width: `${68 * px}px`, paddingBottom: `${px}px`}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <div className={classCss.conTainerHome} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, textAlign: 'center', textTransform: 'uppercase', padding: `${px}px 0px`, borderRadius: `0 ${4 * px}px ${4 * px}px 0`, color: color.first, backgroundColor: color.second, height: `${7 * px}px`}}>
                <div className={classCss.fontSizeHome}></div>
              </div>
              <div style={{flex: 0.1}} />
              <div className={classCss.conTainerAway} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, textAlign: 'center', textTransform: 'uppercase', padding: `${px}px 0px`, borderRadius: `${4 * px}px 0 0 ${4 * px}px`, color: color.first, backgroundColor: color.fifth, height: `${7 * px}px`}}>
                <div className={classCss.fontSizeAway} />
              </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: `${px}px`}}>
              <div style={{paddingLeft: `${px}px`, flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 'max-content', alignItems: 'center'}}>{dataMatch?.homeTeam?.isGiveUp && <p style={{color: '#fff', backgroundColor: 'red', fontSize: `${1.5 * px}px`, padding: `0 ${1.5 * px}px`, borderRadius: `${px}px`, maxWidth: 'max-content'}}>Xử thua</p>}</div>
              <div style={{flex: 1}}>
                {dataMatch?.hasPenalty && ( // co da penalty
                  <div>
                    <p style={{textTransform: 'uppercase', textAlign: 'center'}}>{`(${t('pk')}: ${dataMatch?.homeTeam?.penaltyScore} - ${dataMatch?.awayTeam?.penaltyScore})`}</p>
                  </div>
                )}
              </div>
              <div style={{paddingRight: `${px}px`, flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', height: 'max-content', alignItems: 'center'}}>{dataMatch?.awayTeam?.isGiveUp && <p style={{color: '#fff', backgroundColor: 'red', fontSize: `${1.5 * px}px`, padding: `0 ${1.5 * px}px`, borderRadius: `${px}px`, maxWidth: 'max-content'}}>Xử thua</p>}</div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div style={{flex: 1, paddingRight: `${5 * px}px`}}>
                {matchDetailHome?.map((i, idx) => (
                  <Item key={`${idx}-${new Date()}`} dataItem={i} typeTeam="home" px={px} matchDetail={matchDetailHome} isDisabledTime={isDisabledTime} />
                ))}
              </div>
              <div style={{flex: 1, paddingLeft: `${5 * px}px`}}>
                {matchDetailAway?.map((i, idx) => (
                  <Item key={`${idx}-${new Date()}`} dataItem={i} typeTeam="away" px={px} matchDetail={matchDetailAway} isDisabledTime={isDisabledTime} />
                ))}
              </div>
            </div>
          </div>
        </div>

        <p style={{textAlign: 'center', color: color.first, fontSize: `${2 * px}px`, position: 'absolute', bottom: `0px`, width: '100%'}}>VSPORTS.VN</p>
      </div>
    </div>
  )
}
const Item = ({dataItem, typeTeam, px, matchDetail, isDisabledTime}) => {
  let img = ''
  let h = ''
  let w = ''
  switch (dataItem.type) {
    case 'goal':
      img = config.goal
      h = '15px'
      w = '15px'
      break
    case 'own_goal':
      img = config.own_goal
      h = '15px'
      w = '15px'
      break
    case 'yellow_card':
      img = config.yellow_card
      h = '15px'
      w = '11px'
      break
    case 'red_card':
      img = config.red_card
      h = '15px'
      w = '11px'
      break
    case 'change_player':
      img = config.change_player
      h = '15px'
      w = '15px'
      break

    default:
      img = config.yellow_red_card
      h = '15px'
      w = '15px'
  }
  const yellow_card_by_id = matchDetail
    ?.sort(function (a, b) {
      return a.time - b.time
    })
    ?.filter(i => i?.player?.id === dataItem?.player?.id && i?.type === 'yellow_card')
  if (typeTeam === 'home') {
    return (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: `${px}px`}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: `${px}px`, fontSize: `${2 * px}px`}}>
          {dataItem?.type === 'own_goal' && <p>{`(OG)`}</p>}
          <p style={{marginRight: `${px}px`}}>{dataItem?.player?.name}</p>
          <p>{dataItem?.player?.shirtNumber !== null && dataItem?.player?.shirtNumber !== undefined && !dataItem?.player?.shirtNumber?.trim?.() ? `(${dataItem?.player?.shirtNumber})` : ''}</p>
        </div>
        {!isDisabledTime && (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: `${px}px`, fontSize: `${2 * px}px`}}>
            <p>{Math.floor(dataItem?.time / 60)}'</p>
          </div>
        )}
        {yellow_card_by_id[yellow_card_by_id.length - 1]?.id === dataItem?.id && yellow_card_by_id.length > 1 ? (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: `${2 * px}px`, justifyContent: 'center'}}>
            <Image src={config.yellow_red_card} style={{width: `${2 * px}px`}} />
          </div>
        ) : (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: `${2 * px}px`, justifyContent: 'center'}}>
            <Image src={img} style={{width: `${2 * px}px`}} />
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: `${px}px`}}>
        {yellow_card_by_id[yellow_card_by_id.length - 1]?.id === dataItem?.id && yellow_card_by_id.length > 1 ? (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: `${2 * px}px`, justifyContent: 'center'}}>
            <Image src={config.yellow_red_card} style={{width: `${2 * px}px`}} />
          </div>
        ) : (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: `${2 * px}px`, justifyContent: 'center'}}>
            <Image src={img} style={{width: `${2 * px}px`}} />
          </div>
        )}
        {!isDisabledTime && (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: `${px}px`, fontSize: `${2 * px}px`}}>
            <p>{Math.floor(dataItem?.time / 60)}'</p>
          </div>
        )}
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: `${px}px`, fontSize: `${2 * px}px`}}>
          <p>{dataItem?.player?.shirtNumber !== null && dataItem?.player?.shirtNumber !== undefined && !dataItem?.player?.shirtNumber?.trim?.() ? `(${dataItem?.player?.shirtNumber})` : ''}</p>
          <p style={{marginLeft: `${px}px`}}>{dataItem?.player?.name}</p>
          {dataItem?.type === 'own_goal' && <p>{`(OG)`}</p>}
        </div>
      </div>
    )
  }
}
const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function getBase64(url) {
  return new Promise(resolve => {
    fetch(url, {cache: 'reload'})
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        }
        reader.readAsDataURL(blob)
      })
  })
}
function areEqual(p, n) {
  return JSON.stringify(p.isDisabledTime) === JSON.stringify(n.isDisabledTime) && JSON.stringify(p.dataMatch) === JSON.stringify(n.dataMatch) && JSON.stringify(p.dataMatchDetail) === JSON.stringify(n.dataMatchDetail)
}

export default React.memo(Mau2, areEqual)
