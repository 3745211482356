import {Text} from '@chakra-ui/react'
import moment from 'moment'
import React, {useState} from 'react'
import bg from '../../../public/static/image-background/LOGO_02.png'
import config from '../../../constants/config'
import {getBase64, formatDateTime, useTranslation} from '../../../constants/helpers'

const PlayersList7 = ({id, players, setIsReady, league, size, isShowBirthDay, seasonData, teamData, checkboxPlayer, checkboxStaff, sponsorData, isShowSponsor}) => {
  const {t} = useTranslation()
  const width = size
  const height = width / 1.5
  const [listUser, setListUser] = React.useState([])
  const px = width / 100
  const sizeItemLogo = px * 15
  const playersWrapperWidth = 90 * px
  const listPlayer = players?.reduce(
    (p, c) => {
      if (!p[c.types]) p[c.types] = []
      if (c.types?.length > 1) {
        p['football_player_type'].push(c)
        p['football_staff_type'].push(c)
        return p
      }
      p[c.types].push(c)
      return p
    },
    {football_player_type: [], football_staff_type: []}
  )
  const arrPriority = ['football_team_manager', 'football_coach', 'football_coach_assistant', 'football_medical_staff', 'football_marketing', 'football_other_staff']
  const maxSortIndex = arrPriority?.length
  const _staffs = listPlayer?.football_staff_type
    ?.map(item => {
      const positions = item?.positions
        ?.map(i => {
          const sortIndex = arrPriority.findIndex(p => p == i)
          return {value: i, sortIndex: sortIndex == -1 ? maxSortIndex : sortIndex}
        })
        .sort((a, b) => a.sortIndex - b.sortIndex)
        ?.map(p => p.value)
      const sortIndex = arrPriority?.findIndex(p => p == positions[0])
      return {...item, positions, sortIndex: sortIndex == -1 ? maxSortIndex : sortIndex}
    })
    .sort((a, b) => a.sortIndex - b.sortIndex)

  const reSizeBasedOnWidth = () => {
    let fontSize = window && window?.getComputedStyle(document.querySelector('.text-2-line-custom'))?.fontSize
    document.querySelector('.text-2-line-custom').style.fontSize = parseFloat(fontSize) - 0.5 + 'px'
    if (document.querySelector('.text-2-line-custom').clientHeight >= document.querySelector('.container').clientHeight) {
      reSizeBasedOnWidth()
    }
  }

  const processInput = () => {
    document.querySelector('.text-2-line-custom').innerHTML = seasonData?.name
    document.querySelector('.text-2-line-custom').style.fontSize = px * 20 + 'pt' // Default font size
    reSizeBasedOnWidth()
  }

  const onLoaded = value => {
    setListUser(s => [...s, value])
  }

  React.useEffect(() => {
    if (listUser?.length === listPlayer?.football_staff_type?.length + listPlayer.football_player_type?.length) {
      setIsReady?.(true)
    }
  }, [listUser])

  React.useEffect(() => {
    if (seasonData.name) {
      processInput()
    }
  }, [seasonData])

  const staffPosition = {football_coach: t('football_coach'), football_coach_assistant: t('football_coach_assistant'), football_marketing: t('football_marketing'), football_medical_staff: t('football_medical_staff'), football_other_staff: t('football_other_staff'), football_team_manager: t('football_team_manager')}
  return (
    <div className="container-custom" style={{backgroundColor: '#FAF7B0', width: width / 1.29, paddingBottom: isShowSponsor && sponsorData?.length > 0 ? px * 9 : px * 2, position: 'relative'}}>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative'}}>
        <div id="outer" className="container" style={{textTransform: 'uppercase', width: '100%', paddingRight: '200px', marginTop: '4px', paddingLeft: '200px', height: '55px', fontSize: '20px'}}>
          <div className="text-2-line-custom" style={{textAlign: 'center', wordWrap: 'break-word', fontFamily: 'SFUHelveticaCondensedBold', fontSize: '50pt', color: styleConfig.colorText}}></div>
        </div>
        <div style={{position: 'absolute', display: 'flex', right: 10, top: teamData.name?.length > 90 ? 11 : 12}}>
          <Image src={seasonData?.logo} style={{width: sizeItemLogo / 2.5, marginRight: 15}} />
          <Image src={bg} style={{width: sizeItemLogo / 2.5}} />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 10, top: teamData.name?.length > 90 ? 8 : 10}}>
          <Image src={teamData.logo} style={{width: sizeItemLogo / 3}} />
          <h3 style={{...styleConfig.fontStyleTextNormal, fontSize: teamData.name?.length > 24 ? '8pt' : '12pt'}}>CLB: {teamData.name}</h3>
        </div>
        <h3 style={{...styleConfig.fontStyleTextNormal, fontSize: '20pt', textAlign: 'center', color: '#f48220', paddingTop: '0px', textTransform: 'uppercase'}} className="text-2-line-custom">
          {t('list_team_member')}
        </h3>
      </div>
      {listPlayer?.football_staff_type?.length > 0 && checkboxStaff && (
        <div style={{marginTop: `${(width - 5 * px) / 100}px`}}>
          <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            {_staffs?.map((i, idx) => {
              const itempx = (width - 10 * px) / 100
              return (
                <div
                  key={`${idx}+${i.id}`}
                  style={{
                    display: 'flex',
                    width: `${((21.5 * itempx) / 50) * 40}px`,
                    marginBottom: `${((1 * itempx) / 50) * 40}px`,
                    marginTop: idx > 4 ? `${((1 * itempx) / 50) * 40}px` : `${((5 * itempx) / 50) * 40}px`,
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}>
                  <div style={{display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Image onload={onLoaded} src={i.avatar || config.logo} style={{width: `${((23 * itempx) / 100) * 35}px`, height: `${((23 * itempx) / 100) * 35 * 1.2}px`, border: '4px solid #f48220', backgroundColor: 'white', padding: `${((5 * itempx) / 1200) * 40}px`, objectFit: 'cover'}} />
                    <h3 style={{marginTop: '5px', ...styleConfig.fontStyleTextNormal, fontSize: '11pt', textTransform: 'uppercase'}}>{staffPosition[i.positions[0]] || i?.positions[0]}</h3>
                    <h3 style={{...styleConfig.fontStyleTextNormal, paddingLeft: '2px', paddingRight: '2px', fontSize: i.name.length > 20 ? `calc(${width / (i.name.length * 5.6)}pt) ` : '10pt', width: '100%', textAlign: 'center', textTransform: 'uppercase'}}>{i.name}</h3>
                    {isShowBirthDay && <h3 style={{...styleConfig.fontStyleTextNormal, fontSize: '10pt', textAlign: 'center', textTransform: 'uppercase'}}>{formatDateTime(i.birthday, 'date')}</h3>}{' '}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
      {listPlayer?.football_player_type?.length > 0 && checkboxPlayer && (
        <div style={{width: '100%'}}>
          <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            {listPlayer?.football_player_type?.map((i, idx) => {
              const itempx = (width - 10 * px) / 100
              return (
                <div
                  key={`${idx}+${i.id}`}
                  style={{
                    display: 'flex',
                    width: `${((19.5 * itempx) / 50) * 40}px`,
                    marginTop: (listPlayer?.football_staff_type?.length < 1 || !listPlayer?.football_staff_type) && idx < 5 ? `${((4 * itempx) / 50) * 100}px` : `${((1 * itempx) / 50) * 40}px`,
                    marginBottom: `${((1 * itempx) / 50) * 40}px`,
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}>
                  <div style={{display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
                    <Image onload={onLoaded} src={i.avatar || config.logo} style={{width: `${((23 * itempx) / 100) * 35}px`, height: `${((23 * itempx) / 100) * 35 * 1.2}px`, border: '4px solid #325ea8', backgroundColor: 'white', padding: `${((5 * itempx) / 1200) * 40}px`, objectFit: 'cover'}} />
                    <div style={{height: `${((12 * itempx) / 100) * 40}px`, width: `${((18.5 * itempx) / 50) * 40}px`}}>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <h3 style={{...styleConfig.fontStyleTextNormal, paddingRight: '2px', fontSize: i.name?.length > 20 ? `calc(${width / (i.name?.length * 5.6)}pt) ` : '10pt', width: '100%', textAlign: 'center', textTransform: 'uppercase'}}>
                          {i.shirtNumber ? `${i.shirtNumber}.` : ''}
                          {i.name}
                        </h3>
                      </div>

                      {isShowBirthDay && <h3 style={{...styleConfig.fontStyleTextNormal, fontSize: '10pt', textAlign: 'center', textTransform: 'uppercase'}}>{formatDateTime(i.birthday, 'date')}</h3>}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
      {isShowSponsor && sponsorData?.length > 0 && (
        <div style={{height: `${8 * px}px`, width: width / 1.29, backgroundImage: `url(/BG_DSPLAYER.png)`, position: 'absolute', bottom: px * 0.8, padding: `${0.8 * px}px 0px ${0.8 * px}px 0px`, borderRadius: '50px'}}>
          <div style={{width: '95%', height: '100%', backgroundColor: '#fff', margin: '0 auto', borderRadius: `${5 * px}px`, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            {sponsorData?.length > 0 && sponsorData?.slice(0, 10)?.map((i, idx) => <Image key={idx} src={i.logo || config.logo} style={{width: `auto`, maxHeight: `${px * 6}px`, backgroundColor: 'white', objectFit: 'cover', marginRight: `${2 * px}px`}} />)}
          </div>
        </div>
      )}
    </div>
  )
}

const styleConfig = {
  fontStyleTextNormal: {fontFamily: 'SFUHelveticaCondensedBold', color: '#1d4380', fontSize: '8pt'},
  fontStyleNameOfTable: {fontFamily: 'SFUHelveticaCondensedBold', fontSize: '10pt', textAlign: 'center'},
  paddingTable: {padding: '8px 8px 8px 8x'},
  colorHeader: '#325ea8',
  colorText: '#1d4380',
  colorBodyTable: '#ffe5c2',
  colorTextName: '#f48220',
  colorTextHeader: '#fff'
}

const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    if (props?.src?.includes('ui-avatars')) {
      getBase64(config.logo).then(r => {
        setData(r)
      })
    } else {
      getBase64(props.src).then(r => {
        setData(r)
      })
    }
  }, [props.src])
  return data ? <img {...props} src={data} onLoad={props.onload} alt="" /> : null
}

const areEqual = (p, n) => {
  return (
    JSON.stringify(p.players) === JSON.stringify(n.players) &&
    JSON.stringify(p.checkboxPlayer) === JSON.stringify(n.checkboxPlayer) &&
    JSON.stringify(p.checkboxStaff) === JSON.stringify(n.checkboxStaff) &&
    JSON.stringify(p.isShowBirthDay) === JSON.stringify(n.isShowBirthDay) &&
    JSON.stringify(p.checkboxPlayer) === JSON.stringify(n.checkboxPlayer) &&
    JSON.stringify(p.checkboxStaff) === JSON.stringify(n.checkboxStaff) &&
    JSON.stringify(p.isShowSponsor) === JSON.stringify(n.isShowSponsor)
  )
}

export default React.memo(PlayersList7, areEqual)
