import React from 'react'
import moment from 'moment'
import * as htmlToImage from 'html-to-image'
import ReactPaginate from 'react-paginate'
import {Button, Box, Text, Flex, Checkbox} from '@chakra-ui/react'
import {useParams, useLocation} from 'react-router-dom'
import {MdArrowBackIos} from 'react-icons/md'
import Mau1 from './components/mau1'
import Mau2 from './components/mau2'
import Mau3 from './components/mau3'
import Mau4 from './components/mau4'

import {MdUpdate} from 'react-icons/md'
import imgchoose1 from '../../../../public/static/image-background/bgmau1schedules.jpeg'
import imgchoose2 from '../../../../public/static/image-background/c1bgmau2exportlich.jpeg'
import imgchoose3 from '../../../../public/static/image-background/c2bgmau1exportlich.jpeg'
import imgchoose4 from '../../../../public/static/image-background/c2bgmau2exportlich.jpeg'
import locales from '../../../../public/static/locales/vi.json'

import * as leaguesAPI from '../../../../api_v2.1/leagues'
import {BrowserRouter, Routes, Route, useSearchParams} from 'react-router-dom'
const t = locales
const ExportSchedules = () => {
  let {leagueId, seasonId} = useParams()
  const [searchParams] = useSearchParams()
  const numberRound = searchParams.get('numberRound')
  // const {isOpen, onOpen, onClose} = useDisclosure()
  const [type, setType] = React.useState(null)
  const [state, setState] = React.useState({
    downloading: false,
    loading: true,
    loadingTeam: true,
    dataSchedules: [],
    dataSeason: {},
    roundName: ''
  })
  const [show, setShow] = React.useState(false)
  const [selected, setSelected] = React.useState(0)

  const mau1 = React.useRef(new Date().toISOString() + 'match')
  const mau2 = React.useRef(new Date().toISOString() + 'match')
  const mau3 = React.useRef(new Date().toISOString() + 'match')
  const mau4 = React.useRef(new Date().toISOString() + 'match')

  const idMau1 = mau1.current
  const idMau2 = mau2.current
  const idMau3 = mau3.current
  const idMau4 = mau4.current

  const select = value => () => {
    setSelected(value)
    setShow(true)
  }
  const fetchData = React.useCallback(() => {
    leaguesAPI
      .getSeasonByIdv2(leagueId, seasonId)
      .then(res => {
        setState(s => ({...s, dataSeason: res}))
      })
      .catch(e => {
        console.log('error', e)
      })
    leaguesAPI
      .getSchedulesBasketball({leagueId: leagueId, seasonId: seasonId, matchStatus: 'upcoming'})
      .then(res => {
        const itemFilter = res.find(i => i.id === numberRound)
        setState(s => ({...s, dataSchedules: itemFilter?.matches || [], roundName: itemFilter?.type == 'group_stage' ? `${t[`${itemFilter.type}`]} ${itemFilter?.order}` : `${t[`${itemFilter.type}`]}`}))
      })
      .catch(e => {
        console.log('error', e)
      })
  }, [leagueId, numberRound])
  React.useEffect(() => {
    fetchData()
  }, [fetchData])

  React.useEffect(() => {
    switch (selected) {
      case 1:
        setType(mau1.current)
        break
      case 2:
        setType(mau2.current)
        break
      case 3:
        setType(mau3.current)
        break
      default:
        break
    }
  }, [selected])

  const css = {
    cursor: 'pointer',
    border: '2px solid #fff',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
  /////////////
  ///////////
  /////////
  ///////

  const exportImage = async () => {
    if (state.downloading) return
    setState(s => ({...s, downloading: true}))
    htmlToImage
      .toJpeg(document.getElementById(type), {pixelRatio: 1, quality: 0.95})
      .then(dataUrl => {
        var link = document.createElement('a')
        link.download = `lichthidau.jpeg`
        link.href = dataUrl
        link.click()
        setState(s => ({...s, downloading: false}))
      })
      .catch(function (error) {
        setState(s => ({...s, downloading: false}))
        console.error('oops, something went wrong!', error)
      })
  }

  if (show) {
    return (
      <Box>
        <Flex display={'flex'} flexDirection="row" justifyContent={'space-between'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            mb={3}
            onClick={() => {
              setShow(false)
            }}
            cursor="pointer">
            <MdArrowBackIos size={'28px'} />
            <Text>Trở lại</Text>
          </Box>
          <Flex display="flex" flexDirection={'row'} justifyContent="flex-end">
            <Button ml={4} isLoading={state.downloading} colorScheme="blue" onClick={exportImage}>
              Tải xuống
            </Button>
          </Flex>
        </Flex>
        <Flex mt={4} display={'flex'} flexDirection="row" justifyContent={'center'}>
          <Box>
            <Box maxWidth={'920px'} margin="0 auto" display="flex" flexDirection={'column'} justifyContent="center">
              {selected === 1 && (
                <>
                  <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <Mau1 dataSeason={state.dataSeason} dataSchedules={state.dataSchedules} id={idMau1} size={1800} numberRound={numberRound} roundName={state.roundName} />
                  </div>
                  <Mau1 dataSeason={state.dataSeason} dataSchedules={state.dataSchedules} size={900} numberRound={numberRound} roundName={state.roundName} />
                </>
              )}
              {selected === 2 && (
                <>
                  <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <Mau2 dataSeason={state.dataSeason} dataSchedules={state.dataSchedules} id={idMau2} size={1800} numberRound={numberRound} roundName={state.roundName} />
                  </div>
                  <Mau2 dataSeason={state.dataSeason} dataSchedules={state.dataSchedules} size={900} numberRound={numberRound} roundName={state.roundName} />
                </>
              )}
              {selected === 3 && (
                <>
                  <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <Mau3 dataSeason={state.dataSeason} dataSchedules={state.dataSchedules} id={idMau3} size={1800} numberRound={numberRound} roundName={state.roundName} />
                  </div>
                  <Mau3 dataSeason={state.dataSeason} dataSchedules={state.dataSchedules} size={900} numberRound={numberRound} roundName={state.roundName} />
                </>
              )}
              {selected === 4 && (
                <>
                  <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <Mau4 dataSeason={state.dataSeason} dataSchedules={state.dataSchedules} id={idMau4} size={1800} numberRound={numberRound} />
                  </div>
                  <Mau4 dataSeason={state.dataSeason} dataSchedules={state.dataSchedules} size={900} numberRound={numberRound} />
                </>
              )}
            </Box>
          </Box>
        </Flex>
      </Box>
    )
  } else {
    return (
      <Box height={'100%'} display="flex" flexDirection={'column'} justifyContent="space-between">
        <Flex display={'flex'} flexDirection={'row'} justifyContent={'space-between'} w={'100%'} height={'46%'}>
          <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} onClick={select(1)}>
            <Image style={{margin: '0 auto'}} src={imgchoose1} />
          </Box>
          <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} onClick={select(2)}>
            <Image style={{margin: '0 auto'}} src={imgchoose2} />
          </Box>
          <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} onClick={select(3)}>
            <Image style={{margin: '0 auto'}} src={imgchoose3} />
          </Box>
          {/* <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} onClick={select(4)}>
            <Image style={{margin: '0 auto'}} src={imgchoose4} />
          </Box> */}
          <Box backgroundColor={'#eeeeee'} className="pointer" p={4} width={'24%'} height={'auto'} position="relative">
            <MdUpdate size={30} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%'}} />
          </Box>
        </Flex>
      </Box>
    )
  }
}

const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function getBase64(url) {
  return new Promise(resolve => {
    fetch(url, {cache: 'reload'})
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        }
        reader.readAsDataURL(blob)
      })
  })
}
function areEqual(p, n) {
  return true
}

export default React.memo(ExportSchedules, areEqual)
