import axios from 'axios'

//basketball
export function adminSubmitProfileBasketball(seasonId, leagueId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${data?.id}`,
    method: 'put',
    data
  }).then(res => res.data)
}

export function getSeasonByIdV2(leagueId, seasonId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}`,
    method: 'get'
  }).then(res => res.data)
}

export function getTeamsBySeasonIdAndLeague(leagueId, seasonId, teamId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${teamId}`,
    method: 'get'
  }).then(res => res.data)
}

export function getSeasonByLeagueId(leagueId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons`,
    method: 'get'
  }).then(res => res.data)
}

export function submitProfileBasketBall(seasonId, leagueId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${data?.id}`,
    method: 'post',
    data
  }).then(res => res.data)
}

export function deleteTeamFromSeason({leagueId, seasonId, teamId, data}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${data?.id}`,
    method: 'delete',
    data
  })
}

export function rejectTeamsSuggested({leagueId, seasonId, teamId, data}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${teamId}/suggesting`,
    method: 'delete',
    data
  })
}

export function submitSuggestedEditingBasketBall(seasonId, leagueId, teamId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${teamId}/suggesting`,
    method: 'post',
    data
  }).then(res => res.data)
}

export function adminSubmitSuggestedEditingBasketBall(seasonId, leagueId, teamId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${teamId}/suggesting`,
    method: 'put',
    data
  }).then(res => res.data)
}

export function createRankingTable({seasonId, leagueId, data}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/tables`,
    method: 'post',
    data
  }).then(res => res.data)
}

export function editRankingTable({seasonId, leagueId, data, tableId}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/tables/${tableId}`,
    method: 'put',
    data
  }).then(res => res.data)
}

export function getTableBySeasonBasketball({seasonId, leagueId}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/tables`,
    method: 'get'
  }).then(res => res.data)
}

export function deleteTableById({seasonId, leagueId, tableId}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/tables/${tableId}`,
    method: 'delete'
  }).then(res => res.data)
}

export function editIndexTableBasketBall(leagueId, seasonId, tableId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/tables/${tableId}`,
    method: 'put',
    data
  }).then(res => res.data)
}

export function getStatisticById({leagueId, seasonId}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/statistics`,
    method: 'get'
  }).then(res => res.data)
}

export function getSeasonsParticipatedByTeamId(teamId, joinedStatuses, {page = 1, perPage = 10, sortBy = 'seasonStartDate:desc'}) {
  return axios({
    url: `/api/v2.1/leagues/teams/${teamId}/seasons/${joinedStatuses}?page=${page}&perPage=${perPage}&sortBy=${sortBy}`,
    method: 'get'
  }).then(res => res.data)
}

export function teamLeaveSeasonWhenPending(leagueId, seasonId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${data?.id}`,
    method: 'delete',
    data
  }).then(res => res.data)
}

//basketball end

export function getLeagues({sportType = 'football', type = 'all', q = '', sortBy = 'createAt:asc', page = 1, perPage = 10}) {
  return axios({
    url: `/api/v2.1/leagues/sportType/${sportType}/type/${type}?q=${q}&page=${page}&perPage=${perPage}&sortBy=${sortBy}`,
    method: 'get'
  }).then(res => res.data)
}

export function getRoleByLeagueId(leagueId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/roles`,
    method: 'get'
  }).then(res => res.data)
}

export function getRoleByUserId(leagueId, userId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/userId/${userId}/roles`,
    method: 'get'
  }).then(res => res.data)
}

export function getStatisticsPersonalById({leagueId, seasonId, sportType, type = 'top_goal', page = 1, perPage = 50}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/statistic/individual/sportType/${sportType}/type/${type}?page=${page}&perPage=${perPage}`,
    method: 'get'
  }).then(res => res.data)
}

export function getStatisticsLeaguesGeneralById({leagueId, seasonId, sportType, type = 'rest', page = 1, perPage = 10}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/statistic/season/sportType/${sportType}/type/${type}?page=${page}&perPage=${perPage}`,
    method: 'get'
  }).then(res => res.data)
}

export function getStatisticsLeaguesRestById({leagueId, seasonId, sportType, type = 'rest', sortBy = 'totalYellowCards:asc', page = 1, perPage = 10}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/statistic/season/sportType/${sportType}/type/${type}?page=${page}&perPage=${perPage}&sortBy=${sortBy}`,
    method: 'get'
  }).then(res => res.data)
}

export function getLeagueJustInteractive() {
  return axios({
    url: `/api/v2.1/leagues/getLeagueJustInteractive`,
    method: 'get'
  }).then(res => res.data)
}

export function getLeaguesAdmin({search = '', page = 1, per_page = 10, is_system = 0, sport_type, orderType = 0, orderby = 'created_at', order = 'desc'}) {
  return axios({
    url: `/api/v2.1/leagues/admin?page=${page}&search=${search}&per_page=${per_page}&sport_type=${sport_type}&orderby=${orderby}&order=${order}`,
    method: 'get'
  }).then(res => res.data)
}
export function createLeague(data) {
  return axios({
    url: `/api/v2.1/leagues`,
    method: 'post',
    data
  }).then(res => res.data)
}
export function updateLeagueById(id, data) {
  return axios({
    url: `/api/v2.1/leagues/${id}`,
    method: 'put',
    data
  }).then(res => res.data)
}
export function updateRoundSeasons(id, data) {
  return axios({
    url: `/api/v2.1/leagues/${id}`,
    method: 'put',
    data
  }).then(res => res.data)
}

export function submitProfile(seasonId, leagueId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams`,
    method: 'put',
    data
  }).then(res => res.data)
}

export function submitSuggestedEditing(seasonId, leagueId, teamId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${teamId}/suggestedEditing`,
    method: 'put',
    data
  }).then(res => res.data)
}

export function deleteLeagueById(id) {
  return axios({
    url: `/api/v2.1/leagues/${id}`,
    method: 'delete'
  }).then(res => res.data)
}

export function getLeagueById(id, season) {
  const q = season ? `?season=${season}` : ''
  return axios({
    url: `/api/v2.1/leagues/${id}`,
    method: 'get'
  }).then(res => res.data)
}

export function deleteSeasonById(leagueId, seasonId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}`,
    method: 'delete'
  }).then(res => res.data)
}

//admin
export function getLeagueAdminById(id, season) {
  const q = season ? `?season=${season}` : ''
  return axios({
    url: `/api/v2.1/leagues/${id}${q}/admin`,
    method: 'get'
  }).then(res => res.data)
}
export function getChartDataByLeagueId(id) {
  return axios({
    url: `/api/v2.1/leagues/${id}/admin/chart`,
    method: 'get'
  }).then(res => res.data)
}
export function getPollAdminBySeasonId(seasonId, {page = 1, per_page = 10, category = null, order = 'desc'}) {
  return axios({
    url: `/api/v2.1/leagues/${seasonId}/admin/polls?page=${page}&per_page=${per_page}&category=${category}&order=${order}`,
    method: 'get'
  }).then(res => res.data)
}

export function getAnalyticsLeagueId(id) {
  return axios({
    url: `v1/seasons/${id}/analytics`,
    method: 'get'
  }).then(res => res.data)
}

// Teams
export function getTeamsBySeasonId(leagueId, seasonId, teamId = 'all', status = 'accepted', page = '1', perPage = '10') {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${teamId}/status/${status}?page=${page}&perPage=${perPage}`,
    method: 'get'
  }).then(res => res.data)
}
export function createTeamBySeasonId(seasonId, teamId, data) {
  return axios({
    url: `/v2.1/seasons/${seasonId}/teams/${teamId}`,
    method: 'post',
    data
  }).then(res => res.data)
}
export function updateTeamBySeasonId(seasonId, teamId, data) {
  return axios({
    url: `/v2.1/seasons/${seasonId}/teams/${teamId}`,
    method: 'put',
    data
  }).then(res => res.data)
}

export function TopGoldSeasonBySeasonId(id) {
  return axios({
    url: `v1/seasons/${id}/statistics/topgoal`,
    method: 'get'
  }).then(res => res.data)
}
export function getMatchsBySeasonId(id, status = '') {
  return axios({
    url: `v1/seasons/${id}/matchs?status=${status}`,
    method: 'get'
  }).then(res => res.data)
}
export function getMatchBySeasonIdAndLeagueId(leagueId, seasonId, roundId, type) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/rounds/${roundId}/matches/${type}`,
    method: 'get'
  }).then(res => res.data)
}

// tao truyen thong va tai tro
export function getSponsorTypes(leagueId, seasonId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/sponsorTypes`,
    method: 'get'
  }).then(res => res.data)
}

//thien add sort selectbox choose sponsor type
export function getSponsorTypesSort(leagueId, seasonId) {
  return axios({
    url: `/api/v2.1/meta/findBy/type/value/default_sponsor_types`,
    method: 'get'
  }).then(res => res.data)
}

export function createSponsorTypeBySeasonId(leagueId, seasonId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/sponsorTypes`,
    method: 'post',
    data
  }).then(res => res.data)
}
export function createSponsor(leagueId, seasonId, sponsorTypeId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/sponsorTypes/${sponsorTypeId}/sponsors`,
    method: 'post',
    data
  }).then(res => res.data)
}

export function updateSponsorBySeasonId(leagueId, seasonId, sponsorTypeId, sponsorId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/sponsorTypes/${sponsorTypeId}/sponsors/${sponsorId}`,
    method: 'put',
    data
  }).then(res => res.data)
}
export function deleteSponsorBySeasonId(leagueId, seasonId, sponsorTypeId, sponsorId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/sponsorTypes/${sponsorTypeId}/sponsors/${sponsorId}`,
    method: 'delete'
  }).then(res => res.data)
}
export function deleteSponsorType(leagueId, seasonId, sponsorTypeId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/sponsorTypes/${sponsorTypeId}`,
    method: 'delete'
  }).then(res => res.data)
}
export function getTeamPlayersByTeamId(leagueId, seasonId, teamId, status = 'accepted') {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${teamId}/status/${status}`,
    method: 'get'
  }).then(res => res.data)
}

//

export function createSeason(id, data) {
  return axios({
    url: `/api/v2.1/leagues/${id}/seasons`,
    method: 'post',
    data
  }).then(res => res.data)
}

export function updateSeason(leagueId, seasonId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}`,
    method: 'put',
    data
  }).then(res => res.data)
}

export function createFieldInfos(leagueId, seasonId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/registrationInfos`,
    method: 'post',
    data
  }).then(res => res.data)
}

export function deleteFieldInfos(leagueId, seasonId, registrationInfoId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/registrationInfos/${registrationInfoId}`,
    method: 'delete'
  }).then(res => res.data)
}

export function updateFieldInfos(leagueId, seasonId, registrationInfoId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/registrationInfos/${registrationInfoId}`,
    method: 'put',
    data
  }).then(res => res.data)
}

export function getRequireType(leagueId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/memberTypes`,
    method: 'get'
  }).then(res => res.data)
}

export function getSeasonById(seasonId) {
  return axios({
    url: `/v2.1/seasons/${seasonId}`,
    method: 'get'
  }).then(res => res.data)
}
export function getSeasonByIdSeasonAndIdLeague(leagueId, seasonId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}`,
    method: 'get'
  }).then(res => res.data)
}

// Category

// Participants

//Rewards

export function getRewardsBySeasonId(leagueId, seasonId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/rewards`,
    method: 'get'
  }).then(res => res.data)
}
export function createRewardBySeasonId(leagueId, seasonId, rewardType, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/rewards/${rewardType}`,
    method: 'post',
    data
  }).then(res => res.data)
}
export function updateRewardBySeasonId(leagueId, seasonId, rewardId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/rewards/${rewardId}`,
    method: 'put',
    data
  }).then(res => res.data)
}
export function deleteRewardBySeasonId(leagueId, seasonId, rewardId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/rewards/${rewardId}`,
    method: 'delete'
  }).then(res => res.data)
}
export function resetRewardBySeasonId(leagueId, seasonId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/rewards/reset`,
    method: 'put'
  }).then(res => res.data)
}

export function setUpQuickMatchSchedule(league_id, season_id, type, data) {
  return axios({
    url: `/api/v2.1/leagues/${league_id}/seasons/${season_id}/table/schedule/${type}`,
    method: 'post',
    data
  }).then(res => res.data)
}

export function getRoundMatchBySeasonId({leagueId, seasonId}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/tables`,
    method: 'get'
  }).then(res => res.data)
}

export function getSchedulesBySeasonId(leagueId, seasonId, roundId, type, keyGoOrBack, series) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/rounds/${roundId}/matches/${type}/keyGoOrBack/${keyGoOrBack}/series/${series}`,
    method: 'get'
  }).then(res => res.data)
}

//danh sách trận đấu khác @Hoàng
export function getOtherSchedulesBySeasonId(leagueId, seasonId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/other/matches`,
    method: 'get'
  }).then(res => res.data)
}

export function getRoundSeason(leagueId, seasonId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/rounds`,
    method: 'get'
  }).then(res => res.data)
}

export function getOtherSchedules(leagueId, seasonId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/other/matches`,
    method: 'get'
  }).then(res => res.data)
}

export function addOtherSchedules(leagueId, seasonId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/other/matches`,
    method: 'post',
    data
  }).then(res => res.data)
}

export function deleteOtherSchedules(leagueId, seasonId, roundId, matchId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/round/${roundId}/match/${matchId}/other/matches`,
    method: 'delete'
  }).then(res => res.data)
}

export function createOtherSchedules(leagueId, seasonId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/matches/other`,
    method: 'post',
    data
  }).then(res => res.data)
}

export function getRoundSeasonSetting(leagueId, seasonId, series = '') {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/set/rounds?series=${series}`,
    method: 'get'
  }).then(res => res.data)
}

export function SetUpRoundsSeasonById(leagueId, seasonId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/updateRound`,
    method: 'put',
    data
  }).then(res => res.data)
}

export function getSettingSchedulesBySeasonId(leagueId, seasonId, type, series = 'A') {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/schedule/${type}/${series}`,
    method: 'get'
  }).then(res => res.data)
}

export function getFirstRoundKnockout(leagueId, seasonId, series = '') {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/matches/rounds?series=${series}`,
    method: 'get'
  }).then(res => res.data)
}

export function getRankingSeason(leagueId, seasonId, tableId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/tables/${tableId}/ranks`,
    method: 'get'
  }).then(res => res.data)
}

export function SettingRoundMatchesBySeason({leagueId, seasonId}, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/settings/rounds/matches/updates`,
    method: 'put',
    data
  }).then(res => res.data)
}

// Le Trung Hieu
// get danh sach clb trong giai.
export function getTeamBasketballAcceptedBySeasonId({seasonId, leagueId, status = 'accepted', sortBy = 'createdAt:asc', page = 1, perPage = 100}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams?status=${status}&page=${page}&perPage=${perPage}&sortBy=${sortBy}`,
    method: 'get'
  }).then(res => res.data)
}

// xoa clb ra khoi mua giai
export function deleteTeamInleague(league_id, season_id, data) {
  return axios({
    url: `/api/v2.1/leagues/${league_id}/seasons/${season_id}/teams`,
    method: 'put',
    data
  }).then(res => res.data)
}

// cục bảng xếp hạng//

export function CreateTableMatch(league_id, season_id, data) {
  return axios({
    url: `/api/v2.1/leagues/${league_id}/seasons/${season_id}/createTable`,
    method: 'post',
    data
  }).then(res => res.data)
}
export function updateDrawlots(league_id, season_id, data) {
  return axios({
    url: `/api/v2.1/leagues/${league_id}/seasons/${season_id}/table/draw`,
    method: 'put',
    data
  }).then(res => res.data)
}

export function getRankingTable(league_id, season_id) {
  return axios({
    url: `/api/v2.1/leagues/${league_id}/seasons/${season_id}/ranking`,
    method: 'get'
  }).then(res => res.data)
}

export function createEditTableRanhking(league_id, season_id, table_id, data) {
  return axios({
    url: `/api/v2.1/leagues/${league_id}/seasons/${season_id}/tables/${table_id}/teams`,
    method: 'post',
    data
  }).then(res => res.data)
}
export function updateRankingEdit(league_id, season_id, table_id, data) {
  return axios({
    url: `/api/v2.1/leagues/${league_id}/seasons/${season_id}/table/${table_id}/teams`,
    method: 'put',
    data
  }).then(res => res.data)
}
//xoá đội trong bảng
export function removeTeambyTable(league_id, season_id, table_id, team_id, data) {
  return axios({
    url: `/api/v2.1/leagues/${league_id}/seasons/${season_id}/tables/${table_id}/teams/${team_id}`,
    method: 'delete',
    data
  }).then(res => res.data)
}
export function getAllTeamSeason(league_id, season_id, status = 'accepted', teamIds = 'all', page = 1, per_page = 1000) {
  return axios({
    url: `/api/v2.1/leagues/${league_id}/seasons/${season_id}/teams/${teamIds}/status/${status}?page=${page}&perPage=${per_page}`,
    method: 'get'
  }).then(res => res.data)
}
export function getSeasonTable(league_id, season_id) {
  return axios({
    url: `/api/v2.1/leagues/${league_id}/seasons/${season_id}/tables`,
    method: 'get'
  }).then(res => res.data)
}
export function getTableBySeason(league_id, season_id, table_id) {
  return axios({
    url: `/api/v2.1/leagues/${league_id}/seasons/${season_id}/tables/${table_id}`,
    method: 'get'
  }).then(res => res.data)
}
// Kết thúc bảng xếp hạng
export function getExportMember(data, team_id) {
  return axios({
    url: `/api/v2.1/leagues/teams/${team_id}/members/all/export`,
    method: 'post',
    data
  }).then(res => res.data)
}
//boc tham
export function putDraws(league_id, season_id, data) {
  return axios({
    url: `/api/v2.1/leagues/${league_id}/seasons/${season_id}/table/draw`,
    method: 'put',
    data
  }).then(res => res.data)
}

//Rewards Team @Hoàng
export function getRewardByTeamId(teamId, page = 1, perPage = 10) {
  return axios({
    url: `/api/v2.1/leagues/teams/${teamId}/rewards?${page}&perPage=${perPage}`,
    method: 'get'
  }).then(res => res.data)
}

//danh sách giải đấu team đó tham gia @Hoàng
export function getLeaguesJoinedByTeamId(teamId) {
  return axios({
    url: `/api/v2.1/leagues/accepted/teams/${teamId}`,
    method: 'get'
  }).then(res => res.data)
}

//danh sách giải đấu theo userId đó tham gia @Hoàng
export function getLeaguesJoinedByUserId(userId, {page, perPage, sportType}) {
  return axios({
    url: `/api/v2.1/leagues/users/${userId}/leagues/accepted?page=${page}&perPage=${perPage}&sportType=${sportType}`,
    method: 'get'
  }).then(res => res.data)
}

//chi tiết thống kê của team @Hoàng
export function getStatisticsByTeamId(leagueId, seasonId, teamId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${teamId}/statistic`,
    method: 'get'
  }).then(res => res.data)
}

//danh sách mùa giải của team theo status tham gia vào mùa giải @Hoàng
export function getSeasonsByTeamId(teamId, joinedStatuses, {page = 1, perPage = 10, sortBy = 'seasonStartDate:desc'}) {
  return axios({
    url: `/api/v2.1/leagues/teams/${teamId}/seasons/${joinedStatuses}?page=${page}&perPage=${perPage}&sortBy=${sortBy}`,
    method: 'get'
  }).then(res => res.data)
}

//danh sách thành viên của đội trong season @Hoàng
export function getMembersTeamBySeasonId(leagueId, seasonId, teamId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${teamId}`,
    method: 'get'
  }).then(res => res.data)
}

//Update or Delete hồ sơ Accepted @Hoàng
export function updateProfileBySeasonId(leagueId, seasonId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams`,
    method: 'put',
    data
  }).then(res => res.data)
}

//@Hoang
export function getStatisticsByUserId({leagueId, seasonId, userId, sportType}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/users/${userId}/statistic/notPagination?sportType=${sportType}`,
    method: 'get'
  }).then(res => res.data)
}

//@Hoang
export function getMyLeaguesByUserId({userId, sportType, page = 1, perPage = 10}) {
  return axios({
    url: `/api/v2.1/leagues/users/${userId}/leagues/accepted?page=${page}&perPage=${perPage}&sportType=${sportType}`,
    method: 'get'
  }).then(res => res.data)
}

//@Hoang
export function getAllTableRanksTeam({seasonId, leagueId}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/getAllTableRanksTeams`,
    method: 'get'
  }).then(res => res.data)
}

//@Hoang
export function getMyTeamsByUserId({userId, sportType, page = 1, perPage = 10}) {
  return axios({
    url: `/api/v2.1/leagues/users/${userId}/teams/accepted?page=${page}&perPage=${perPage}&sportType=${sportType}`,
    method: 'get'
  }).then(res => res.data)
}

export function getCategoryPolls({leagueId, seasonId, page = 1, perPage = 10}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/polls`,
    method: 'get'
  }).then(res => res.data)
}

export function getPollsById(leagueId, seasonId, pollId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/polls/${pollId}`,
    method: 'get'
  }).then(res => res.data)
}

export function createCategoryPolls(leagueId, seasonId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/polls`,
    method: 'post',
    data
  }).then(res => res.data)
}

export function deleteCategoryPolls(leagueId, seasonId, pollId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/polls/${pollId}`,
    method: 'delete'
  }).then(res => res.data)
}

export function updateCategoryPolls(leagueId, seasonId, pollId, {data}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/polls/${pollId}`,
    method: 'put',
    data
  }).then(res => res.data)
}
// Le Trung Hieu 19/09 - Get chi tiet bang dau

export function getTableByTableId(leagueId, seasonId, tableId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/tables/${tableId}`,
    method: 'get'
  }).then(res => res.data)
}

// Le Trung Hieu 19/09 - Cap nhat vi tri bang xep hang

export function editIndexTable(leagueId, seasonId, tableId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/tables/${tableId}`,
    method: 'put',
    data
  }).then(res => res.data)
}

//get reward user
//Thien add
export function getRewardsByUserId(userId, sportType, page = 1, perPage = 10) {
  return axios({
    url: `/api/v2.1/leagues/users/${userId}/rewards?page=${page}&perPage=${perPage}&sportType=${sportType}`,
    method: 'get'
  }).then(res => res.data)
}
//le trung hieu 13/10 - chia bang vong tron
export function createRoundRobin(leagueId, seasonId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/createTable/roundRobin`,
    method: 'post',
    data
  }).then(res => res.data)
}

//get detail verification
//Thien add
export function getVerifyDetail({status = 'verified,verifying,rejected', sortBy = 'verify.latestRequestedAt:desc', searchText = '', page = 1, perPage = 10}) {
  return axios({
    url: `/api/v2.1/leagues/all/seasons?verifyStatuses=${status}&page=${page}&perPage=${perPage}&q=${searchText}&sortBy=${sortBy}`,
    method: 'get'
  }).then(res => res.data)
}

export function verifyConfirm({leagueId, seasonId, status = 'verified'}, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/verify/${status}`,
    method: 'put',
    data: data
  }).then(res => res.data)
}

export function requestConfirmToAdmin(leagueId, seasonId, {data}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/verify/verifying`,
    method: 'put',
    data
  }).then(res => res.data)
}

// le trung hieu - get lich giao dien v2.1
export function getSchedulesBySeasonIdV2(leagueId, seasonId, numberRound, type, keyGoOrBack, series) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/rounds/${numberRound}/matches/${type}/keyGoOrBack/${keyGoOrBack}/series/${series}`,
    method: 'get'
  }).then(res => res.data)
}
// le trung hieu - export the 7/11/2022
export function exportCard(leagueId, seasonId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/static/exportExcelCard`,
    method: 'post',
    data
  }).then(res => res.data)
}
// le trung hieu - get danh sach clb theo bang dau (tat ca bang dau)
export function getTeamsTableBySeasonId(leagueId, seasonId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/getAllTableRanksTeams`,
    method: 'get'
  }).then(res => res.data)
}
//le trung hieu - chinh sua lich thi dau
export function editSchedules(leagueId, seasonId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/edit/matches`,
    method: 'put',
    data: data
  }).then(res => res.data)
}

//Xuất biên bản trận đấu @Hoang
export function exportMatchesById({leagueId, seasonId, matchId}, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/match/${matchId}/exportExcel/statisticalMatch`,
    method: 'post',
    data
  }).then(res => res.data)
}
// cap nhat lich thi dau
export function editSchedulesSpecificMatch(leagueId, seasonId, matchId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/matches/${matchId}/view/win_team_in_specific_match`,
    method: 'put',
    data: data
  }).then(res => res.data)
}

export function getNotification(leagueId, seasonId, types, listTeams, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/userIds/${types}?teamIds=${listTeams}`,
    method: 'POST',
    data
  }).then(res => res.data)
}
// Fees
export function createFee(leagueId, seasonId, data) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/activity-fees`,
    method: 'post',
    data
  }).then(res => res.data)
}
export function getFees(leagueId, seasonId, page, perPage, searchText = '', sortBy = 'createdAt:desc') {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/activity-fees?page=${page}&perPage=${perPage}&q=${searchText}&sortBy=${sortBy}`,
    method: 'get'
  }).then(res => res.data)
}
export function deleteFeeId(leagueId, seasonId, activityFeeId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/activity-fees/${activityFeeId}`,
    method: 'delete'
  }).then(res => res.data)
}
export function getFeesTeam(leagueId, seasonId, teamId, page, perPage, status, searchText = '', sortBy = 'createdAt:desc') {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${teamId}/activity-fees?page=${page}&perPage=${perPage}&teamStatus=${status}&q=${searchText}&sortBy=${sortBy}`,
    method: 'get'
  }).then(res => res.data)
}

export function getTeamsBySeasonIdBasketBall(leagueId, seasonId, teamId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/teams/${teamId}`,
    method: 'get'
  }).then(res => res.data)
}
// get season v2

export function getSeasonByIdv2(leagueId, seasonId) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}`,
    method: 'get'
  }).then(res => res.data)
}
// GET LICH BONG RO
export function getSchedulesBasketball({leagueId, seasonId, matchStatus = 'upcoming'}) {
  return axios({
    url: `/api/v2.1/leagues/${leagueId}/seasons/${seasonId}/rounds?matchStatus=${matchStatus}`,
    method: 'get'
  }).then(res => res.data)
}
