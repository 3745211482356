import React from 'react'
import moment from 'moment'
import img from '../../../../../public/static/image-background/logo-v-02.png'
import imgbgschedules from '../../../../../public/static/image-background/bgitemschedules.png'
import {json} from 'react-router-dom'
import locales from '../../../../../public/static/locales/vi.json'
const Mau1 = ({dataSeason, dataSchedules, size, id, numberRound}) => {
  const t = locales
  const width = size
  const px = size / 100
  const color = {
    first: '#f48220',
    second: '#325ea8',
    third: '#ffe5c2',
    fourth: '#1d4380'
  }
  const classCustom = size < 1000 ? 'view' : 'export'
  return (
    <div id={id} className="bg-match-mau1" style={{width: width, minHeight: width / 1.3, padding: `${1.6 * px}px`, fontFamily: 'SFUHelveticaCondensedBold'}}>
      <div style={{width: `100%`, minHeight: `calc(${width / 1.3}px - ${3.2 * px}px)`, padding: `${px * 1.3}px ${px * 1.3}px ${px * 4.2}px ${px * 1.3}px`, backgroundColor: '#fff', position: 'relative', borderRadius: `${1.5 * px}px`}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{flex: 1}}>
            <Image src={dataSeason?.logo} style={{width: `${px * 6}px`, height: 'max-content'}} />
          </div>
          <div style={{flex: 6, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <h1 style={{textAlign: 'center', color: color.fourth, fontSize: `${2.5 * px}px`, textTransform: 'uppercase'}}>{dataSeason?.name}</h1>
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <Image src={img} style={{width: `${px * 6}px`, height: 'max-content'}} />
          </div>
        </div>
        <h1 style={{textAlign: 'center', color: color.first, fontSize: `${4.5 * px}px`, textTransform: 'uppercase'}}>LỊCH THI ĐẤU</h1>
        <div style={{width: '100%'}}>
          <h2 style={{textTransform: 'uppercase', margin: '0 auto', width: 'max-content', color: color.fourth, padding: `${0.5 * px}px ${5 * px}px`, borderRadius: `${1.5 * px}px`, fontSize: `${2.6 * px}px`, textTransform: 'uppercase'}}>Bán Kết</h2>
        </div>
        <div>
          {dataSchedules?.map((i, idx) => (
            <Item key={`${idx}${new Date()}`} data={i} imgbgschedules={imgbgschedules} px={px} classCustom={classCustom} idx={idx} />
          ))}
        </div>

        <p style={{textAlign: 'center', color: color.first, fontSize: `${2 * px}px`, position: 'absolute', bottom: `0px`, right: `${1.2 * px}px`}}>VSPORTS.VN</p>
      </div>
    </div>
  )
}
const Item = React.memo(
  ({data, imgbgschedules, px, classCustom, idx}) => {
    const color = {
      first: '#f48220',
      second: '#325ea8',
      third: '#ffe5c2',
      fourth: '#1d4380'
    }
    const t = locales

    let homeName = data?.homeTeam?.name || (t[`${data?.matchPair[0]}`] ? t[`${data?.matchPair[0]}`] : `${t['rank']} ${data?.matchPair[0]}`)

    let awayName = data?.awayTeam?.name || (t[`${data?.matchPair[1]}`] ? t[`${data?.matchPair[1]}`] : `${t['rank']} ${data?.matchPair[1]}`)
    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', marginTop: `${2 * px}px`}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
          <div style={{flex: 2}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <Image src={data?.homeTeam?.logo} style={{width: `${px * 9}px`, height: `${9 * px}px`, borderRadius: `50%`, objectFit: 'cover', border: `${0.3 * px}px solid ${color.first}`}} />
            </div>
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
            <h2 style={{color: '#fff', textAlign: 'center', fontSize: `${2 * px}px`, color: color.fourth}}>BÁN KẾT {idx + 1}</h2>
          </div>
          <div style={{flex: 2}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <Image src={data?.awayTeam?.logo} style={{width: `${px * 9}px`, height: `${9 * px}px`, borderRadius: `50%`, objectFit: 'cover', border: `${0.3 * px}px solid ${color.first}`}} />
            </div>
          </div>
        </div>
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundImage: `url(${imgbgschedules})`, height: `${11.3 * px}px`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
          <div style={{flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
            <Name propClass={`home${data?.homeTeam?.id}${new Date().getTime()}${classCustom}${idx}`} name={homeName} px={px} />
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <h2 style={{color: '#fff', textAlign: 'center', fontSize: `${1.6 * px}px`, lineHeight: `${3 * px}px`, textTransform: 'uppercase'}}>{data?.stadium && data?.stadium?.length < 16 ? data?.stadium : ''}</h2>
            <div style={{paddingBottom: `${1.2 * px}px`}}>
              <h2 style={{color: '#fff', textAlign: 'center', fontSize: `${1.5 * px}px`, lineHeight: `${2.2 * px}px`}}>{moment(data?.dateTime).format('DD.MM.YYYY')}</h2>
              <h2 style={{color: '#fff', textAlign: 'center', fontSize: `${3.5 * px}px`, lineHeight: `${4 * px}px`}}>{moment(data?.dateTime).format('HH:mm')}</h2>
            </div>
          </div>
          <div style={{flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
            <Name propClass={`away${data?.awayTeam?.id}${new Date().getTime()}${classCustom}${idx}`} name={awayName} px={px} />
          </div>
        </div>
      </div>
    )
  },
  (p, n) => JSON.stringify(p.data) === JSON.stringify(n.data)
)

const Name = React.memo(
  ({name, px, propClass}) => {
    const reSizeBasedOnWidth = () => {
      let fontSize = window && window?.getComputedStyle(document.querySelector(`.${propClass}`))?.fontSize
      document.querySelector(`.${propClass}`).style.fontSize = parseFloat(fontSize) - 0.3 * px + 'px'
      if (document.querySelector(`.${propClass}`).clientHeight >= document.querySelector(`.contain${propClass}`).clientHeight) {
        reSizeBasedOnWidth()
      }
    }
    const processInput = () => {
      document.querySelector(`.${propClass}`).innerHTML = name
      document.querySelector(`.${propClass}`).style.fontSize = px * 3 + 'px' // Default font size
      reSizeBasedOnWidth()
    }

    React.useEffect(() => {
      processInput()
    }, [name])

    return (
      <div className={`contain${propClass}`} style={{flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: `${4 * px}px`, marginTop: `${1.5 * px}px`, textTransform: 'uppercase'}}>
        <h2 className={propClass} style={{textAlign: 'center', width: '80%', color: '#fff', fontSize: `${3 * px}px`}}></h2>
      </div>
    )
  },
  (p, n) => JSON.stringify(p.name) === JSON.stringify(n.name)
)
const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function getBase64(url) {
  return new Promise(resolve => {
    fetch(url, {cache: 'reload'})
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader()
        reader.onload = function () {
          resolve(this.result)
        }
        reader.readAsDataURL(blob)
      })
  })
}
function areEqual(p, n) {
  return JSON.stringify(p.isDisabledTime) === JSON.stringify(n.isDisabledTime) && JSON.stringify(p.dataMatch) === JSON.stringify(n.dataMatch) && JSON.stringify(p.dataMatchDetail) === JSON.stringify(n.dataMatchDetail)
}

export default React.memo(Mau1, areEqual)
